import React from 'react'
import imgConception from '../../../images/conception-mobile.svg'

const PhaseConception = () => {
    return (
        <section className="bg-white trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pb-50 pt-5 align-self-center text-center img-left-mobile">
                        <img src={imgConception} alt="" width="80%" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune pt-5">Phase de conception</h2>
                        <p className="chapeaux">La création d’applications mobiles est un projet complexe faisant appel à une multitude de compétences et exige une bonne connaissance des attentes de l'utilisateur final</p>
                        <p>La phase de conception consiste à définir les spécifications fonctionnelles et techniques, concevoir l'architecture technique (notamment les échanges avec la console d'administration, l’intégration au système d’information de l'entreprise et la sécurisation de l’architecture), définir les principes d’ergonomie et de navigation (storyboard), élaborer le design général et les maquettes graphiques de l’ensemble des écrans, etc.</p>

                        <p>Ces 2 dernières tâches sont confiées aux designers qui doivent faire en sorte que l’utilisateur s’oriente aisément et trouve ce qu’il est venu chercher de la manière la plus efficace possible. Ils doivent donc trouver l’équilibre entre fonctionnalités, simplicité, esthétique, accessibilité, intuition, etc. bref, ils doivent modéliser l'expérience utilisateur !.</p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default PhaseConception
