import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from "react-ga4"
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import StylesJolofGlobal from './css/StylesJolofGlobal';
import HeaderMenu from './composants/HeaderMenu';
import Footer from './composants/Footer';
import Accueil from './pages/Accueil';
import Agence from './pages/Agence';
import MarketingDigital from './pages/MarketingDigital';
import SystemesInformation from './pages/SystemesInformation';
import Realisations from './pages/Realisations';
import Contact from './pages/Contact';
import SiteInternet from './pages/marketing-digital/SiteInternet';
import CommunityManagement from './pages/marketing-digital/CommunityManagement';
import ApplicationMobile from './pages/marketing-digital/ApplicationMobile';
import Referencement from './pages/marketing-digital/Referencement';
import EmailMarketing from './pages/marketing-digital/EmailMarketing';
import AuditEreputation from './pages/marketing-digital/AuditEreputation';
import PartenariatTechnique from './pages/PartenariatTechnique';

import Erreur404 from './pages/Erreur404';
import { SessionContextProvider } from './composants/utils/SessionContext';
import RemonterPage from './composants/utils/RemonterPage';

function App() {

  //ReactGA.initialize('UA-146942554-2');
  ReactGA.initialize('G-3M1MTK35CR');
  
  return (
    <SessionContextProvider>
    <Router>
      <RemonterPage />
      <StylesJolofGlobal />
      <HeaderMenu />
      <Switch>
        <Route exact path='/' component={Accueil} />
        <Route path='/agence' component={Agence} />
        <Route path='/marketing-digital' component={MarketingDigital} />
          <Route path='/site-internet' component={SiteInternet} />
          <Route path='/community-management' component={CommunityManagement} />
          <Route path='/application-mobile' component={ApplicationMobile} />
          <Route path='/referencement' component={Referencement} />
          <Route path='/email-marketing' component={EmailMarketing} />
          <Route path='/audit-e-reputation' component={AuditEreputation} />
          <Route path='/partenariat-technique' component={PartenariatTechnique} />
        <Route path='/systemes-information' component={SystemesInformation} />
        <Route path='/realisations' component={Realisations} />
        <Route path='/contact' component={Contact} />
        <Route component={Erreur404} />
      </Switch>
      <Footer page={window.location.href} />
    </Router>
    </SessionContextProvider>
  );
}

AOS.init();

export default App;
