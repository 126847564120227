import React from 'react'
import styled from 'styled-components'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'


const SlideUpDown = (props) => {
    return (
        <DivCSS>
            <SlideDown>
                {props.open === true ? props.children : null}
                {/*props.children*/}
            </SlideDown>
        </DivCSS>
    )
}

const DivCSS = styled.div`
    .react-slidedown {
        transition-duration: 400ms;
        transition-timing-function: cubic-bezier(1, 0, 0, 1);
        padding: 0 !important;
        //overflow-x: hidden;
    }
`

export default SlideUpDown