import React, { Fragment, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import HeaderTitre from '../composants/HeaderTitre'
import bgMarketingDigital from '../images/bg-marketing-digital.webp'
import Intro from '../composants/marketingDigital/Intro'
import Objectifs from '../composants/marketingDigital/Objectifs'
import Recette from '../composants/marketingDigital/Recette'
import Partenariat from '../composants/marketingDigital/Partenariat'
import SessionContext from '../composants/utils/SessionContext'
//import ogImage from '../images/bg-marketing-digital-og.jpg'


const MarketingDigital = () => {

    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('marketingDigital');
    })

    const titre = 'Marketing digital - Jolof Digital';
    const description = 'Optimisez la notoriété de votre enseigne et boostez votre chiffre d’affaires';
    const urlPage = 'https://jolof.digital/marketing-digital';
    const imgPage = 'https://jolof.digital/open-graph/marketing-digital.jpg';
    
    return (
        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{titre}</title>
                <meta name="description" content={description}></meta>
                <link rel="canonical" href={urlPage} />
                <meta property="og:title" content={titre} />
                <meta property="og:description" content={description} />
                <meta property="og:type" content="website" /> 
                <meta property="og:url" content={urlPage} />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:image" content={imgPage} />
                <meta name="twitter:card" content="photo"/>
                <meta name="twitter:site" content="@jolofdigital"/>
                <meta property="twitter:title" content={titre}/>
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image:src" content={imgPage} />
                {/*
                <meta name="title" content="Production-Ready Animation Library for React | Framer Motion"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, viewport-fit=cover"/>
                <meta property="fb:app_id" content="216342412279020"/>
                <meta property="og:type" content="product"/>
                <meta property="og:url" content="https://www.framer.com/motion/"/>
                <meta property="og:title" content="Production-Ready Animation Library for React | Framer Motion"/>
                <meta property="og:description" content="Framer Motion is a production-ready React animation and gesture library."/>
                <meta property="og:image" content="https://www.framer.com/images/social/motion.png"/>
                <meta name="twitter:card" content="photo"/>
                <meta name="twitter:site" content="@framer"/><meta name="twitter:creator" content="@framer"/>
                <meta name="twitter:title" content="Production-Ready Animation Library for React | Framer Motion"/>
                <meta name="twitter:description" content="Framer Motion is a production-ready React animation and gesture library."/>
                <meta name="twitter:image:src" content="https://www.framer.com/images/social/motion.png"/>
                <meta itemProp="name" content="Production-Ready Animation Library for React | Framer Motion"/>
                <meta itemProp="description" content="Framer Motion is a production-ready React animation and gesture library."/>
                <meta itemProp="image" content="https://www.framer.com/images/social/motion.png"/>
                <meta name="mobile-web-app-capable" content="yes"/> 
                */}
            </Helmet>

            <HeaderTitre 
                titre = {<Fragment>Marketing <br/>digital</Fragment>}
                texte = 'Optimisez la notoriété de votre enseigne et boostez votre chiffre d’affaires'
                largeur ='courtTexte'>
                <img src={bgMarketingDigital} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

            <Intro />

            <Objectifs />
            
            <Recette />

            <Partenariat />

        </Fragment>
    )
}

export default MarketingDigital