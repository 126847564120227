import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import imgSiteVitrine from '../../../images/site-vitrine.svg'
import imgSiteEcommerce from '../../../images/site-ecommerce.svg'
import imgSiteBlog from '../../../images/site-blog.svg'
import imgSiteEvenementiel from '../../../images/site-evenementiel.svg'

const DifferentsSites = () => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        pauseOnHover: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
            breakpoint: 1400,
                settings: {
                    slidesToShow: 3
                }
            },
            {
            breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
            breakpoint: 700,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <DivCSS>
            <div className="container defilement">

                <div className="row pb-5" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                    <div className="col-12 text-center">
                        <h2 className="texte-bleu">Nos spécialités en matière de site</h2>
                        <p className="sous-titres">Nous vous aidons à faire le bon choix</p>
                    </div>
                </div>

                <div className="row" data-aos="fade-up">

                    <div className="col-xl-10 offset-xl-1">

                        <Slider {...settings}>

                            <div className="item">
                                <div className="typeSite">
                                    <img src={imgSiteVitrine} alt="" />
                                    <p className="chapeaux">Vitrine</p>
                                    <p>Soyez présent sur internet, présentez votre activité, vos produits et services</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="typeSite">
                                    <img src={imgSiteEcommerce} alt="" />
                                    <p className="chapeaux">Ecommerce</p>
                                    <p>Exposez votre catalogue de produits en ligne et commencer à vendre partout 24h/24</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="typeSite">
                                    <img src={imgSiteBlog} alt="" />
                                    <p className="chapeaux">Blog</p>
                                    <p>Partagez vos articles au format texte ou vidéo avec les internautes du monde entier</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="typeSite">
                                    <img src={imgSiteEvenementiel} alt="" />
                                    <p className="chapeaux">Evènementiel</p>
                                    <p>Marquez votre événement avec un site chargé d'informer et guider les participants</p>
                                </div>
                            </div>

                        </Slider>

                    </div>

                </div>

            </div>
        </DivCSS>
    )
}

const DivCSS = styled.div`
padding: 150px 0;
    .item {
        padding: 30px;
    }
    .typeSite {
        background: #fff;
        padding: 50px 35px;
        box-shadow: rgba(0, 0, 0, 0.1) 0 10px 20px;
        border-radius: 10px;
        border-bottom: var(--bleujolof) 5px solid;
        text-align: center;
    }
    .typeSite img {
        height: 100px;
        max-width: 75px;
        padding-bottom: 25px;
        margin: 0 auto;
    }

`

export default DifferentsSites
