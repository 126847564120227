import React from 'react'
import imgPositionnement from '../../../images/analyse-positionnement.svg'

const AnalysePositionnement = () => {
    return (
        <section className="bg-white trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-md-6 text-center pt-5 pb-5 order-md-2 align-self-center img-left-mobile">
                        <img src={imgPositionnement} alt="" width="60%" data-aos="zoom-in-up" />
                    </div>

                    <div className="col-md-6 pr-100 order-md-1">
                        <h2 className="texte-jaune">Analyse du positionnement <br className="d-none d-md-block"/>sur les moteurs de recherche</h2>
                        <p className="chapeaux">Mesurons l’impact de vos efforts déjà consentis en matière de référencement</p>
                        <p>La première chose à faire, avant de mettre en place une stratégie de référencement pertinente, est de réaliser un état des lieux précis du positionnement actuel de votre site sur Google et les autres moteurs de recherche. L’idée est de savoir exactement, à un moment donné, quels sont les mots-clés sur lesquels vous êtes positionnés et sur quelle page votre site est déjà référencé.</p>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default AnalysePositionnement
