import React from 'react'
import imgReputationConcurrents from '../../../images/reputation-concurrents.svg'

const AnalyseConcurrents = () => {
    return (
        <section className="bg-white trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-md-6 text-center pb-5 order-md-2 align-self-center img-left-mobile">
                        <img src={imgReputationConcurrents} alt="" width="60%" data-aos="zoom-in-up"/>
                    </div>

                    <div className="col-md-6 pr-100 order-md-1">
                        <h2 className="texte-jaune pt-2">Analyse de la réputation <br className="d-none d-xl-block"/>de vos concurrents</h2>
                        <p className="chapeaux">Un indicateur clé à surveiller dans le cadre de la veille concurrentielle et même technologique</p>
                        <p>L’e-reputation révèle tout ce que les données officielles ne mettent pas en lumière. Surveiller l’e-reputation de ses concurrents, c’est ainsi se renseigner sur l’image de l’entreprise et la noter : est-elle fiable ? Ses produits répondent-ils aux attentes des clients ? Paie-t-elle ses salariés et prestataires en temps ? Satisfait-elle ses clients ? A-t-elle connu des crises d’image ces dernières années et pourquoi ? quel est leur point faible ? L’e-reputation permet d’extraire la vérité d’une entreprise, ce qu’aucun document officiel ne pourra combler.</p>
                    </div>

                </div>

            </div>
        </section>

    )
}

export default AnalyseConcurrents
