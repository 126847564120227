import React from 'react'
import styled from 'styled-components'
import imgAchat from '../../../images/achat-espace.svg'

const AchatEspace = () => {
    return (
        <SectionCSS className="bg-white pt-150 pb-150">

            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-md-6 text-center pb-50 order-md-2 align-self-center img-left-mobile">
                        <img src={imgAchat} alt="" data-aos="zoom-in-up" />
                    </div>

                    <div className="col-md-6 order-md-1 pr-100">
                        <h2 className="texte-jaune">Achat d'espace publicitaire</h2>
                        <p className="chapeaux">Augmentez votre audience tout en ciblant avec précision les personnes que vous souhaitez toucher</p>
                        <p>La publicité sur les réseaux sociaux est aujourd’hui indispensable pour bénéficier d’une plus grande notoriété et pour toucher une large cible qualifiée. En effet, les différentes régies intégrées à chaque réseau social disposent de nombreux critères de segmentation et de ciblage afin de toucher les prospects les plus pertinents pour votre activité.</p>

                        <p>Pour travailler votre visibilité dans une démarche prospective, y faire de la publicité peut vous aider dans vos objectifs de vente ! Les campagnes de publicités digitales sont un véritable tremplin concernant vos ventes, car elles génèrent du trafic sur le site de votre entreprise. Ce trafic draine plus de visiteurs, et donc augmente vos chances de conversion en nouveaux clients.</p>
                    </div>

                </div>

            </div>

        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    img{width: 50%;}
`

export default AchatEspace
