import React, { useContext, useEffect, useState , Fragment } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faMoneyBillWave, faCheck } from '@fortawesome/free-solid-svg-icons'
import imgDevis from '../../../images/icone-tarif-bleu.svg'
import imgMoteursRecherche from '../../../images/icone-searchengin-brands.svg'
import imgLogoGooglegAds from '../../../images/icone-g00gle-ads.svg'
import imgLogoBingAds from '../../../images/icone-bing-ads.svg'
import imgValide from '../../../images/valide.svg'
import SlideUpDown from '../../ui/SlideUpDown'
import BoutonLarge from '../../ui/BoutonLarge'
import Bouton from '../../ui/Bouton'
import InputSpinner from 'react-bootstrap-input-spinner'  
import SessionContext from '../../utils/SessionContext'
import Loader from '../../ui/Loader'

const Devis = () => {

    const session = useContext(SessionContext);

    const [seo, setSeo] = useState(false);
    const [googleAds, setGoogleAds] = useState(false);
    const [bingAds, setBingAds] = useState(false);
    const [sea, setSea] = useState(false);
    const [budget, setBudget ] = useState(0);
    const [offre, setOffre ] = useState(false);

    const seoHandler = () => {setSeo(!seo)}
    const googleAdsHandler = () => {setGoogleAds(!googleAds)}
    const bingAdsHandler = () => {setBingAds(!bingAds)}

    useEffect(() => {
        setSea(
            googleAds || bingAds
        );
    }, [googleAds, bingAds]);

    useEffect(() => {
        setOffre(
            seo || googleAds || bingAds
        );
    }, [seo, googleAds, bingAds]);

    const referencement = {
        'article' : 'Référencement',
        'contenu' : [
            seo && ('Référencement naturel'),
            googleAds && ('Campagne Google Ads'),
            bingAds && ('Campagne Bing Ads'),
            sea && budget && ('Budget mensuel : ' + Number(budget).toLocaleString() + ' FCFA')
        ]
    }

    return (
        <SectionCSS className="bg-bleu-blanc" id="devisBox">

            <div className="container">

                { session.recupService('Référencement') ? (

                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <div className="col-12 text-center">
                            <img src={imgValide} alt="" width="100" className="mb-3" data-aos="fade-in-up" />
                            <h2 className="texte-bleu">Félicitations</h2>
                            <p className="sous-titres mb-2">Vous avez ajouté Référencement à votre projet !</p>
                            <p className="sous-titres mb-3">Vous pouvez nous envoyer votre projet maintenant ou poursuivre votre visite pour y ajouter d'autres services qui pourraient vous intéresser.</p>

                            <Bouton couleur='bleu' action={session.toggleProjet}>Voir ou envoyer mon projet</Bouton>

                            <p className="sous-titres mt-4 supprimer_projet" onClick={() => session.supprimerDevis('Référencement')}>Supprimer du projet</p>
                        </div>
                    </div>

                ) : (

                    <Fragment>
                        <div className="row pb-50" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                            <div className="col-12 text-center">
                                <img src={imgDevis} alt="" width="50" className="mb-3" data-aos="zoom-in-up" />
                                <h2 className="texte-bleu">Demandez <br className="d-sm-none" />un devis</h2>
                                <p className="sous-titres">Sélectionnez les options qui correspondent à vos besoins</p>
                            </div>
                        </div>

                        <div className="row" data-aos="fade-up">

                            <div className="col-md-6 col-xl-6 offset-xl-1">

                                <SlideUpDown open={!offre}>
                                    <div id="attente-mobile" className="box-options text-center">
                                        <Loader />
                                        <p className="chapeaux" style={{maxWidth: '90%'}}>Choisissez un référencement pour démarrer</p>
                                        <FontAwesomeIcon icon={faSortDown} className='texte-bleu-clair' />
                                    </div>
                                </SlideUpDown>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-xl-1 align-self-center text-center">
                                            <img src={imgMoteursRecherche} width="40" alt=""/>
                                        </div>
                                        <div className="col-xl-8">
                                            <b className="denomination">Référencement naturel</b><br/>
                                            <span className="description">Optimiser mon site pour les moteurs de recherche</span>
                                        </div>
                                        <div className="col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={seoHandler} 
                                                    checked={seo} 
                                                    className="switch-input" 
                                                    type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-xl-1 align-self-center text-center">
                                            <img src={imgLogoGooglegAds} width="40" alt=""/>
                                        </div>
                                        <div className="col-xl-8">
                                            <b className="denomination">Campagne Google Ads</b><br/>
                                            <span className="description">Publicité sur Google et/ou les sites et applications partenaires</span><br/>
                                        </div>
                                        <div className="col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={googleAdsHandler}
                                                    checked={googleAds}
                                                    className="switch-input" 
                                                    type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-xl-1 align-self-center text-center">
                                            <img src={imgLogoBingAds} width="40" alt=""/>
                                        </div>
                                        <div className="col-xl-8">
                                            <b className="denomination">Campagne Bing Ads</b><br/>
                                            <span className="description">Publicité sur Bing et/ou les sites et applications partenaires</span><br/>
                                        </div>
                                        <div className="col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={bingAdsHandler}
                                                    checked={bingAds}
                                                    className="switch-input" 
                                                    type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                <SlideUpDown open={sea}>
                                    <div className="box-options">
                                        <div className="row">
                                            <div className="col-xl-1 align-self-center text-center">
                                                <FontAwesomeIcon icon={faMoneyBillWave} className='texte-bleu'  style={{fontSize: '1.5em'}} />
                                            </div>
                                            <div className="col-xl-7">
                                                <b className="denomination">Budget achat d'espace mensuel <span style={{fontSize: '66%', fontWeight: '300'}}>(En FCFA)</span></b><br/>
                                                <span className="description">Publicité sur les moteurs de recherche et/ou les sites et applications partenaires</span><br/>
                                            </div>
                                            <div className="col-xl-4 align-self-center text-center">
                                                <div className="boxSpinner">
                                                    <InputSpinner
                                                        type={'real'}
                                                        precision={0}
                                                        max={25000000}
                                                        min={100000}
                                                        step={10000}
                                                        value={budget}
                                                        onChange={nbr => setBudget(nbr)}
                                                        size="sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SlideUpDown>

                            </div>

                            <div className="col-md-6 col-xl-4">

                                <SlideUpDown open={!offre}>
                                    <div id="attente" className="box-options text-center" style={{padding: '15px 20px 25px 20px'}}>
                                        <Loader />
                                        <p className="chapeaux" style={{maxWidth: '80%'}}>Choisissez un référencement pour démarrer</p>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre}>

                                    <div id="offre" className="box-options pt-0 pb-0">
                                        <div className="row border-0" id="optionschoisies">
                                            <div className="col-7 col-md-8 text-start texte-bleu text-uppercase align-self-center">Options choisies</div>
                                        </div>

                                        <SlideUpDown open={seo}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">Référencement naturel</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center nombrePubHTML">
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={googleAds}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">Campagne Google Ads</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center nombrePubHTML">
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={bingAds}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">Campagne Bing Ads</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center nombrePubHTML">
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </div>
                                            </div>
                                        </SlideUpDown>
                                    </div>

                                </SlideUpDown>

                                <SlideUpDown open={offre && sea}>
                                    <div className="box-options bg-bleu-fonce">
                                        <div className="row">
                                            <div className="col-7 col-md-8 text-start text-white">Achat d'espace mensuel</div>
                                            <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center">
                                                {Number(budget).toLocaleString()}
                                            </div>
                                        </div>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre}>
                                    <BoutonLarge 
                                        couleur='bleu' 
                                        action={() => session.ajouterDevis(referencement) }>
                                        Ajouter à mon projet
                                    </BoutonLarge>
                                </SlideUpDown>

                            </div>

                        </div>

                    </Fragment>

                )}

            </div>

        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    padding: 150px 0;
    //img{width: 50%;}
`

export default Devis
