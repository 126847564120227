import React, { Fragment } from 'react'
import styled from 'styled-components'
import introMarketing from '../../images/intro-marketing-digital.svg'

const Presentation = () => {
    return (
        <Fragment>
            <DivDegrade />
            <SectionCSS className="bg-white" id='presentation'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-up">
                            <hr />
                            {/*<h3>
                                Jolof Digital, votre partenaire pour la création de solutions innovantes et performantes pour votre entreprise
    </h3>*/}
                            <h3>
                                Jolof Digital, votre partenaire pour la mise en œuvre de solutions numériques destinées aux Petites et Moyennes Entreprises (PME)
                            </h3>
                            {/*<p>
                                Nous proposons une large gamme de services allant de la création de sites web à la gestion des réseaux sociaux, en passant par la conception d'applications mobiles, la publicité en ligne, l'e-commerce, l'optimisation de moteurs de recherche (SEO) et la gestion de campagnes de marketing digital.
                            </p>*/}
                            <p>
                                Notre offre de services couvre un large éventail de besoins numériques, incluant la conception de sites web, la gestion de réseaux sociaux, la publicité en ligne, le Ecommerce, la création d'applications mobiles et l'optimisation de moteurs de recherche entre autres.
                            </p>
                            {/*<p>
                                Même si leur cœur d'activité ne repose pas sur internet, les PME doivent prendre en compte ce média ne serait-ce pour maîtriser leur réputation en ligne, donner la bonne information et engager une véritable conversation avec leurs clients, prospects et partenaires.
                            </p>*/}
                            <p>
                                Notre service est intégral pour vous permettre de concentrer vos efforts sur votre activité, en déléguant la gestion de votre présence en ligne à des professionnels expérimentés.
                            </p>
                            <p>
                                Notre approche personnalisée, combinée à une connaissance approfondie du secteur, nous permet de répondre à vos besoins spécifiques et de fournir une solution sur mesure pour chacun de vos projets.
                            </p>
                        </div>
                        <div className="col-lg-6 text-center" data-aos="zoom-in-up">
                            <img src={introMarketing} alt="" />
                        </div>
                    </div>
                </div>
            </SectionCSS>
        </Fragment>
    )
}

const SectionCSS = styled.section`
    padding: 100px 0 50px 0;
    /*@media (max-width: 991px) {
        padding: 0;
    }*/
    img{
        width : 100%;
        max-width: 490px; 
        padding: 60px 25px 25px 25px;
        margin: 0 auto;
    }
`
const DivDegrade = styled.div`
    //background: linear-gradient(to top, var(--fondpage), rgba(216,237,247,0)); 
    display: none !important;
    width: 100%; 
    height: 150px;
`

export default Presentation