import React, { Fragment, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import HeaderTitre from '../../composants/HeaderTitre'
import bgAudit from '../../images/bg-audit-e-reputation.webp'
import Intro from '../../composants/marketingDigital/auditEreputation/Intro'
import AnalyseReferencement from '../../composants/marketingDigital/auditEreputation/AnalyseReferencement'
import AnalyseSitesWeb from '../../composants/marketingDigital/auditEreputation/AnalyseSitesWeb'
import AnalyseReseauxSociaux from '../../composants/marketingDigital/auditEreputation/AnalyseReseauxSociaux'
import AnalyseConcurrents from '../../composants/marketingDigital/auditEreputation/AnalyseConcurrents'
import AnalysePersonas from '../../composants/marketingDigital/auditEreputation/AnalysePersonas'
import Recommandatons from '../../composants/marketingDigital/auditEreputation/Recommandatons'
import Devis from '../../composants/marketingDigital/auditEreputation/Devis'
import AutresServicesMarketing from '../../composants/marketingDigital/AutresServicesMarketing'
import SessionContext from '../../composants/utils/SessionContext'


const AuditEreputation = () => {

    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('marketingDigital');
    })

    return (
        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Audit e-répuration | Jolof Digital</title>
                <meta name="description" content="Découvrez comment votre marque est perçue sur le web afin de définir des points d’amélioration"></meta>
                <link rel="canonical" href="https://jolof.digital/audit-e-reputation" />
            </Helmet>

            <HeaderTitre 
                titre = {<>Audit <br/>e-réputation</>}
                texte = "Découvrez comment votre marque est perçue sur le web afin de définir des points d’amélioration">
                <img src={bgAudit} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

            <Intro />

            <AnalyseReferencement />

            <AnalyseSitesWeb />

            <AnalyseReseauxSociaux />

            <AnalyseConcurrents />

            <AnalysePersonas />

            <Recommandatons />

            <Devis />

            <AutresServicesMarketing exclure='audit-e-reputation' />
            
        </Fragment>
    )
}

export default AuditEreputation
