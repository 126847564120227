import React, { Fragment, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import HeaderTitre from '../../composants/HeaderTitre'
import bgSiteInternet from '../../images/bg-site-internet.webp'
import Intro from '../../composants/marketingDigital/siteInternet/Intro'
import RedactionContenu from '../../composants/marketingDigital/siteInternet/RedactionContenu'
import UxDesign from '../../composants/marketingDigital/siteInternet/UxDesign'
import Ecommerce from '../../composants/marketingDigital/siteInternet/Ecommerce'
import HebergementSeo from '../../composants/marketingDigital/siteInternet/HebergementSeo'
import WebAnalytics from '../../composants/marketingDigital/siteInternet/WebAnalytics'
import DifferentsSites from '../../composants/marketingDigital/siteInternet/DifferentsSites'
import SimulateurTarif from '../../composants/marketingDigital/siteInternet/SimulateurTarif'
import AutresServicesMarketing from '../../composants/marketingDigital/AutresServicesMarketing'
import SessionContext from '../../composants/utils/SessionContext'


const SiteInternet = () => {

    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('marketingDigital');
    })

    return (
        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Site internet | Jolof Digital</title>
                <meta name="description" content="Dans l’ère du digital, votre présence sur le web est le minimum vital"></meta>
                <link rel="canonical" href="https://jolof.digital/site-internet" />
            </Helmet>

            <HeaderTitre 
                titre = {<>Site <br/>internet</>}
                texte = "Dans l’ère du digital, votre présence sur le web est le minimum vital"
                largeur ='courtTexte'>
                <img src={bgSiteInternet} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

            <Intro />

            <RedactionContenu />

            <UxDesign />

            <section className="bg-blanc-bleu">

                <Ecommerce />

                <HebergementSeo />

                <WebAnalytics />

            </section>

            <section className="bg-blanc-bleu trait-blanc-bottom">

                <DifferentsSites />

                <SimulateurTarif />

            </section>

            <AutresServicesMarketing exclure="site-internet" />

        </Fragment>
    )
}

export default SiteInternet