import React from 'react'
import { css } from "@emotion/react";
import PropagateLoader from 'react-spinners/PropagateLoader'

const LoaderEmail = () => {
    const override = css`
        display: block;
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 25px;
    `;
    return (
        <PropagateLoader color='#2ac1e3' loading={true} css={override} size={20} />
    )
}

export default LoaderEmail
