import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
//import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import bgTitre from '../../images/bg-titre-header-bleu.webp'
import bgTitreXxl from '../../images/bg-titre-header-arrondi-bleu.webp'
//import videoBgMp4 from '../../videos/pexels-pavel-danilyuk-7592062_1.mp4'
//import videoBgMp4 from '../../videos/entrepreneur-720.mp4'
//import imgBgVideo from '../../videos/entrepreneur-fond.webp'
import videoBgMp4 from '../../videos/projets-jolof.mp4'
import videoBgWebm from '../../videos/projets-jolof.webm'
import imgBgVideo from '../../videos/projet-jolof-fond.webp'
//import SessionContext from '../utils/SessionContext'


const HeaderVideo = (props) => {

    //const session = useContext(SessionContext);

    const refVideo = useRef(null);

    useEffect(() => {
        if (!refVideo.current) {
            return;
        }
        refVideo.current.defaultMuted = true;
        refVideo.current.muted = true;
    });

    //const videoBgWebm = 'https://res.cloudinary.com/dqbbhtmas/video/upload/v1677703250/entrepreneur-720_xz8noc.webm';
    //const videoBgMp4 = 'https://res.cloudinary.com/dqbbhtmas/video/upload/v1677689826/entrepreneur-720_lic4zw.mp4';
    //const videoBgWebm = 'https://res.cloudinary.com/dqbbhtmas/video/upload/v1678157436/projets-jolof_bodjv1.webm';
    //const videoBgMp4 = 'https://res.cloudinary.com/dqbbhtmas/video/upload/v1678157396/projets-jolof_r8tv67.mp4';

    return (
        <>
        <SectionCSS className="p-0 header-section" id='accueil'>

            <div className={props.referencement ? 'header-titre referencement' : 'header-titre'} data-aos="fade-right" data-aos-duration="1000">
                <div className="container">
                    <h1 className="pb-3">
                        Garantissez votre <br className='d-none d-xl-block' /> part de marché <br className='d-none d-xl-block' /> grâce au numérique
                    </h1>
                    <h2 className='chapeaux '>
                        Nous sommes spécialisés dans la conception de projets digitaux créatifs axés sur les résultats
                    </h2>
                    {/*<div className='contact'>
                        <a href="https://wa.me/221772541160" className='element'>
                            <div>
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </div>
                            <div>
                                <span className='titre'>WhatsApp</span><br />
                                <span className='contenu'>+221 78 198 08 08</span>
                            </div>
                        </a>
                        <a href="https://wa.me/221772541160" className='element'>
                            <div>
                                <FontAwesomeIcon icon={faPhone} />
                            </div>
                            <div>
                                <span className='titre'>Appel</span><br />
                                <span className='contenu'>78 198 08 08</span>
                            </div>
                        </a>
                        <span onClick={() => session.defiler('contact')} className='element'>
                            <div>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <div>
                                <span className='titre'>Email</span><br />
                                <span className='contenu'>Remplir le formulaire</span>
                            </div>
                        </span>

                    </div>*/}
                </div>
            </div>

            {/*<div dangerouslySetInnerHTML={{
                __html:*/}
                <video ref={refVideo} autoPlay muted loop playsInline id="videoSlide">
                    <source src={videoBgWebm} type="video/webm" />
                    <source src={videoBgMp4} type="video/mp4" />
                </video>
            {/*}} />*/}

            <div className='fondVideo'></div>
            <div className='fondVideo2'></div>
            <div className='fondVideoImage'></div>

        </SectionCSS>
        <GlobalStyle />
        </>
    )
}


// Styles CSS
const GlobalStyle = createGlobalStyle`
    body{
        background: white !important;
    }
    header:not(.fixe) {
        background: none !important;
        //background: white !important;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) !important;
        //background: linear-gradient(to bottom, rgba(25, 184, 222, 0.75), rgba(25, 184, 222, 0)) !important;
    }
    footer {
        margin-top: 0 !important;
    }
`

const SectionCSS = styled.section`

    #videoSlide{
        width: 100%;
        height: 350px;
        object-fit: cover;
        object-position: center;
        position: absolute;
        transform: scaleX(-1);
        top: 0;
        z-index: -2;
        margin-top: 110px;
    }
    .fondVideo{
        //background: linear-gradient(45deg, rgba(242,101,34,0.5), rgba(242,101,34,0));
        //background: linear-gradient(45deg, rgba(242,101,34,1), rgba(43, 194, 228,0.5));
        //background: linear-gradient(45deg, rgba(46,47,66,1), rgba(46,47,66,0.5));
        //background: linear-gradient(45deg,rgba(216,237,247,1),rgba(216,237,247,0));
        //background: linear-gradient(45deg,rgba(255,255,255,1),rgba(255,255,255,0.5));
        //background: linear-gradient(135deg,rgba(0,0,0,0.2),rgba(0,0,0,0));
        //background: linear-gradient(45deg,rgba(43, 194, 228,0.25),rgba(43, 194, 228, 0));
        //background: linear-gradient(135deg,rgba(255, 255, 255, 1),rgba(255, 255, 255, 0));
        //background: linear-gradient(90deg,rgba(184, 87, 17, 0.9),rgba(184, 87, 17, 0.3));
        width: 100%;
        height: 100% !important;
        display: block;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .fondVideo2{
        width: 100%;
        height: 100% !important;
        display: none;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .fondVideoImage{
        width: 100%;
        height: 350px;
        margin-top: 110px;
        background: url(${imgBgVideo});
        background-size: cover;
        background-position: center !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -3;
    }

    // XS
        .header-titre {
            background: linear-gradient(-135deg, var(--bleujolof), #38a3c0);
            margin: 374px 0 0 0;
            padding: 35px 0 25px 0;
        }
        .header-titre h1 {
            font-size: 35px;
            line-height: 38px;
            color: white !important;
            font-weight: 400;
            //color: white !important;
            text-shadow: none;
        }
        .header-titre h2 {
            font-size: 20px;
            line-height: 23px;
            font-weight: 400;
            max-width: 300px;
            color: #0b414d !important;
        }
        .header-image {
            width: 100%;
            margin-bottom: 50px;
            z-index: -1;
        }
        /*.contact{
            //border: red solid thin;
            margin: 35px 0 15px 0;
            display: flex; //000
        }
        .contact .element{
            display: inline-block;
            padding: 10px 20px;
            margin: 0 5px 15px 0;
            background: #fff;
            width: 90%;
            user-select: none !important;
            cursor: pointer;
            transition: all ease-in-out 300ms;
            width: 33%; border-radius: 10px !important; text-align: center; padding: 10px 5px; //000
        }
        .contact .element:first-child{
            border-radius: 15px;
        }
        .contact .element:last-child{
            border-radius: 15px;
        }
        .contact .element div{
            display: inline-block;
            line-height: 1em;
            display: block; line-height: 0.8em; //000
        }
        .contact .element svg{
            color: var(--bleujolof) !important;
            font-size: 30px;
            padding-right: 10px;
            top: -5px;
            position: relative;
            font-size: 30px; padding: 15px 0 5px 0; //000
        }
        .contact .element .titre{
            //font-weight: 400;
            //text-transform: uppercase;
            font-size: 16px; //000
        }
        .contact .element .contenu{
            font-weight: 700;
            text-transform: uppercase;
            font-size: 16px;
            font-size: 14px; display: none; //000
        }
        .contact .element:last-child .contenu{
            font-size: 14px;
        }*/
        
    @media (min-width: 576px) { // SM
        #videoSlide{
            width: 66%; right: 0;
            height: 100%;
            object-position: right;
            margin-top: 0;
            //transform: scaleX(-1);
        }
        .fondVideo{
            //background: linear-gradient(135deg,rgba(255, 255, 255, 1),rgba(255, 255, 255, 0));
            //background: linear-gradient(90deg,rgba(25, 184, 222, 1) 50%,rgba(25, 184, 222, 0));
            background: linear-gradient(90deg,rgba(255, 255, 255, 1) 34%,rgba(255, 255, 255, 0));
            //background: rgba(23, 184, 222, 0.5);
            //background: rgba(255, 255, 255, 0.75);
        }
        .fondVideo2{
            //background: linear-gradient(135deg,rgba(255, 255, 255, 1),rgba(255, 255, 255, 0));
            //background: linear-gradient(90deg,rgba(25, 184, 222, 1) 50%,rgba(25, 184, 222, 0));
            display: block;
            background: linear-gradient(to bottom,rgba(255, 255, 255, 1),rgba(255, 255, 255, 0));
            //background: rgba(23, 184, 222, 0.5);
            //background: rgba(255, 255, 255, 0.75);
        }
        .fondVideoImage{
            height: 100%;
            margin-top: 0;
            width: 66%;
            margin-left: 34%;
            background-position: bottom left !important;
        }
        .header-titre {
            background: url( ${bgTitre} ) right;
            background-size: cover;
            margin: 150px 10% 90px 0;
            //padding: 75px 15% 65px 0;
            padding: 75px 25% 65px 0;
        }
        .header-titre h1 {
            font-size: 30px;
            line-height: 30px;
            //color: var(--bleujolof) !important;
            text-shadow: rgba(0,0,0,0.05) 1px 2px 2px;
        }
        .header-titre h2 {
            font-size: 20px;
            line-height: 25px;
            font-weight: 300;
            max-width: 350px;
            //color: var(--gristexte) !important;
        }
        .header-image {
            width: 100%;
            max-width: 600px;
            margin-bottom: 50px;
        }
        .container{
            padding-right: 0 !important;
        }
    }
    @media (min-width: 768px) { // MD
        .header-titre {
            margin: 100px 30% 120px 0;
            //padding: 75px 15% 65px 0;
            padding: 55px 15% 35px 0;
        }
        .header-titre h1 {
            font-size: 40px;
            line-height: 40px;
        }
        .header-titre h2 {
            font-size: 20px;
            line-height: 25px;
            max-width: 400px;
        }
        .header-image {
            width: 100%;
            max-width: 700px;
            margin-bottom: 50px;
        }
    }
    @media (min-width: 992px) { // LG
        .header-titre {
            margin: 110px 45% 80px 0;
            //padding: 75px 15% 65px 0;
            padding: 65px 15% 55px 0;
        }
        .header-titre h1 {
            font-size: 40px;
            line-height: 40px;
        }
        .header-titre h2 {
            font-size: 20px;
            line-height: 25px;
            max-width: 500px;
        }
        .header-image {
            width: 100%;
            max-width: 700px;
            margin-bottom: 50px;
        }
    }
    @media (min-width: 1200px) { // XL 
        margin-top: 65px;
        .header-titre {
            margin: 75px 45% 90px 0;
            //padding: 75px 15% 65px 0;
            padding: 70px 15% 55px 0;
        }
        .header-titre.referencement {
            padding: 75px 15% 30px 0;
        }
        .header-titre h1 {
            font-size: 45px;
            line-height: 45px;
            //font-weight: 700;
        }
        .header-titre h2 {
            font-size: 26px;
            line-height: 30px;
            max-width: 500px;
            min-height: 99px;
        }
        .header-image {
            width: unset;
            max-width: none;
            height: 398px /*432px*/;
            margin-bottom: 50px;
            top: 175px;
            right: 0;
            position: absolute;
        }
        /*.contact .element{
            width: auto;
            padding: 10px 20px 10px 15px;
        }
        .contact .element:hover{
            background: rgba(255, 255, 255, 0.8);
        }
        .contact .element:first-child{
            border-radius: 15px 0 0 15px;
        }
        .contact .element:last-child{
            border-radius: 0 15px 15px 0;
        }
        .contact .element svg{
            top: -3px;
        }
        .contact .element .contenu{
            font-size: 15px;
        }
        .contact .element:last-child .contenu{
            font-size: 13px;
        }*/
    }
    @media (min-width: 1600px) {
        margin-top: 91px;
        .header-titre {
            margin: 155px 42% 140px 0;
            //padding: 150px 15% 140px calc((100% - 1600px) / 2);
            padding: 110px 15% 100px calc((100% - 1600px) / 2);
        }
        .header-titre.referencement {
            padding: 150px 15% 95px calc((100% - 1600px) / 2);
        }
        .header-titre h1 {
            font-size: 60px;
            line-height: 60px;
        }
        .header-titre .chapeaux {
            max-width: 500px;
        }
        .header-titre .chapeaux.courtTexte  {
            max-width: 375px !important;
        }
        .header-image {
            width: unset;
            max-width: none;
            height: 588px /*622px*/;
            margin-bottom: 50px;
            top: 200px;
            left: 47% /*45%*/;
            position: absolute;
            border-radius: 50px;
        }
        /*.contact .element{
            width: auto;
            padding: 10px 20px;
        }
        .contact .element svg{
            top: -5px;
        }
        .contact .element .contenu{
            font-size: 16px;
        }
        .contact .element:last-child .contenu{
            font-size: 14px;
        }*/
    }
    @media (min-width: 1925px) {
        .header-titre {
            background: url( ${bgTitreXxl} ) right no-repeat;
            background-size: contain;
            margin: 235px 48% 220px 0;
            //padding: 100px 9% 100px calc((100% - 1600px) / 2);
            padding: 80px 9% 60px calc((100% - 1600px) / 2);
        }
        .header-titre.referencement {
            padding: 100px 9% 55px calc((100% - 1600px) / 2);
        }
        .header-titre h1 {
            font-size: 60px;
            line-height: 60px;
        }
        .header-image {
            width: unset;
            max-width: none;
            height: 498px /*532px*/;
            margin-bottom: 50px;
            top: 200px;
            left: 48% /*47%*/;
            position: absolute;
        }
    }

    // iPhone
    .IIV::-webkit-media-controls-play-button,
    .IIV::-webkit-media-controls-start-playback-button {
        opacity: 0;
        pointer-events: none;
        width: 5px;
    }

`


export default HeaderVideo
