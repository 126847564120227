import React, { Fragment, useContext, useEffect } from 'react'
//import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import HeaderTitre from '../composants/HeaderTitre'
import bgNone from '../images/bg-none.png'
import SessionContext from '../composants/utils/SessionContext'


const Agence = () => {

    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('agence');
    })

    return (
        <Fragment>
            
            <Helmet>
                <meta charSet="utf-8" />
                <title>A propos de l'agence | Jolof Digital</title>
                <meta name="description" content="Agence spécialisée dans le marketing digital et les systèmes d’information avec une connaissance globale 360°"></meta>
                <link rel="canonical" href="https://jolof.digital/agence" />
            </Helmet>

            <HeaderTitre 
                titre = {<Fragment>A propos <br/> de l'agence</Fragment>}
                texte = 'Optimisez la notoriété de votre enseigne et boostez votre chiffre d’affaires'>
                <img src={bgNone} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

        </Fragment>
    )
}

export default Agence
