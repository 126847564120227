import React, { Fragment, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import HeaderTitre from '../../composants/HeaderTitre'
import bgCommunityManagement from '../../images/bg-community-management.webp'
import Intro from '../../composants/marketingDigital/communityManagement/Intro'
import Strategie from '../../composants/marketingDigital/communityManagement/Strategie'
import CharteEditoriale from '../../composants/marketingDigital/communityManagement/CharteEditoriale'
import PlanningCreations from '../../composants/marketingDigital/communityManagement/PlanningCreations'
import AnimationModeration from '../../composants/marketingDigital/communityManagement/AnimationModeration'
import AchatEspace from '../../composants/marketingDigital/communityManagement/AchatEspace'
import AnalyseReporting from '../../composants/marketingDigital/communityManagement/AnalyseReporting'
import Devis from '../../composants/marketingDigital/communityManagement/Devis'
import AutresServicesMarketing from '../../composants/marketingDigital/AutresServicesMarketing'
import SessionContext from '../../composants/utils/SessionContext'

const CommunityManagement = () => {
    
    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('marketingDigital');
    })

    return (
        <Fragment>
            
            <Helmet>
                <meta charSet="utf-8" />
                <title>Community management | Jolof Digital</title>
                <meta name="description" content="Assurez votre présence sur les réseaux sociaux et répondez à vos objectifs de relation client et de notoriété"></meta>
                <link rel="canonical" href="https://jolof.digital/community-management" />
            </Helmet>

            <HeaderTitre 
                titre = {<Fragment>Community <br/>management</Fragment>}
                texte = "Assurez votre présence sur les réseaux sociaux et répondez à vos objectifs de relation client et de notoriété">
                <img src={bgCommunityManagement} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

            <Intro />

            <Strategie />

            <CharteEditoriale />

            <PlanningCreations />

            <AnimationModeration />

            <AchatEspace />

            <AnalyseReporting />

            <Devis />

            <AutresServicesMarketing exclure="community-management" />
            
        </Fragment>
    )
}

export default CommunityManagement
