import React, { Fragment, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import HeaderTitre from '../../composants/HeaderTitre'
import bgApplicationMobile from '../../images/bg-application-mobile.webp'
import Intro from '../../composants/marketingDigital/applicationMobile/Intro'
import PhaseConception from '../../composants/marketingDigital/applicationMobile/PhaseConception'
import UxDesign from '../../composants/marketingDigital/applicationMobile/UxDesign'
import Developpement from '../../composants/marketingDigital/applicationMobile/Developpement'
import ConsoleAdmin from '../../composants/marketingDigital/applicationMobile/ConsoleAdmin'
import Publication from '../../composants/marketingDigital/applicationMobile/Publication'
import MobileAnalytics from '../../composants/marketingDigital/applicationMobile/MobileAnalytics'
import Devis from '../../composants/marketingDigital/applicationMobile/Devis'
import AutresServicesMarketing from '../../composants/marketingDigital/AutresServicesMarketing'
import SessionContext from '../../composants/utils/SessionContext'


const ApplicationMobile = () => {

    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('marketingDigital');
    })

    return (
        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Application mobile | Jolof Digital</title>
                <meta name="description" content="Offrez à vos clients la meilleure expérience mobile possible sur smartphones Android et IOS"></meta>
                <link rel="canonical" href="https://jolof.digital/application-mobile" />
            </Helmet>

            <HeaderTitre 
                titre = {<>Application <br/>mobile</>}
                texte = "Offrez à vos clients la meilleure expérience mobile possible sur smartphones Android et IOS"
                largeur ='courtTexte'>
                <img src={bgApplicationMobile} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

            <Intro />

            <PhaseConception />

            <section className="bg-blanc-jaune">
                
                <UxDesign />
                
                <Developpement />

            </section>
            
            <ConsoleAdmin />

            <Publication />

            <MobileAnalytics />

            <Devis />

            <AutresServicesMarketing exclure="application-mobile" />

        </Fragment>
    )
}

export default ApplicationMobile