import React, { Fragment } from 'react'
import styled from 'styled-components'
import methodologie from '../../../images/methodologie.svg'

const Intro = () => {
    return (
        <Fragment>
            <DivDegrade />
            <SectionCSS className="bg-bleu-blanc">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up">
                        <hr />
                        <h3>
                            Générez de l’engagement  <br className="d-none d-xl-block" />grâce au community management
                        </h3>
                        <p>
                            Le principal avantage des réseaux sociaux, c’est la proximité avec les clients qu’ils offrent à votre entreprise. C’est un canal de communication direct qui permet aux utilisateurs de faire part de leur expérience avec vos produits ou services. C’est un aspect qu’on ne retrouve pas toujours sur les autres supports de marketing et de communication. Bien sûr, en exposant votre entreprise aux réseaux sociaux vous prenez le risque de commentaires négatifs préjudiciables à votre marque. Il ne faut pas y voir un danger, mais au contraire, l’occasion de rebondir sur l’avis négatif et de proposer une solution individuelle à l’utilisateur. En cas de remarque récurrente, vous pourrez remonter le problème à vos collaborateurs et réfléchir à la façon de corriger cette tendance. La règle générale est donc de réguler ses réseaux, sans laisser les consommateurs livrés à eux-mêmes. Les avis positifs quant à eux, permettront de valoriser le produit et indirectement, l’image de marque de l’entreprise. 
                        </p>
                        <p>
                            Avec une approche inclusive, Jolof digital vous accompagne durant tout le processus afin de développer votre présence en ligne, engager votre communauté, promouvoir vos produits & services, améliorer votre e-réputation, générer de la conversation et augmenter le capital sympathie de votre enseigne.
                        </p>
                    </div>
                    <div className="col-lg-6" data-aos="zoom-in-up">
                        <div className="methodologie">

                            <div className="row">
                                <div className="col-xl-5 order-xl-2">
                                    <img src={methodologie} alt="" />
                                </div>
                                <div className="col-xl-7 order-xl-1">
                                    <h3 className="texte-bleu">Notre <br />méthodologie</h3>
                                    <ol>
                                        <li>Briefing avec le client</li>
                                        <li>Définition de la stratégie</li>
                                        <li>Définition de la charte éditoriale</li>
                                        <li>Planning de publication mensuel</li>
                                        <li>Créations graphiques</li>
                                        <li>Validation avec le client</li>
                                        <li>Animation et modération</li>
                                        <li>Achat d'espace publicitaire</li>
                                        <li>Analyse et reporting</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </SectionCSS>
        </Fragment>
    )
}

const SectionCSS = styled.section`
    .methodologie {
        background: #fff;
        box-shadow: rgba(2, 45, 75, 0.1) 0 10px 20px;
        border-radius: 10px;
        padding: 30px;
        margin: 50px 0 0 0;
    }
    .methodologie ol {
        list-style: none;
        counter-reset: li;
        padding-left: 0;
    }
    .methodologie li::before {
        content: counter(li) ". ";
        color: var(--bleujolof);
        font-size: 18px;
        margin-left: -19px;
    }
    .methodologie li {
        counter-increment: li;
        margin-left: 19px;
    }
    .methodologie img {
        width: 100%;
        margin-bottom: 50px;
        max-width: 150px;
    }
    @media (min-width: 576px) {
        .methodologie {
            padding: 50px;
        }
    }
    @media (min-width: 768px) {
        .methodologie img {
            width: 100%;
            margin-top: 50px;
            max-width: 200px;
        }
    }
    @media (min-width: 992px) {
        .methodologie {
            margin: 50px 0 0 50px;
        }
        .methodologie img {
            width: 100%;
            margin-top: 0;
            margin-bottom: 50px;
            max-width: 150px;
        }
    }
    @media (min-width: 1200px) {
        .methodologie img {
            margin-top: 50px;
            margin-bottom: 0;
            max-width: 200px;
        }
    }
`
const DivDegrade = styled.div`
    background: linear-gradient(to top, var(--fondpage), rgba(216,237,247,0)); 
    width: 100%; 
    height: 150px;
`

export default Intro
