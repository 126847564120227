import React from 'react'
import imgConsole from '../../../images/console-administration.svg'

const ConsoleAdmin = () => {
    return (
        <section className="bg-white trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-md-6 text-center pb-5 pt-5 order-md-2 align-self-center img-left-mobile">
                        <img src={imgConsole} alt="" width="90%" data-aos="zoom-in-up" />
                    </div>

                    <div className="col-md-6 pr-100 order-md-1">
                        <h2 className="texte-jaune pt-5">Console d'administration</h2>
                        <p className="chapeaux">Les applications mobiles assez simples pour ne pas avoir besoin d’un back-end pour fonctionner efficacement sont rares</p>
                        <p>En général, une console d'administration est nécessaire pour enregistrer vos clients et leurs données, les authentifier, traiter leurs actions, communiquer avec eux, gérer vos produits, assurer le suivi de l’utilisation de l’application mobile, etc.</p>

                        <p>En plus du développement mobile, une application Web complète devra probablement être construite. Cela soulève plusieurs questions, et parmi elles, celle de la technologie qui doit être utilisée pour construire le backend, comment l’héberger, ainsi que la façon de gérer les notifications et les analyses. Nous avons la connaissance de l’infrastructure de serveur Web, de la programmation côté serveur et de la gestion de base de données pour créer le backend qui conviendra à votre application mobile.</p>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default ConsoleAdmin
