import React, { useState, useEffect } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const SessionContext = React.createContext({
    devis: false,
    ajouterDevis: () => {},
    supprimerDevis: () => {},
    recupService: () => {},
    menu: false,
    projet: false,
    toggleMenu: () => {},
    toggleProjet: () => {},
    fermerMenuAnimation: () => {},
    fermerProjetAnimation: () => {},
    menuActif: '',
    setMenuActif: () => {},
    defiler: () => {},
    defilerMobile: () => {},
    defilerHaut: () => {},
    defilerHautMobile: () => {}
});

export const SessionContextProvider = (props) => {

    const [devis, setDevis] = useState([]);         // Services ajoutés au devis
    const [menu, setMenu] = useState(false);        // Afficher le volet menu
    const [projet, setProjet] = useState(false);    // Afficher le volet projet
    const [menuActif, setMenuActif] = useState(''); // Menu actif sur HeaderMenu.jsx
    
    const ajouterDevis = (newService) => {
        setDevis((oldServices) => {
            var servicesAajouter = [...oldServices, newService];
            localStorage.setItem('devisStorage', JSON.stringify(servicesAajouter));
            return servicesAajouter
        });
        document.querySelector('#devisBox').scrollIntoView({behavior: "instant"});
    }
    const supprimerDevis = (service) => {
        var servicesAremettre = devis.filter(elements => elements.article !== service);
        setDevis(servicesAremettre);
        localStorage.setItem('devisStorage', JSON.stringify(servicesAremettre));
    }
    useEffect(() => {
        const devisStorage = JSON.parse(localStorage.getItem('devisStorage'));
        if (devisStorage && devisStorage.length > 0 && devis.length === 0) {
            setDevis(devisStorage);
        }
    }, [devis.length])
    const recupService = (service) => {
        return devis.find( (az) => az.article === service)
    }

    const toggleMenu = () => {
        setMenu(!menu)
        masquerMenuFixe()
    }
    const toggleProjet = () => {
        setProjet(!projet)
        fermerMenuAnimation()
        masquerMenuFixe()
    }
    const fermerMenuAnimation = () => {
        if(document.getElementById('fermerVolet')){
            document.getElementById('fermerVolet').classList.add('fonduSortieRapide');
            document.getElementById('fondOverlay').classList.add('fonduSortie');
            document.getElementById('menuBody').classList.add('slideSortie');
            setTimeout(toggleMenu, 500);
        }
    }
    const fermerProjetAnimation = () => {
        document.getElementById('fermerVolet').classList.add('fonduSortieRapide');
        document.getElementById('fondOverlay').classList.add('fonduSortie');
        document.getElementById('projetBody').classList.add('slideSortie');
        setTimeout(toggleProjet, 500);
    }
    const afficherMenuFixe = () => {
        document.getElementById('headerMenu').classList.add('fixe');
        document.getElementById('headerMenu').classList.remove('slideSortieHeader');
    }
    const masquerMenuFixe = () => {
        document.getElementById('headerMenu').classList.remove('fixe');
    }
    const animerSortieMenuFixe = () => {
        document.getElementById('headerMenu').classList.add('slideSortieHeader');
        setTimeout(masquerMenuFixe, 300);
    }
    useScrollPosition(({ prevPos, currPos }) => {

        let afficherMenu = currPos.y < -300 /*&& currPos.y > prevPos.y*/;

        if(afficherMenu && !menu && !projet) {
            afficherMenuFixe()
        } else if(menu || projet) {
            masquerMenuFixe()
        } else {
            animerSortieMenuFixe()
        }
    });
    const defiler = (props) => {
        const element = document.getElementById(props);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const defilerMobile = (props) => {
        const element = document.getElementById(props);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        toggleMenu();
    }
    const defilerHaut = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    const defilerHautMobile = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        toggleMenu();
    }

    return (
        <SessionContext.Provider
          value={{
              devis: devis,
              ajouterDevis: ajouterDevis,
              supprimerDevis: supprimerDevis,
              recupService: recupService,
              menu: menu,
              projet: projet,
              toggleMenu: toggleMenu,
              toggleProjet: toggleProjet,
              fermerMenuAnimation: fermerMenuAnimation,
              fermerProjetAnimation: fermerProjetAnimation,
              menuActif: menuActif,
              setMenuActif: setMenuActif,
              defiler: defiler,
              defilerMobile: defilerMobile,
              defilerHaut: defilerHaut,
              defilerHautMobile: defilerHautMobile
          }}>
          {props.children}
        </SessionContext.Provider>
    )
}

export default SessionContext;