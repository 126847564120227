import React, { Fragment } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDraftingCompass } from '@fortawesome/free-solid-svg-icons'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import imgTransfavo from '../../images/references/site_transfavo.webp'
import imgMaraz from '../../images/references/site_maraz.webp'
import imgEden from '../../images/references/site_edenimmo.webp'
import imgAcadaf from '../../images/references/site_acadaf.webp'
import imgAfdal from '../../images/references/site_afdal.webp'
import imgCioSenegal from '../../images/references/site_ciosenegal.webp'
import imgImanStore from '../../images/references/site_imanstore.webp'
import imgImpertinence from '../../images/references/site_impertinence.webp'
import imgMsa from '../../images/references/site_msa.webp'
import imgRemugol from '../../images/references/site_remugol.webp'
import imgSafaye from '../../images/references/site_safaye.webp'
import imgSenOuvrier from '../../images/references/site_senouvrier.webp'
import imgTalents from '../../images/references/site_talents.webp'
import imgBis from '../../images/references/site_bis.webp'
import imgAida from '../../images/references/site_aidasenegal.webp'
import Realisations from '../ui/Realisations'

const NosRealisations = () => {

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        pauseOnHover: false,
        //centerMode: true,
        //centerPadding: "260px",
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
            breakpoint: 2000,
                settings: {
                    slidesToShow: 3
                }
            },
            {
            breakpoint: 1400,
                settings: {
                    slidesToShow: 3
                }
            },
            {
            breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
            breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    rows: 2
                }
            }
        ]
    };

    return (
        <Fragment>
            <SectionTitre  className="bg-bleu-clair" id='realisations'>
                <div className="container">

                    <div className="text-center pb-50">
                        <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                            <FontAwesomeIcon icon={faDraftingCompass} />
                        </div>
                    </div>

                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <div className="col-12 text-center">
                            <h2 className="texte-bleu">Quelques réalisations</h2>
                            <p className="sous-titres">Plongez dans l'univers de nos réalisations créatives et innovantes qui ont su répondre aux besoins uniques de nos clients</p>
                        </div>
                    </div>
                </div>
            </SectionTitre>

            <SectionRealisations className="bg-white pt-0 mt-0">
                <div className="container.000">

                <div className="row" data-aos="fade-up">

                    <div className="col-12">

                        <div id="liste">

                            <Slider {...settings}> 

                                <div className="item">
                                    <Realisations lien='transfavo.com' nom='Transfavo'>
                                        <img src={imgTransfavo} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='marazorigins.com' nom='Maraz'>
                                        <img src={imgMaraz} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='edenimmo-saly.com' nom='Eden Immo Saly'>
                                        <img src={imgEden} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='aida-senegal.com' nom='Aida Sénégal'>
                                        <img src={imgAida} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='acadaf-afrique.org' nom='ACADAF Afrique'>
                                        <img src={imgAcadaf} alt="" />
                                    </Realisations>
                                </div>
                                
                                {/*
                                <div className="item">
                                    <Realisations lien='deuggu-ak-liggey.com' nom='AFDAL'>
                                        <img src={imgAfdal} alt="" />
                                    </Realisations>
                                </div>
                                */}

                                <div className="item">
                                    <Realisations lien='demo.jolof.digital/ciosenegal' nom='CIO Sénégal'>
                                        <img src={imgCioSenegal} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='iman-store.com' nom='Iman Store'>
                                        <img src={imgImanStore} alt="" />
                                    </Realisations>
                                </div>
                                
                                <div className="item">
                                    <Realisations lien='impertinence.shop' nom="Joyaux d\'impertinence">
                                        <img src={imgImpertinence} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='multiservices-agricoles.com' nom='MSA'>
                                        <img src={imgMsa} alt="" />
                                    </Realisations>
                                </div>
                                
                                <div className="item">
                                    <Realisations lien={null} nom='BIS'>
                                        <img src={imgBis} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='remugol.com' nom='Remugol'>
                                        <img src={imgRemugol} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='demo.jolof.digital/safaye-consultancy' nom='Safaye Consultancy'>
                                        <img src={imgSafaye} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='demo.jolof.digital/senouvrier' nom='Senouvrier'>
                                        <img src={imgSenOuvrier} alt="" />
                                    </Realisations>
                                </div>

                                <div className="item">
                                    <Realisations lien='talentsconsult.com' nom='Talents Consulting'>
                                        <img src={imgTalents} alt="" />
                                    </Realisations>
                                </div>

                            </Slider>

                        </div>

                    </div>

                    </div>
                </div>
            </SectionRealisations>
        </Fragment>
    )
}

export default NosRealisations


const SectionTitre = styled.section`
    padding: 150px 0 0 0;
    svg{
        font-size: 50px; 
        //color: var(--bleujolof) !important;
    }
    h2{
        font-weight: 700;
        text-transform: uppercase;
    }
`

const SectionRealisations = styled.section`
    background: linear-gradient(to bottom, var(--fondpage), var(--bleujolof)) !important;

    padding: 100px 0;

    .row{
        width: 150%;
        margin-left: -25%;
    }
    #liste .item{
        padding: 75px 15px 30px 15px;
    }
    @media (max-width: 600px) {
        .row{
            width: 100%;
            margin-left: 0;
        }
        #liste .item{
            padding: 30px;
        }
    }
    #liste .realisation{
        //background: #fff; 
        //padding: 50px 35px; 
        //box-shadow: rgba(0,0,0,0.1) 0 10px 20px; 
        border-radius: 10px; 
        overflow: hidden;
        //border-bottom: var(--fondpage) 5px solid; 
        text-align: center;
    }
    #liste .realisation img{
        width: 100%;
        //height: 100px; 
        //max-width: 75px; 
        //padding-bottom: 25px; 
        margin: 0 auto;
        transition: all 300ms ease-in-out;
    }
    #liste .realisation img:hover{
        filter: grayscale(100%);
        //filter: blur(1px);
        opacity: 0.75;
        cursor: pointer;
    }
    #liste .realisation .chapeaux{
        color: var(--bleujolof) !important;
    }
    @media (min-width: 1700px) {
        #liste .realisation p:not(.chapeaux){
            min-height: 120px;
        }
    }
`