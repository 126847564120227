import React from 'react'
import imgStores from '../../../images/stores.webp'

const Publication = () => {
    return (
        <section className="bg-white trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pb-50 pt-5 align-self-center text-center img-left-mobile">
                        <img src={imgStores} alt="" width="60%" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune pt-5">Publication sur les stores</h2>
                        <p className="chapeaux">Une fois le développement terminé, les applications doivent être disponibles sur le Play Store pour Android et l’App Store d’Apple pour iOS</p>
                        <p>Nous déployons chaque application en procédant à son inscription, à sa mise en ligne, au suivi de la validation, au remplissage de la fiche de présentation accompagnée de captures d'écran, à la catégorisation, à la classification du contenu et à la définition de la politique de prix, de distribution et de confidentialité.</p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Publication
