import React from 'react'
import styled from 'styled-components'
import planning from '../../../images/planning-publication.svg'
import creations from '../../../images/creations-graphique.svg'
import fondBleu from '../../../images/fond-arrondi-bleu.webp'
import fondJaune from '../../../images/fond-arrondi-jaune.webp'


const CharteEditoriale = () => {
    return (
        <SectionCSS className="pt-0 pb-0" data-aos="fade-up">

            <div className="container resetContainerMobile">

                <div className="row">

                    <div className="col-lg-6 pt-100 pb-100 pr-100 box-planning-publication">
                        <img src={planning} alt="" data-aos="zoom-in-up" />
                        <h2 className="texte-jaune pt-5">Planning de publication</h2>
                        <p className="chapeaux text-white pr-100">Le planning décline les contenus qui doivent être créés et publiés sur vos différentes pages</p>
                        <p className="text-white">Nous engageons votre communauté dans la continuité avec un planning équilibré et régulier, marqué par des temps forts, des promotions, des jeux-concours ou du social gaming pour fédérer les internautes autour de vos pages.</p>

                        <p className="text-white">Toutes les propositions de publication qui sont dans le planning sont accompagnées de visuels illustratifs qui devront être validés par le client.</p>

                        <p className="text-white">Pour un contrat continu, le planning est mensuel et est produit/validé avant la fin du mois. Pour les projets ponctuels (lancement d'une marque), il est fourni avant le démarrage de la campagne.</p>
                    </div>

                    <div className="col-lg-6 pt-100 box-creations-graphiques">
                        <img src={creations} alt="" data-aos="zoom-in-up" />
                        <h2 className="texte-bleu-fonce pt-5">Créations graphiques</h2>
                        <p className="chapeaux text-white pr-100">Nos équipes donnent vie au planning en représentant visuellement chaque publication</p>
                        <p className="text-white">Les publications avec visuels engendrent beaucoup plus d’interactions sur les réseaux sociaux que les publications avec du texte uniquement. Pas surprenant, notre cerveau traite les images plus rapidement que le texte et la plupart des internautes ont la paresse de lire un texte s'ils ne savent pas à priori de quoi ça parle.</p>

                        <p className="text-white">De beaux visuels non pixélisés (flous) et ajustés au bon format selon le réseau social choisi contribueront à faire valoir la crédibilité et le professionnalisme de votre entreprise. De plus, ça rend votre entreprise à la fois plus sympathique et plus humaine.</p>

                    </div>
                </div>
            </div>

        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    background: var(--fondpage);
    img{height: 175px;}
    
    .container {
        padding: 0 10px;
    }
    .box-planning-publication {
        background: url(${fondBleu}) right no-repeat;
        background-size: cover;
        padding-right: 50px;
        padding-left: 45px;
        margin: 0 0 50px 0;
        max-width: calc(100% - 20px);
    }
    .box-creations-graphiques {
        background: url(${fondJaune}) left no-repeat;
        background-size: cover;
        padding-left: 50px;
        padding-right: 45px;
        margin: 0 0 0 20px;
    }
    @media (min-width: 992px) {

        background: url(${fondBleu}) left no-repeat, url(${fondJaune}) right no-repeat;
        background-size: 40% 100%;

        .box-planning-publication {
            padding-left: 15px;
            margin: 0;
        }
        .box-creations-graphiques {
            padding-left: 100px;
            padding-right: 15px;
            margin: 0;
        }
    }

    @media (max-width: 991px) {
        .box-planning-publication {
            padding: 95px !important;
        }
        .box-creations-graphiques {
            padding: 95px 95px 95px 75px !important;
        }
    }
    @media (max-width: 575px) {
        .box-planning-publication {
            padding: 55px !important;
        }
        .box-creations-graphiques {
            padding: 55px !important;
        }
    }
`

export default CharteEditoriale
