import React, { Fragment } from 'react'
import styled from 'styled-components'
import methodologie from '../../../images/methodologie.svg'

const Intro = () => {
    return (
        <Fragment>
            <DivDegrade />
            <SectionCSS className="bg-bleu-blanc">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up">
                        <hr />
                        <h3>
                            Votre liste de contact est une ressource des plus importantes, si vous apprenez à bien la gérer, le temps que vous dédiez à votre travail sera bien plus rentable <span style={{fontSize: '66%'}}>(Neil Patel)</span>
                        </h3>
                        <p>
                            L’email marketing est une solution très efficace et peu coûteux pour une entreprise souhaitant créer ou conserver un lien avec sa clientèle ou d’éventuels prospects. Il peut être utilisé sous différentes formes afin de personnaliser chaque message envoyé. Votre service commercial aura pour mission de définir les paramètres de segmentation ou critères de ciblage d’après les données de la base clientèle afin de choisir les meilleurs contacts en fonction du service ou produit mis en avant.
                        </p>
                        <p>
                            L'email marketing ne consiste pas seulement envoyer une newsletter, c'est surtout une stratégie digitale permettant de relier le contenu de vos emails avec les intérêts de vos clients. Utilisez les réseaux sociaux et votre site pour attirer de nouveaux abonnés et l'emailing pour une gestion complète de vos relations clients. Vos messages sont mis en forme et envoyés par nos équipes à votre liste d'abonnés.
                        </p>
                    </div>
                    <div className="col-lg-6" data-aos="zoom-in-up">
                        <div className="methodologie">

                            <div className="row">
                                <div className="col-xl-5 order-xl-2">
                                    <img src={methodologie} alt="" />
                                </div>
                                <div className="col-xl-7 order-xl-1">
                                    <h3 className="texte-bleu">Notre <br />méthodologie</h3>
                                    <ol>
                                        <li>Briefing avec le client</li>
                                        <li>Définition de la stratégie</li>
                                        <li>Mise en place technique</li>
                                        <li>Conception des modèles</li>
                                        <li>Définition de la charte graphique et éditoriale des emails</li>
                                        <li>Envoi programmé</li>
                                        <li>Suivi analytique</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </SectionCSS>
        </Fragment>
    )
}

const SectionCSS = styled.section`
    .methodologie {
        background: #fff;
        box-shadow: rgba(2, 45, 75, 0.1) 0 10px 20px;
        border-radius: 10px;
        padding: 30px;
        margin: 50px 0 0 0;
    }
    .methodologie ol {
        list-style: none;
        counter-reset: li;
        padding-left: 0;
    }
    .methodologie li::before {
        content: counter(li) ". ";
        color: var(--bleujolof);
        font-size: 18px;
        margin-left: -19px;
    }
    .methodologie li {
        counter-increment: li;
        margin-left: 19px;
    }
    .methodologie img {
        width: 100%;
        margin-bottom: 50px;
        max-width: 150px;
    }
    @media (min-width: 576px) {
        .methodologie {
            padding: 50px;
        }
    }
    @media (min-width: 768px) {
        .methodologie img {
            width: 100%;
            margin-top: 50px;
            max-width: 200px;
        }
    }
    @media (min-width: 992px) {
        .methodologie {
            margin: 50px 0 0 50px;
        }
        .methodologie img {
            width: 100%;
            margin-top: 0;
            margin-bottom: 50px;
            max-width: 150px;
        }
    }
    @media (min-width: 1200px) {
        .methodologie img {
            margin-top: 50px;
            margin-bottom: 0;
            max-width: 200px;
        }
    }
`
const DivDegrade = styled.div`
    background: linear-gradient(to top, var(--fondpage), rgba(216,237,247,0)); 
    width: 100%; 
    height: 150px;
`

export default Intro
