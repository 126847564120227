import React, {useContext, useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faDesktop, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'
import imgDevis from '../../../images/icone-tarif-bleu.svg'
import imgValide from '../../../images/valide.svg'
import SlideUpDown from '../../ui/SlideUpDown'
import BoutonLarge from '../../ui/BoutonLarge'
import Bouton from '../../ui/Bouton'
import SessionContext from '../../utils/SessionContext'
import Loader from '../../ui/Loader'

const Devis = () => {

    const session = useContext(SessionContext);

    const [android, setAndroid ] = useState(false);
    const [ios, setIos ] = useState(false);
    const [console, setConsole ] = useState(false);
    const [offre, setOffre ] = useState(false);
    const [alerte, setAlerte ] = useState(false);

    const androidHandler = () => {
        setAndroid(!android)
        setAlerte(false)
    }
    const iosHandler = () => {
        setIos(!ios)
        setAlerte(false)
    }
    const consoleHandler = () => {
        if(!android && !ios){
            setAlerte(true)
            setConsole(false)
        } else {
            setConsole(!console)}
    }

    useEffect(() => {
        setOffre( android || ios );
        setConsole( (android || ios) ? console : false );
    }, [android, ios, console]);

    useEffect(() => {
        if(alerte) {
            setTimeout(
                () => setAlerte(false), 5000
            )
        }
    }, [alerte]);

    const applicationMobile = {
        'article' : 'Application mobile',
        'contenu' : [
            android && 'Application Android',
            ios && 'Application IOS',
            console && 'Console d\'administration'
        ]
    }

    return (
        <SectionCSS className="bg-bleu-blanc" id="devisBox">

            <div className="container">

                { session.recupService('Application mobile') ? (

                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <div className="col-12 text-center">
                            <img src={imgValide} alt="" width="100" className="mb-3" data-aos="fade-in-up" />
                            <h2 className="texte-bleu">Félicitations</h2>
                            <p className="sous-titres mb-2">Vous avez ajouté Application Mobile à votre projet !</p>
                            <p className="sous-titres mb-3">Vous pouvez nous envoyer votre projet maintenant ou poursuivre votre visite pour y ajouter d'autres services qui pourraient vous intéresser.</p>

                            <Bouton couleur='bleu' action={session.toggleProjet}>Voir ou envoyer mon projet</Bouton>

                            <p className="sous-titres mt-4 supprimer_projet" onClick={() => session.supprimerDevis('Application mobile')}>Supprimer du projet</p>
                        </div>
                    </div>

                ) : (

                    <Fragment>
                        <div className="row pb-100" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                            <div className="col-12 text-center">
                                <img src={imgDevis} alt="" width="50" className="mb-3" data-aos="zoom-in-up" />
                                <h2 className="texte-bleu">Demandez <br className="d-sm-none" />un devis</h2>
                                <p className="sous-titres">Sélectionnez les options qui correspondent à vos besoins</p>
                            </div>
                        </div>

                        <div className="row" data-aos="fade-up">

                            <div className="col-md-6 offset-md-1">

                                <SlideUpDown open={!offre}>
                                    <div id="attente-mobile" className="box-options text-center">
                                        <Loader />
                                        <p className="chapeaux" style={{maxWidth: '90%'}}>Choisissez un système d'exploitation pour démarrer</p>
                                        <FontAwesomeIcon icon={faSortDown} className='texte-bleu-clair' />
                                    </div>
                                </SlideUpDown>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <FontAwesomeIcon icon={faGooglePlay} className='texte-bleu' style={{fontSize: '2em'}} />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Application Android</b><br/>
                                            <span className="description">Pour les smartphones de marque Samsung, Google, LG, Xiaomi, Oppo, Tecno, Motorola, OnePlus, Sony, Nokia, Lenovo, etc.</span>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input onChange={androidHandler} checked={android} className="switch-input" type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <FontAwesomeIcon icon={faAppStore} className='texte-bleu' style={{fontSize: '2em'}} />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Application IOS</b><br/>
                                            <span className="description">Pour les smartphones de marque Apple (iPhone)</span><br/>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input onChange={iosHandler} checked={ios} className="switch-input" type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <FontAwesomeIcon icon={faDesktop} className='texte-bleu' style={{fontSize: '2em'}} />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Console d'administration</b><br/>
                                            <span className="description">Pour gérer et alimenter votre application mobile</span><br/>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input onChange={consoleHandler} checked={console} className="switch-input" type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <SlideUpDown open={alerte}>
                                        <div className="row bg-bleu0 texte-bleu alertedevis">
                                            Veuillez choisir au moins un système d'exploitation pour démarrer
                                        </div>
                                    </SlideUpDown>

                                </div>

                            </div>

                            <div className="col-md-4">

                                <SlideUpDown open={!offre}>
                                    <div id="attente" className="box-options text-center" style={{padding: '20px 20px 33px 20px'}}>
                                        <Loader />
                                        <p className="chapeaux" style={{maxWidth: '80%'}}>Choisissez un système d'exploitation pour démarrer</p>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre}>

                                    <div id="offre" className="box-options pt-0 pb-0">
                                        <div className="row border-0" id="optionschoisies">
                                            <div className="col-7 col-md-8 text-start texte-bleu text-uppercase align-self-center">Options choisies</div>
                                        </div>

                                        <SlideUpDown open={android}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">Application Android</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center"><FontAwesomeIcon icon={faCheck} /></div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={ios}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">Application IOS</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center"><FontAwesomeIcon icon={faCheck} /></div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={console}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">Console d'administration</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center"><FontAwesomeIcon icon={faCheck} /></div>
                                            </div>
                                        </SlideUpDown>
                                    </div>

                                    <SlideUpDown open={offre}>
                                        <BoutonLarge 
                                            couleur='bleu' 
                                            action={() => session.ajouterDevis(applicationMobile) }>
                                            Ajouter à mon projet
                                        </BoutonLarge>
                                    </SlideUpDown>

                                </SlideUpDown>

                            </div>

                        </div>

                    </Fragment>

                ) }

            </div>

        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    padding: 150px 0;
    //img{width: 50%;}
`

export default Devis
