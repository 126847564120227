import React from 'react'
import imgAudit from '../../../images/audit-existant.svg'

const AuditExistant = () => {
    return (
        <section className="bg-white trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pt-5 mt-4 pb-50 align-self-center text-center img-left-mobile">
                        <img src={imgAudit} alt="" width="70%" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune pt-5">Audit de l'existant</h2>
                        <p className="chapeaux">Un audit SEO peut être entrepris à n’importe quelle étape du cycle de vie du votre site web</p>
                        <p>Son objectif est d’identifier des axes d’amélioration et de déceler d’éventuels problèmes cachés ayant un impact sur le référencement naturel. Lors d’un audit SEO, plusieurs éléments doivent être contrôlés : le diagnostic sur Google Search Console, l'accessibilité, la compatibilité mobile, la vitesse de chargement du site,  les URL, les liens, les redirections, les mots-clés, les contenus, les sitemaps, les balises Meta et Title, protocole de sécurité, les sous-domaines, la géolocalisation, la qualité du code, les sitemaps, etc.</p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default AuditExistant
