import React, { Fragment, useContext, useEffect } from 'react'
//import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import HeaderTitre from '../../composants/HeaderTitre'
import bgEmailMarketing from '../../images/bg-email-marketing.webp'
import Intro from '../../composants/marketingDigital/emailMarketing/Intro'
import SessionContext from '../../composants/utils/SessionContext'

const EmailMarketing = () => {
    
    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('marketingDigital');
    })

    return (
        <Fragment>
            
            <Helmet>
                <meta charSet="utf-8" />
                <title>Email marketing | Jolof Digital</title>
                <meta name="description" content="Nous vous accompagnons de la mise en place à l'envoi d'email afin de toucher directement votre cible"></meta>
                <link rel="canonical" href="https://jolof.digital/email-marketing" />
            </Helmet>
            
            <HeaderTitre 
                titre = {<Fragment>Email <br/> marketing</Fragment>}
                texte = "Nous vous accompagnons de la mise en place à l'envoi d'email afin de toucher directement votre cible">
                <img src={bgEmailMarketing} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

            <Intro />

        </Fragment>
    )
}

export default EmailMarketing
