import React, { Fragment, useContext, useEffect } from 'react'
//import styled from 'styled-components'
import HeaderTitre from '../composants/HeaderTitre'
import bgNone from '../images/bg-none.png'
import SessionContext from '../composants/utils/SessionContext'


const Realisations = () => {

    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('realisations');
    })
    
    return (
        <Fragment>
            
            <HeaderTitre 
                titre = {<Fragment>Nos <br/>réalisations</Fragment>}
                texte = 'Optimisez la notoriété de votre enseigne et boostez votre chiffre d’affaires'>
                <img src={bgNone} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

        </Fragment>
    )
}

export default Realisations
