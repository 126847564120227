import React from 'react'
import styled from 'styled-components'
import imgRedaction from '../../../images/redaction-contenu.svg'

const RedactionContenu = () => {
    return (
        <SectionCSS className="bg-white trait-gris-bottom">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pb-50 align-self-center">
                        <img src={imgRedaction} alt="" width="80%" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune pt-3">Rédaction de contenu</h2>
                        <p className="chapeaux">Vous n'avez pas encore rédigé le contenu de votre site web ? Notre rédacteur met à votre service sa maîtrise de la sémantique web pour assurer que vos textes soient parfaitement optimisés, que ce soit en Français ou en Anglais.</p>
                        <p>Notre rédacteur spécialisé produit un contenu web optimisé pour la cible ainsi que pour les moteurs de recherche dans le but d’améliorer la visibilité et le trafic sur votre site web tout en augmentant son référencement naturel et la notoriété de votre structure. Nous vous accompagnons dans la rédaction du contenu de votre site Web en choisissant les mots clés les plus pertinents.</p>
                    </div>
                </div>

            </div>
        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    padding:150px 0;
`


export default RedactionContenu
