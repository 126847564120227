import React, { Fragment } from 'react'
import styled from 'styled-components'
import methodologie from '../../../images/methodologie.svg'

const Intro = () => {
    return (
        <Fragment>
            <DivDegrade />
            <SectionCSS className="bg-bleu-blanc">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up">
                        <hr />
                        <h3>
                            Votre site est la plate-forme centrale  <br className="d-none d-xl-block" />de vos activités sur internet
                        </h3>
                        <p>
                            En effet, tous les canaux et outils marketing emmènent les visiteurs sur votre site et son but principal est de les « convertir »  en client (achat direct) ou prospect (demande de devis, inscription, etc.) ou de les inviter à réaliser une action spécifique (remplissage d’un formulaire, téléchargement d’un document, visionnage d’une vidéo, etc.).
                        </p>
                        <p>
                            La conversion représente ainsi l’objectif ultime de votre site web ; il s’agit du résultat positif des actions marketing menées auprès de votre cible et mesure, au passage, la rentabilité de ces actions.
                        </p>
                        <p>
                            Jolof Digital vous accompagne et vous conseille dans la réalisation ou la refonte de votre site pour véhiculer sur internet le meilleur de votre entreprise. Votre présence sur le web est aujourd’hui primordiale pour faire face à la concurrence ; elle reflète votre sérieux et peut répondre aux recherches de vos potentiels clients 24h/24.
                        </p>
                    </div>
                    <div className="col-lg-6" data-aos="zoom-in-up">
                        <div className="methodologie">

                            <div className="row">
                                <div className="col-xl-5 order-xl-2">
                                    <img src={methodologie} alt="" />
                                </div>
                                <div className="col-xl-7 order-xl-1">
                                    <h3 className="texte-bleu">Notre <br />méthodologie</h3>
                                    <ol>
                                        <li>Briefing avec le client</li>
                                        <li>Rédaction du contenu</li>
                                        <li>Design de l'interface</li>
                                        <li>Validation avec le client</li>
                                        <li>Production du site</li>
                                        <li>Mise en ligne sur internet</li>
                                        <li>Finalisation du référencement</li>
                                        <li>Livraison définitive</li>
                                        <li>Maintenance</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </SectionCSS>
        </Fragment>
    )
}

const SectionCSS = styled.section`
    padding-bottom: 100px;
    .methodologie {
        background: #fff;
        box-shadow: rgba(2, 45, 75, 0.1) 0 10px 20px;
        border-radius: 10px;
        padding: 30px;
        margin: 50px 0 0 0;
    }
    .methodologie ol {
        list-style: none;
        counter-reset: li;
        padding-left: 0;
    }
    .methodologie li::before {
        content: counter(li) ". ";
        color: var(--bleujolof);
        font-size: 18px;
        margin-left: -19px;
    }
    .methodologie li {
        counter-increment: li;
        margin-left: 19px;
    }
    .methodologie img {
        width: 100%;
        margin-bottom: 50px;
        max-width: 150px;
    }
    @media (min-width: 576px) {
        .methodologie {
            padding: 50px;
        }
    }
    @media (min-width: 768px) {
        .methodologie img {
            width: 100%;
            margin-top: 50px;
            max-width: 200px;
        }
    }
    @media (min-width: 992px) {
        .methodologie {
            margin: 50px 0 0 50px;
        }
        .methodologie img {
            width: 100%;
            margin-top: 0;
            margin-bottom: 50px;
            max-width: 150px;
        }
    }
    @media (min-width: 1200px) {
        .methodologie img {
            margin-top: 50px;
            margin-bottom: 0;
            max-width: 200px;
        }
    }
`
const DivDegrade = styled.div`
    background: linear-gradient(to top, var(--fondpage), rgba(216,237,247,0)); 
    width: 100%; 
    height: 150px;
`

export default Intro
