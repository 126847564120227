import React from 'react'
import styled from 'styled-components'
import strategiePositionnement from '../../../images/strategie-positionnement.svg'


const Strategie = () => {
    return (
        <SectionCSS className="bg-white trait-gris-bottom pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pb-50 align-self-center text-center img-left-mobile">
                        <img src={strategiePositionnement} alt="" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune pt-2">Stratégie & positionnement</h2>
                        <p className="chapeaux">Votre positionnement concurrentiel doit être un vrai choix stratégique et non le résultat de circonstances hasardeuses ou historiques</p>
                        <p>Nous déterminons avec vous les principaux objectifs à remplir et axes de communication à exploiter afin de toucher votre cible affinitaire. Nous élaborons un concept novateur et différenciant à partir de ces critères.</p>
                    </div>
                </div>

            </div>
        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    padding-top: 300px;
    img{width: 60%;}
`

export default Strategie
