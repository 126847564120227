import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import methodologie from '../../../images/methodologie.svg'

const Intro = () => {

    const [recherchesGoogle, setRecherchesGoogle] = useState(0);

    useEffect(() => {
        const intervalle = setInterval(() => {
            setRecherchesGoogle(recherchesGoogle => recherchesGoogle + 83756);
        }, 1000);
        return () => clearInterval(intervalle);
    }, []);
    

    return (
        <Fragment>
            <DivDegrade />
            <SectionCSS className="bg-bleu-blanc">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up">
                        <hr />
                        <h3>
                            Il semble évident que les moteurs de recherches souhaitent présenter du contenu de qualité à leurs utilisateurs
                        </h3>
                        <div style={{marginBottom: '1rem'}}>
                            Depuis que vous êtes sur cette page, les internautes du monde entier ont effectué plus de <span id="compteurGoogle" style={{display: 'inline-block'}}>{recherchesGoogle.toLocaleString()}</span> recherches sur Google <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="infos" />
                                <ReactTooltip id="infos" className='tooltipMenu' place="bottom" type="dark" effect="solid">
                                A raison de 83 756 recherches par seconde
                                </ReactTooltip> et les sites les plus compétitifs arrivent naturellement en tête. Un site pauvre en contenu ou dont le contenu n'est pas compréhensible et accessible par les algorithmes ne pourra pas être compétitif.
                        </div>
                        <p>
                            Le référencement Naturel (SEO) est en général le processus qui consiste à faire en sorte que le code et le contenu de votre site web soient bien acceptés par les moteurs de recherche. En termes simples, cela signifie qu’il faut ajuster certains éléments pour qu'ils puissent parcourir et comprendre le contenu et la structure facilement. Il inclut le SEO technique qui concerne la programmation elle-même et l’application de recommandations spécifiques (installation d’un certificat SSL, réduction du temps de chargement des pages, suppression des liens cassés ou du contenu dupliqué, etc.) et le référencement sur le contenu (travail sur le contenu en lui même). L’utilisation de titres et de descriptions engageants, de mots-clés appropriés et de liens bien placés sont des exemples de bonnes pratiques.
                        </p>
                    </div>
                    <div className="col-lg-6" data-aos="zoom-in-up">
                        <div className="methodologie">

                            <div className="row">
                                <div className="col-xl-5 order-xl-2">
                                    <img src={methodologie} alt="" />
                                </div>
                                <div className="col-xl-7 order-xl-1">
                                    <h3 className="texte-bleu">Notre <br />méthodologie</h3>
                                    <ol>
                                        <li>Briefing avec le client</li>
                                        <li>Audit de l'existant</li>
                                        <li>Analyse du positionnement</li>
                                        <li>Référencement naturel</li>
                                        <li>Campagne Google Ads</li>
                                        <li>Campagne Bing Ads</li>
                                        <li>Suivie de l'évolution</li>
                                        <li>Ajustement au besoin</li>
                                        <li>Analyse et reporting</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </SectionCSS>
        </Fragment>
    )
}

const SectionCSS = styled.section`
    padding-bottom: 100px;
    .methodologie {
        background: #fff;
        box-shadow: rgba(2, 45, 75, 0.1) 0 10px 20px;
        border-radius: 10px;
        padding: 30px;
        margin: 50px 0 0 0;
    }
    .methodologie ol {
        list-style: none;
        counter-reset: li;
        padding-left: 0;
    }
    .methodologie li::before {
        content: counter(li) ". ";
        color: var(--bleujolof);
        font-size: 18px;
        margin-left: -19px;
    }
    .methodologie li {
        counter-increment: li;
        margin-left: 19px;
    }
    .methodologie img {
        width: 100%;
        margin-bottom: 50px;
        max-width: 150px;
    }
    #infos{
        font-size: 14px;
        font-weight: 400;
        padding: 2px 10px;
        background: var(--bleujolof) !important;
        color: white !important;
    }
    #infos:after{
        border-bottom-color: #17b8de !important;
    }
    @media (max-width: 992px){
        #infos:after{
            border-left-color: #17b8de !important;
            border-right-color: #17b8de !important;
        }
    }
    
    @media (min-width: 576px) {
        .methodologie {
            padding: 50px;
        }
    }
    @media (min-width: 768px) {
        .methodologie img {
            width: 100%;
            margin-top: 50px;
            max-width: 200px;
        }
    }
    @media (min-width: 992px) {
        .methodologie {
            margin: 50px 0 0 50px;
        }
        .methodologie img {
            width: 100%;
            margin-top: 0;
            margin-bottom: 50px;
            max-width: 150px;
        }
    }
    @media (min-width: 1200px) {
        .methodologie img {
            margin-top: 50px;
            margin-bottom: 0;
            max-width: 200px;
        }
    }
`
const DivDegrade = styled.div`
    background: linear-gradient(to top, var(--fondpage), rgba(216,237,247,0)); 
    width: 100%; 
    height: 150px;
`

export default Intro
