import React, { Fragment } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

const FondBleuTransparent = (props) => {

    return (
        <Fragment>
            <DivCSS id='fondOverlay' onClick={props.fermer} data-tip data-for="fermerFond" />

            <TooltipCSS>
                <ReactTooltip id="fermerFond" className='tooltipFond' type="dark" effect="float">
                    {props.titre}
                </ReactTooltip>
            </TooltipCSS>
        </Fragment>
    )
}

const DivCSS = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(23, 184, 222, 0.5);
    border-top: var(--bleujolof) 2px solid;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 950;
    cursor: pointer;
    animation-name: opacite;
    animation-duration: 500ms;

    @keyframes opacite {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    // Fondu sortie
    &.fonduSortie {
        animation-name: opaciteSortie !important;
        animation-duration: 500ms !important;
        opacity:0;
    }
    @keyframes opaciteSortie {
        0% {opacity: 1;}
        75% {opacity: 1;}
        100% {opacity: 0;}
    }
`
const TooltipCSS = styled.div`
    .tooltipFond{
        font-size: 14px;
        font-weight: 400;
        padding: 2px 10px;
        background: white !important;
        color: #17b8de !important;
        white-space: nowrap;
    }
    .tooltipFond:not(.place-right):not(.place-left):after{
        border-bottom-color: white !important;
        border-top-color: white !important;
    }
    .tooltipFond.place-right:after{
        border-right-color: white;
    }
    .tooltipFond.place-left:after{
        border-left-color: white;
    }
    @media (max-width: 992px){
        .tooltipFond{display: none}
    }
`

export default FondBleuTransparent