import React from 'react'
import styled from 'styled-components'
import imgSocialAnalytics from '../../../images/social-analytics.svg'

const AnalyseReporting = () => {
    return (
        <SectionCSS className="bg-blanc-bleu pt-150 pb-150 trait-gris-bottom">

            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-md-6 text-center pb-50 align-self-center img-left-mobile">
                        <img src={imgSocialAnalytics} alt="" data-aos="zoom-in-up" />
                    </div>

                    <div className="col-md-6 pt-4 pr-100">
                        <h2 className="texte-jaune">Analyse et reporting</h2>
                        <p className="chapeaux">Concentrez-vous sur les statistiques qui comptent</p>
                        <p>Notre stratégie n’est jamais arrêtée : nous vous en restituons les retombées chaque mois via des reportings clairs et détaillés qui analysent la portée, l’évolution du taux d’engagement et les sujets les plus porteurs. Nous affinons alors la prise de parole en fonction de nos observations.</p>

                    </div>

                </div>

            </div>

        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    img{width: 50%;}
`

export default AnalyseReporting
