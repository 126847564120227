import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import imgUxDesign from '../../../images/ux-ui-design.svg'
import Bouton from '../../ui/Bouton'

const UxDesign = () => {
    return (
        <SectionCSS className="bg-blanc-jaune">
            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-md-6 text-center pb-50 order-md-2 align-self-center img-left-mobile">
                        <img src={imgUxDesign} alt="" width="80%" data-aos="zoom-in-up" />
                    </div>

                    <div className="col-md-6 pr-100 order-md-1">
                        <h2 className="texte-jaune pt-5">UX & UI Design</h2>
                        <p className="chapeaux">Nous modélisons l'expérience utilisateur, <br className="d-none d-md-block" />vous testez des prototypes dynamiques</p>
                        <p>Nos équipes conçoivent l’ergonomie, réalisent des prototypes fonctionnels et testent le design de votre site web tout en s’adaptant aux environnements techniques, avec l’exigence et le souci du détail. Le résultat : des interfaces intuitives au design soigné où les animations amènent de la modernité et donnent vie à vos projets.</p>

                        <Link to='ux-design'>
                            <Bouton couleur='jaune'>En savoir plus</Bouton>
                        </Link>
            
                    </div>

                </div>

            </div>
        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    padding: 150px 0;
`

export default UxDesign
