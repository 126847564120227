import React from 'react'
import imgSEO from '../../../images/seo.svg'

const OptimisationSEO = () => {
    return (
        <section className="bg-blanc-bleu trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pt-5 mt-4 pb-50 align-self-center text-center img-left-mobile">
                        <img src={imgSEO} alt="" width="70%" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune pt-5">Optimisation de votre référencement naturel (SEO)</h2>
                        <p className="chapeaux">Positionnez votre site dans le top 3 des résultats des moteurs de recherche sur les mots clés pertinents</p>
                        <p>En prenant en compte les points forts et les points faibles de votre référencement, nous pouvons déployer la stratégie qui a pour objectif de positionner votre site dans le tiercé gagnant. En pratique, elle consiste à :</p>

                        <ul className="listes">
                            <li>Apporter des correctifs techniques sur le site pour respecter les recommandations des différents moteurs de recherche et éviter des pénalités de leur part</li>
                            <li>Améliorer le contenu et le présenter de manière structuré pour qu'il soit plus compréhensible par vos lecteurs et les moteurs de recherche tout en travaillant sur les mots clés ciblés</li>
                            <li>Inscrire votre site sur les annuaires locaux en lien avec votre activité</li>
                            <li>Suivre les pages d'autres sites qui pointent vers le votre (backlink) pour supprimer ceux qui sont toxiques (qui pénalisent votre référencement)</li>
                            <li>Suivre l'évolution du positionnement sur les moteurs de recherche et analyser le trafic vers votre site</li>
                        </ul>

                    </div>
                </div>

            </div>
        </section>
    )
}

export default OptimisationSEO
