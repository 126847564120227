import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import ombre from '../../images/ombre.png'

const Demarche = () => {
    return (
        <SectionCSS className="bg-white" id='demarche'>
            <div className="container">

                <div className="text-center pb-50">
                    <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <FontAwesomeIcon icon={faSortDown} />
                    </div>
                </div>

                <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                    <div className="col-md-12 text-center">
                        <h2 className="texte-bleu">Notre démarche</h2>
                        <p className="sous-titres">Les diverses disciplines du marketing digital visent essentiellement à développer votre relation-client. Le but de la manœuvre étant d’optimiser votre notoriété et booster votre chiffre d’affaires. <br />De fil en aiguille, il s’agit de :</p>
                    </div>
                </div>

                <div className="row margin-top-75">

                    <div className="col-xl-3 col-md-6 mb-4" data-aos="fade-up">
                        <div className="bg-rouge arrondi text-white text-center objectifs-marketing">
                            <div className="pt-5 pb-3">
                                <div className="etapes texte-orange">I</div>
                            </div>
                            <div className="p-2 text-uppercase">
                                <span className="fw-bold chapeaux">Préparer</span><br />votre cible
                            </div>
                            <img src={ombre} width="100%" alt="" />
                            <div className="pt-4 ps-5 pe-5 pb-4">
                                Nous créons et animons une communauté sur les réseaux sociaux et en profitons pour déceler les besoins de votre audience pour donner des informations pertinentes
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4" data-aos="fade-up">
                        <div className="bg-orange arrondi text-white text-center objectifs-marketing">
                            <div className="pt-5 pb-3">
                                <div className="etapes texte-orange">II</div>
                            </div>
                            <div className="p-2 text-uppercase">
                                <span className="fw-bold chapeaux">Augmenter</span><br />le trafic vers votre site
                            </div>
                            <img src={ombre} width="100%" alt="" />
                            <div className="pt-4 ps-5 pe-5 pb-4">
                                Générer des leads sur votre site grâce aux stratégies déployées sur les réseaux sociaux, les moteurs de recherche, les sites partenaires et les annuaires
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4" data-aos="fade-up">
                        <div className="bg-jaune arrondi text-white text-center objectifs-marketing">
                            <div className="pt-5 pb-3">
                                <div className="etapes texte-jaune">III</div>
                            </div>
                            <div className="p-2 text-uppercase">
                                <span className="fw-bold chapeaux">Convertir</span><br />les visiteurs en clients
                            </div>
                            <img src={ombre} width="100%" alt="" />
                            <div className="pt-4 ps-5 pe-5 pb-4">
                                L’enjeu n’est pas d’attirer des visiteurs mais de retenir leur attention et les convaincre de devenir prospects, clients ou de réaliser une action spécifique
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4" data-aos="flip-left">
                        <div className="bg-bleu arrondi text-white text-center objectifs-marketing">
                            <div className="pt-5 pb-3">
                                <div className="etapes texte-bleu">IV</div>
                            </div>
                            <div className="p-2 text-uppercase">
                                <span className="fw-bold chapeaux">Fidéliser</span><br />votre clientèle
                            </div>
                            <img src={ombre} width="100%" alt="" />
                            <div className="pt-4 ps-5 pe-5 pb-4">
                                C'est crucial pour la croissance et la pérennité de votre entreprise. En effet, il est plus coûteux de trouver de nouveaux clients que de conserver ceux que vous avez déjà acquis
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </SectionCSS>
    )
}

export default Demarche


const SectionCSS = styled.section`
    padding: 100px 0 0 0;
    svg{
        font-size: 80px;
    }
    h2{
        font-weight: 700;
        text-transform: uppercase;
    }
    @media (min-width: 992px) {
        .objectifs-marketing {
            min-height: 380px;
        }
    }
    @media (min-width: 1200px) {
        .objectifs-marketing {
            min-height: 470px;
        }
    }
`