import React, { useContext, useState, Fragment } from 'react'
import styled from 'styled-components'
import imgDevis from '../../../images/icone-tarif-bleu.svg'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider'
import imgValide from '../../../images/valide.svg'
import BoutonLarge from '../../ui/BoutonLarge'
import Bouton from '../../ui/Bouton'
import SessionContext from '../../utils/SessionContext'
import Loader from '../../ui/Loader'

const Devis = () => {

    const session = useContext(SessionContext);

    const [mois, setMois] = useState(0);

    const auditEreputation = {
        'article' : 'Audit e-réputation',
        'contenu' : [
            mois < 4 && ('Audit ponctuel de ' + mois + ' mois'),
            mois > 3 && ('Audit permanent de ' + mois + ' mois')
        ]
    }

    return (
        <SectionCSS className="bg-white" id="devisBox">

            <div className="container">

                { session.recupService('Audit e-réputation') ? (

                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <div className="col-12 text-center">
                            <img src={imgValide} alt="" width="100" className="mb-3" data-aos="fade-in-up" />
                            <h2 className="texte-bleu">Félicitations</h2>
                            <p className="sous-titres mb-2">Vous avez ajouté Audit e-réputation à votre projet !</p>
                            <p className="sous-titres mb-3">Vous pouvez nous envoyer votre projet maintenant ou poursuivre votre visite pour y ajouter d'autres services qui pourraient vous intéresser.</p>

                            <Bouton couleur='bleu' action={session.toggleProjet}>Voir ou envoyer mon projet</Bouton>
                        
                            <p className="sous-titres mt-4 supprimer_projet" onClick={() => session.supprimerDevis('Audit e-réputation')}>Supprimer du projet</p>
                        </div>
                    </div>


                ) : (

                    <Fragment>

                        <div className="row pb-50" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                            <div className="col-12 text-center">
                                <img src={imgDevis} alt="" width="50" className="mb-3" data-aos="zoom-in-up" />
                                <h2 className="texte-bleu">Demandez <br className="d-sm-none" />un devis</h2>
                                <p className="sous-titres">Choisir la durée de l'audit e-réputation</p>
                            </div>
                        </div>

                        <div className="row" data-aos="fade-up">
                    

                            <div className="col-md-8 offset-md-2 col-lg-4 offset-lg-4">

                                <RangeSlider
                                    min={0}
                                    max={12}
                                    value={mois}
                                    onChange={changeEvent => setMois(changeEvent.target.value)}
                                    size='lg'
                                    tooltip='off'
                                    variant='info'
                                />

                                {mois < 1 && (
                                    <div className="text-center mt-5 pt-3 pb-4">
                                        <Loader />
                                        <p className="chapeaux" style={{maxWidth: '250px', margin: '0 auto', fontSize: '24px'}}>
                                            Déplacez le curseur pour démarrer
                                        </p>
                                    </div>
                                )}

                                {mois > 0 && (
                                    <Fragment>
                                        <div className="box-options mt-5" style={{border: 'var(--bleujolof) thin solid', boxShadow: 'none'}}>
                                            <div className="row">
                                                <div className="col-12 text-center pt-3 pb-3">
                                                    <h2 style={{fontWeight: 700}}>{mois} mois</h2>
    
                                                    {mois < 4 ? (
                                                        <div>
                                                            <p className="denomination"><b>AUDIT PONCTUEL</b></p>
                                                            <br/>
                                                            <p className="px-5" style={{lineHeight: '1em'}}>Idéal pour mesurer l'impact des stratégies marketing que vous avez déployé sur internet</p>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <p className="denomination"><b>AUDIT PERMANENT</b></p>
                                                            <br/>
                                                            <p className="px-5" style={{lineHeight: '1em'}}>Idéal pour faire de la veille et pouvoir réajuster en temps réel vos stratégies marketing</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <BoutonLarge 
                                            couleur='bleu' 
                                            action={() => session.ajouterDevis(auditEreputation) }>
                                            Ajouter à mon projet
                                        </BoutonLarge>
                                    </Fragment>
                                )}

                            </div>

                        </div>

                    </Fragment>

                )}

            </div>

        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    padding: 150px 0;
    //img{width: 50%;}
`

export default Devis
