import React from 'react'
import styled from 'styled-components'
import discussionLeft from '../../../images/discussion-left.svg'
import animation from '../../../images/animation-moderation.svg'
import discussionRight from '../../../images/discussion-right.svg'


const AnimationModeration = () => {
    return (
        <SectionCSS className="bg-bleu-blanc trait-gris-bottom pt-150 pb-150">

            <div className="container">
                <div className="row pt-100" data-aos="fade-up">
                    <div className="col-3 text-end align-self-center" data-aos="zoom-in-up">
                        <img src={discussionLeft} alt="" className="maxwidth100" />
                    </div>
                    <div className="col-6 text-center">
                        <img src={animation} alt="" className="maxwidth100" data-aos="fade-up" />
                    </div>
                    <div className="col-3 text-start align-self-center" data-aos="zoom-in-up">
                        <img src={discussionRight} alt="" className="maxwidth100" />
                    </div>
                </div>
                <div className="row" >
                    <div className="col-md-8 offset-md-2 text-center">
                        <h2 className="texte-jaune pt-5">Animation et modération</h2>
                        <p className="chapeaux">Les réseaux sociaux sont d'abord des espaces d’échanges</p>
                        <p>Nos équipes font une animation de proximité grâce à laquelle la communauté se sent écoutée et importante. Nous répondons aux interrogations et valorisons les contributions positives de chacun.</p>
                    </div>
                </div>
            </div>
            
        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    .col-3 img{width: 50px;}
    .col-6 img{width: 200px;}
`

export default AnimationModeration
