import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

const Realisations = (props) => {
    return (

        <div className="realisation" data-tip data-for="realisation">

            {props.lien !== null ? (
                <>
                    <a 
                        href={'https://' + props.lien} 
                        target='_blank' 
                        rel='noopener noreferrer'
                        aria-label={'Voir le site ' + props.nom}>
                        {props.children}
                    </a>
                    <TooltipCSS>
                        <ReactTooltip 
                            id="realisation" 
                            className='tooltipFond' 
                            type="dark" 
                            effect="float">
                            Voir le site
                        </ReactTooltip>
                    </TooltipCSS>
                </>
                ) : (
                    props.children
                ) 
            }
        </div>

    )
}


// Styles CSS
const TooltipCSS = styled.div`
    .tooltipFond{
        font-size: 14px;
        font-weight: 400;
        padding: 2px 10px;
        background: #f57531 !important;
        color: white !important;
        white-space: nowrap;
    }
    .tooltipFond:not(.place-right):not(.place-left):after{
        border-bottom-color: #f57531 !important;
        border-top-color: #f57531 !important;
    }
    .tooltipFond.place-right:after{
        border-right-color: #f57531;
    }
    .tooltipFond.place-left:after{
        border-left-color: #f57531;
    }
    @media (max-width: 992px){
        .tooltipFond{display: none}
    }
`

export default Realisations
