import React from 'react'
import styled from 'styled-components'
import imgAnnonce from '../../../images/annonce.svg'
import imgGoogleAds from '../../../images/g00gle-ads-logo.svg'
import imgBingAds from '../../../images/bing-ads-logo.svg'
import imgFondBlancGauche from '../../../images/fond-arrondi-blanc-gauche.webp'
import imgFondBlancDroite from '../../../images/fond-arrondi-blanc-droite.webp'


const SEA = () => {
    return (
        <DivCSS className='pb-50'>
            <section className="bg-bleu-clair pt-150 pb-100">

                <div className="container">
                    <div className="row" data-aos="fade-up">
                        <div className="col-3 text-end align-self-center" data-aos="zoom-in-up">
                        </div>
                        <div className="col-6 text-center" data-aos="zoom-in-up">
                            <img src={imgAnnonce} alt="" width="100" className="maxwidth100" />
                        </div>
                        <div className="col-3 text-start align-self-center" data-aos="zoom-in-up">
                        </div>
                    </div>
                    <div className="row" data-aos="fade-up">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="texte-jaune pt-5">Référencement payant (SEA)</h2>
                            <p className="chapeaux">Développez un trafic qualifié sur votre site rapidement et au bon moment</p>
                            <p>Le principe est d'acheter aux enchères des mots clés relatifs à vos produits, votre activité, votre évènement, etc. Si votre enchère est suffisamment haute, votre annonce apparaîtra en tête lorsqu’un internaute (faisant partie des cibles) recherche spécifiquement les mots clés en question. C'est l'idéal pour vos campagnes promotionnelles.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-0 pb-0" id="google-bing-ads" data-aos="fade-up">

                <div className="container resetContainerMobile">

                    <div className="row">

                        <div className="col-lg-6 pt-100 pb-100 pr-100 box-google-ads">
                            <img src={imgGoogleAds} alt="" height="75" data-aos="zoom-in-up" style={{maxWidth: '100%'}} />
                            <p className="chapeaux pt-5 pr-100">Associez-vous à Google pour toucher la bonne audience sans dépasser votre budget</p>
                            <p>Google Ads est l’une des plateformes publicitaires les plus avancées qui permettent aux petites et grandes entreprises de faire annoncer leurs produits et services devant des clients potentiels, en quelques heures seulement !</p>

                            <p>Avec 80 milliards de visites par mois, de réseau de recherche est le point de mire lorsqu’il s’agit de cibler les clients potentiels les plus chauds pour vos produits et services. Ainsi, sans aucun doute, les campagnes du réseau Search devraient être votre premier port d’escale.</p>

                            <p>Contrairement au marketing sur le réseau Display (sites et applications partenaires) qui interrompt vos prospects lorsqu’ils naviguent sur le web, les prospects sur le réseau Search ont en fait entrepris une action en cherchant une solution à leur problème ; ce qui les qualifie déjà comme des clients potentiels. Le lancement sur le réseau de recherche vous donne également une bonne idée des mots-clés contextuels qui peuvent être transférés sur le réseau Display.</p>
                        </div>

                        <div className="col-lg-6 pt-100 box-bing-ads">
                            <img src={imgBingAds} alt="" height="75" data-aos="zoom-in-up" style={{maxWidth: '100%'}} />
                            <p className="chapeaux pt-5">Même si Google est le leader incontournable pour l'achat d'espace web, il ne faut pas sous-estimer Bing</p>
                            <p>Développé par Microsoft, Bing présente l’avantage de disposer d’une audience quasi exclusive. Etant le moteur de recherche configuré par défaut sur Internet Explorer, Microsoft Edge et Cortana (installés par défaut sur Windows 10), de nombreuses personnes l’utilisent soit parce qu’elles ignorent comment changer de navigateur ou de moteur de recherche, soit parce qu’elles y sont extrêmement fidèles.</p>

                            <p>Une autre spécificité de Bing concerne la qualité de son audience : un peu plus âgée que les utilisateurs de Google, majoritairement de catégories socio-professionnelles les plus favorisées et disposant d’un fort pouvoir d’achat. Généralement moins informés des nouvelles technologies, ces utilisateurs ont moins recours aux bloqueurs de publicité et sont moins réfractaires aux liens sponsorisés.</p>

                            <p>Ca vaut le coût d'essayer, Bing Ads peut vous apporter un complément de trafic et de leads très intéressant, tant d’un point de vue économique que qualitatif !</p>

                        </div>
                    </div>
                </div>

            </section>
        </DivCSS>
    )
}

const DivCSS = styled.div`
    #google-bing-ads {
        background: var(--fondpage);
    }
    #google-bing-ads .container {
        padding: 0 10px;
    }
    #google-bing-ads .box-google-ads {
        background: url(${imgFondBlancGauche}) right no-repeat;
        background-size: cover;
        padding-right: 50px;
        padding-left: 45px;
        margin: 0 0 50px 0;
        max-width: calc(100% - 20px);
    }
    #google-bing-ads .box-bing-ads {
        background: url(${imgFondBlancDroite}) left no-repeat;
        background-size: cover;
        padding-left: 50px;
        padding-right: 45px;
        margin: 0 0 0 20px;
    }

    @media (min-width: 992px) {
        #google-bing-ads {
            background: url(${imgFondBlancGauche}) left no-repeat,
            url(${imgFondBlancDroite}) right no-repeat;
            background-size: 40% 100%;
        }
        #google-bing-ads .container {
            padding: 0 40px;
        }
        #google-bing-ads .box-google-ads {
            padding-left: 15px;
            margin: 0;
        }
        #google-bing-ads .box-bing-ads {
            padding-left: 100px;
            padding-right: 15px;
            margin: 0;
        }
    }

    @media (max-width: 991px) {
        .box-google-ads {
            padding: 95px !important;
        }
        .box-bing-ads {
            padding: 95px 95px 95px 75px !important;
        }
    }
    @media (max-width: 575px) {
        .box-google-ads {
            padding: 55px !important;
        }
        .box-bing-ads {
            padding: 55px !important;
        }
    }
`

export default SEA
