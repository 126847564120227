import React, { Fragment, useContext, useEffect } from 'react'
//import styled from 'styled-components'
import HeaderTitre from '../composants/HeaderTitre'
import bgSystemesIformation from '../images/bg-systemes-information.webp'
import SessionContext from '../composants/utils/SessionContext'


const SystemesInformation = () => {

    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('systemesInformation');
    })
    
    return (
        <Fragment>

            <HeaderTitre 
                titre = {<Fragment>Systèmes <br/>d'information</Fragment>}
                texte = 'Optimisez la notoriété de votre enseigne et boostez votre chiffre d’affaires'>
                <img src={bgSystemesIformation} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

        </Fragment>
    )
}

export default SystemesInformation