import React from 'react'
import imgAnalytics from '../../../images/analytics.svg'

const MobileAnalytics = () => {
    return (
        <section className="bg-blanc-bleu trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-md-6 text-center pb-50 order-md-2 align-self-center img-left-mobile">
                        <img src={imgAnalytics} alt="" width="60%" data-aos="zoom-in-up" />
                    </div>

                    <div className="col-md-6 order-md-1 pr-100">
                        <h2 className="texte-jaune">Mobile analytics</h2>
                        <p className="chapeaux">Comprendre les comportements des utilisateurs et leurs habitudes de navigation</p>
                        <p>Nous analysons vos données, connectons nos outils d’audit comportementaux, avec comme objectif de mettre en lumière les points positifs et les axes d’amélioration. Analyser pour objectiver, oublier les croyances pour se concentrer sur l’utilisateur ; notre méthode innovante permet de comprendre parfaitement son audience pour en faire des leviers de business et d’innovation.</p>

                    </div>

                </div>

            </div>
        </section>
    )
}

export default MobileAnalytics
