import React, { useContext, useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import iconeOnePage from '../../../images/icone-site-one-page.svg'
import iconeMultiPages from '../../../images/icone-site-multipages.svg'
import iconeRedactionContenu from '../../../images/icone-redaction-contenu.svg'
import iconeMultilingue from '../../../images/icone-multilingue.svg'
import iconeEcommerce from '../../../images/icone-ecommerce.svg'
import imgHebergement from '../../../images/icone-hebergement.svg'
import imgMaintenance from '../../../images/icone-maintenance.svg'
import imgCode from '../../../images/icone-code.svg'
import imgDevis from '../../../images/icone-tarif-bleu.svg'
import imgValide from '../../../images/valide.svg'
import SlideUpDown from '../../ui/SlideUpDown'
import BoutonLarge from '../../ui/BoutonLarge'
import Bouton from '../../ui/Bouton'
import SessionContext from '../../utils/SessionContext'
import Loader from '../../ui/Loader'

const SimulateurTarif = () => {

    const session = useContext(SessionContext);

    const [onePage, setOnePage ] = useState(false);
    const [multipages, setMultipages ] = useState(false);
    const [redaction, setRedaction ] = useState(false);
    const [multilingue, setMultilingue ] = useState(false);
    const [ecommerce, setEcommerce ] = useState(false);
    const [hebergement, setHebergement ] = useState(false);
    const [maintenance, setMaintenance ] = useState(false);
    const [developpement, setDeveloppement ] = useState(false);
    const [developpementProjet, setDeveloppementProjet ] = useState('');
    const [mois, setMois] = useState(1);
    const [offre, setOffre ] = useState(false);
    const [alerteHebergement, setAlerteHebergement ] = useState(false);
    
    const [onePagePrix, setOnePagePrix ] = useState(0);
    const [multipagesPrix, setMultipagesPrix ] = useState(0);
    const [redactionPrix, setRedactionPrix ] = useState(0);
    const [multilinguePrix, setMultilinguePrix ] = useState(0);
    const [ecommercePrix, setEcommercePrix ] = useState(0);
    const [hebergementPrix, setHebergementPrix ] = useState(0);
    const [maintenancePrix, setMaintenancePrix ] = useState(0);
    const [remise, setRemise ] = useState(0);
    const [totalApayer, setTotalApayer ] = useState(0);

    const onePageHandler = () => {
        setOnePage(!onePage);
        setMultipages(false);
        // Si Multipages n'était pas actif avant le rendu/clic
        if(!multipages){
            resetOptions();
        }
    }
    const multipagesHandler = () => {
        setMultipages(!multipages);
        setOnePage(false);
        // Si One Page n'était pas actif avant le rendu/clic
        if(!onePage){
            resetOptions();
        }
    }
    const redactionHandler = () => {
        setRedaction(!redaction);
    }
    const multilingueHandler = () => {
        setMultilingue(!multilingue);
    }
    const ecommerceHandler = () => {
        setEcommerce(!ecommerce);
    }
    const hebergementHandler = () => {
        setHebergement(!hebergement);
    }
    const maintenanceHandler = () => {
        setMaintenance(!maintenance);
    }
    const developpementHandler = () => {
        setDeveloppement(!developpement);
    }
    const developpementProjetHandler = (event) => {
        setDeveloppementProjet(event.target.value);
    }
    const moisHandler = (event) => {
        setMois(Number(event.target.value));
    }

    // Recalculer les prix des services
    useEffect(() => {
        let total_avant, remise, total_apres = 0;

        onePage ? setOnePagePrix( 240000 / mois ) : setOnePagePrix(0);
        multipages ? setMultipagesPrix (360000 / mois ) : setMultipagesPrix(0);
        redaction ? setRedactionPrix( 30000 / mois ) : setRedactionPrix(0);
        multilingue ? setMultilinguePrix( 30000 / mois ) : setMultilinguePrix(0);
        ecommerce ? setEcommercePrix( 180000 / mois ) : setEcommercePrix(0);
        hebergement ? setHebergementPrix( 60000 / mois ) : setHebergementPrix(0);
        maintenance ? setMaintenancePrix( 60000 / mois ) : setMaintenancePrix(0);
        
        total_avant = onePagePrix + multipagesPrix + redactionPrix + multilinguePrix 
                    + ecommercePrix + hebergementPrix + maintenancePrix;

        if(mois === 1){
            remise = -0.2 * total_avant;
        } else if(mois === 3){
            remise = -0.1 * total_avant;
        } else if(mois === 6){
            remise = -0.05 * total_avant;
        } else {
            remise = 0;
        }

        total_apres = total_avant + remise;

        setRemise(remise);
        setTotalApayer(total_apres);
    }, [onePage, mois, multipages, redaction, multilingue, ecommerce, hebergement, maintenance, onePagePrix, multipagesPrix, redactionPrix, multilinguePrix, ecommercePrix, hebergementPrix, maintenancePrix])
    
    useEffect(() => {
        // Afficher l'offre si l'utilisateur choisit un site
        setOffre(onePage || multipages );

        // Cocher le site MULTIPAGES si l'ulisateur choisit des options sans choisir de site
        if(!onePage && !multipages && (redaction || multilingue || ecommerce || hebergement || maintenance)){
            setMultipages(true)
        }

    }, [onePage, multipages, redaction, multilingue, ecommerce, hebergement, maintenance, mois])

    useEffect(() => {
        // Cocher l'option hébergement si l'utilisateur choisit une modalité sur plusiaurs mois sans choisir l'option hébergement
        if(!hebergement && mois > 1){
            setHebergement(true);
            setAlerteHebergement(true);
        }
        // Masquer l'alerte s'il chosit le paiement au comptant
        if(mois === 1){
            setAlerteHebergement(false);
        }
        // Masquer l'alerte 10s après son affichage
        if(alerteHebergement) {
            setTimeout(
                () => setAlerteHebergement(false), 10000
            )
        }
    }, [hebergement, mois, alerteHebergement])

    const resetOptions = () => {
        setRedaction(false); 
        setMultilingue(false); 
        setEcommerce(false); 
        setHebergement(false); 
        setMaintenance(false);
        setMois(1);
    }

    const siteInternet = {
        'article' : 'Site internet',
        'contenu' : [
            !developpement && onePage && ('Site One Page : ' + onePagePrix.toLocaleString() + ' FCFA'),
            !developpement && multipages && ('Site Multipages : ' + multipagesPrix.toLocaleString() + ' FCFA'),
            !developpement && redaction && ('Option Rédaction : ' + redactionPrix.toLocaleString() + ' FCFA'),
            !developpement && multilingue && ('Option Multilingue : ' + multilinguePrix.toLocaleString() + ' FCFA'),
            !developpement && ecommerce && ('Option Ecommerce : ' + ecommercePrix.toLocaleString() + ' FCFA'),
            !developpement && hebergement && ('Option Hébergement : ' + hebergementPrix.toLocaleString() + ' FCFA'),
            !developpement && maintenance && ('Option Maintenance : ' + maintenancePrix.toLocaleString() + ' FCFA'),
            !developpement && ('Total à payer : ' + totalApayer.toLocaleString() + ' FCFA'),
            !developpement && mois === 1 && ('Modalités : Payable au comptant'),
            !developpement && mois !== 1 && ('Modalités : Payable en ' + mois + ' mois'),
            developpement && ('Site avec développement particulier'),
            developpement && ('Détail du projet : ' + developpementProjet)
        ]
    }

    return (
        <DivCSS id="devisBox">

            <div className='container'>

                { session.recupService('Site internet') ? (

                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <div className="col-12 text-center">
                            <img src={imgValide} alt="" width="100" className="mb-3" data-aos="fade-in-up" />
                            <h2 className="texte-bleu">Félicitations</h2>
                            <p className="sous-titres mb-2">Vous avez ajouté Site internet à votre projet !</p>
                            <p className="sous-titres mb-3">Vous pouvez nous envoyer votre projet maintenant ou poursuivre votre visite pour y ajouter d'autres services qui pourraient vous intéresser.</p>

                            <Bouton couleur='bleu' action={session.toggleProjet}>Voir ou envoyer mon projet</Bouton>
                        
                            <p className="sous-titres mt-4 supprimer_projet" onClick={() => session.supprimerDevis('Site internet')}>Supprimer du projet</p>
                        </div>
                    </div>


                ) : (

                    <Fragment>

                        <div className="row pb-100" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                            <div className="col-12 text-center">
                                <img src={imgDevis} alt="" width="50" className="mb-3" data-aos="zoom-in-up" />
                                <h2 className="texte-bleu">Simulateur <br className="d-sm-none "/>de tarif</h2>
                                <p className="sous-titres">Constituez vous-même votre devis en fonction de vos besoins</p>
                            </div>
                        </div>

                        <div className="row" data-aos="fade-up">

                            <div className="col-md-6 col-xl-6 offset-xl-1">

                                <SlideUpDown open={!offre && !developpement}>
                                    <div id="attente-mobile" className="box-options text-center">
                                        <Loader />
                                        <p className="chapeaux">Choisissez une option pour démarrer</p>
                                        <FontAwesomeIcon icon={faSortDown} className='texte-bleu-clair' />
                                    </div>
                                </SlideUpDown>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <img src={iconeOnePage} alt="" width="40" />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Site internet ONE PAGE</b><br/>
                                            <span className="description">Tout votre site sur une seule page divisée en parties accessibles en scrollant ou en cliquant sur le menu</span>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input
                                                    onChange={onePageHandler}
                                                    checked={onePage}
                                                    className="switch-input"
                                                    type="checkbox"
                                                    value="240000" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <img src={iconeMultiPages} alt="" width="40" />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Site internet MULTIPAGES</b><br/>
                                            <span className="description">Votre site sur plusieurs pages distinctes accessibles via le menu</span><br/>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={multipagesHandler}
                                                    checked={multipages}
                                                    className="switch-input" 
                                                    type="checkbox" 
                                                    value="360000" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <img src={iconeRedactionContenu} alt="Rédaction de contenu" width="40" />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Option Rédaction de contenu</b><br/>
                                            <span className="description">Nous rédigeons les textes pour vous</span><br/>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={redactionHandler}
                                                    checked={redaction}
                                                    className="switch-input" 
                                                    type="checkbox" 
                                                    value="30000" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <img src={iconeMultilingue} alt="" width="40" />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Option Multilingue</b><br/>
                                            <span className="description">Rendre le site disponible en 2 langues au moins</span><br/>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={multilingueHandler}
                                                    checked={multilingue}
                                                    className="switch-input" 
                                                    type="checkbox" 
                                                    value="30000" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <img src={iconeEcommerce} alt="" width="40" />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Option Ecommerce <span style={{fontSize: '75%'}}>(ou petites annonces)</span></b><br/>
                                            <span className="description">Pour vendre vos produits en ligne</span><br/>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={ecommerceHandler}
                                                    checked={ecommerce}
                                                    className="switch-input" 
                                                    type="checkbox" 
                                                    value="180000" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <img src={imgHebergement} alt="" width="40" />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Option Hébergement web</b><br/>
                                            <span className="description">Pour publier votre site sur internet</span><br/>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={hebergementHandler}
                                                    checked={hebergement}
                                                    className="switch-input" 
                                                    type="checkbox" 
                                                    value="60000" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <SlideUpDown open={alerteHebergement}>
                                        <div className="row texte-bleu alertedevis">
                                            Les sites à crédit sont obligatoirement hébergés par Jolof Digital. Veuillez choisir « Payable au comptant » si vous ne souhaitez pas souscrire à l'offre « Hébergement »
                                        </div>
                                    </SlideUpDown>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <img src={imgMaintenance} alt="" width="40" />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Option Maintenance pendant 12 mois</b><br/>
                                            <span className="description">Modifications, mises à jour, suivi et support technique</span><br/>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={maintenanceHandler}
                                                    checked={maintenance}
                                                    className="switch-input" 
                                                    type="checkbox" 
                                                    value="60000" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-lg-2 col-xl-1 align-self-center text-center">
                                            <img src={imgCode} alt="" width="40" />
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <b className="denomination">Développement particulier</b><br/>
                                            <span className="description">Ex : site avec espace client, simulateur de tarif, marketplace, etc</span><br/>
                                        </div>
                                        <div className="col-lg-3 col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={developpementHandler}
                                                    checked={developpement}
                                                    className="switch-input" 
                                                    type="checkbox" 
                                                    value="Développement particulier" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-6 col-xl-4">

                                <SlideUpDown open={!offre && !developpement}>
                                    <div id="attente" className="box-options text-center">
                                        <Loader />
                                        <p className="chapeaux">Choisissez une option pour démarrer</p>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={developpement}>
                                    <div id="developpement" className="box-options text-center">
                                        <img src={imgCode} alt="" className="pb-5" />
                                        <p className="chapeaux">Site avec développement particulier</p>
                                        <p className="description">étudié au cas par cas par <br/>nos techniciens et commerciaux</p>
                                    </div>
                                    <div id="developpementDetail" className="box-options text-center">
                                        <textarea 
                                            value={developpementProjet}
                                            onChange={developpementProjetHandler}
                                            cols="30" 
                                            rows="10" 
                                            placeholder="Parlez-nous de votre projet"
                                        ></textarea>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre && !developpement}>
                                
                                    <div id="offre" className="box-options pt-0 pb-0">
                                        <div className="row border-0" id="optionschoisies">
                                            <div className="col-8 text-start texte-bleu text-uppercase align-self-center">Options choisies</div>
                                            <div className="col-4 text-end description align-self-center">Prix en FCFA</div>
                                        </div>

                                        <SlideUpDown open={onePage}>
                                            <div className="row">
                                                <div className="col-8 text-start">Site One Page</div>
                                                <div className="col-4 text-end texte-bleu fw-bold text-nowrap align-self-center">
                                                    {onePagePrix.toLocaleString()}
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={multipages}>
                                            <div className="row">
                                                <div className="col-8 text-start">Site Multipages</div>
                                                <div className="col-4 text-end texte-bleu fw-bold text-nowrap align-self-center">
                                                    {multipagesPrix.toLocaleString()}
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={redaction}>
                                            <div className="row">
                                                <div className="col-8 text-start">Option Rédaction de contenu</div>
                                                <div className="col-4 text-end texte-bleu fw-bold text-nowrap align-self-center">
                                                    {redactionPrix.toLocaleString()}
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={multilingue}>
                                            <div className="row">
                                                <div className="col-8 text-start">Option Multilingue</div>
                                                <div className="col-4 text-end texte-bleu fw-bold text-nowrap align-self-cente">
                                                    {multilinguePrix.toLocaleString()}
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={ecommerce}>
                                            <div className="row">
                                                <div className="col-8 text-start">Option Ecommerce</div>
                                                <div className="col-4 text-end texte-bleu fw-bold text-nowrap align-self-center">
                                                    {ecommercePrix.toLocaleString()}
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={hebergement}>
                                            <div className="row">
                                                <div className="col-8 text-start">Option Hébergement web</div>
                                                <div className="col-4 text-end texte-bleu fw-bold text-nowrap align-self-center">
                                                    {hebergementPrix.toLocaleString()}
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={maintenance}>
                                            <div className="row">
                                                <div className="col-8 text-start">Option Maintenance</div>
                                                <div className="col-4 text-end texte-bleu fw-bold text-nowrap align-self-center">
                                                    {maintenancePrix.toLocaleString()}
                                                </div>
                                            </div>
                                        </SlideUpDown>
                                    </div>


                                </SlideUpDown>

                                <SlideUpDown open={remise !== 0 && !developpement}>
                                    <div id="offre" className="box-options pt-0 pb-0">
                                        <div className="row">
                                            <div className="col-8 text-start">
                                                Remise {mois === 1 ? '' : 'par mois'}
                                            </div>
                                            <div className="col-4 text-end texte-bleu fw-bold text-nowrap align-self-center">
                                                {remise.toLocaleString()}
                                            </div>
                                        </div>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre && !developpement}>
                                    <div id="total" className="box-options bg-bleu-fonce">
                                        <div className="row">
                                            <div className="col-8 text-start text-white">
                                                Total à payer {mois === 1 ? 'au comptant' : 'par mois'}
                                            </div>
                                            <div className="col-4 text-end texte-bleu fw-bold text-nowrap align-self-center">
                                                {Number(totalApayer).toLocaleString()}
                                            </div>
                                        </div>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre && !developpement}>
                                    <div className="box-options pb-4" id="modalites">
                                        <div className="text-start text-uppercase py-3">Modalités de paiement</div>

                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className={mois === 1 ? 'boite actif' : 'boite'}>
                                                    <input
                                                        onChange={moisHandler}
                                                        checked={mois === 1} 
                                                        type="radio" 
                                                        id="unmois" 
                                                        name="modalite" 
                                                        value="1"
                                                    />
                                                    <label htmlFor="unmois">
                                                        <b>Payable <br/>au comptant</b><br/>
                                                        Remise de 20%
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className={mois === 3 ? 'boite actif' : 'boite'}>
                                                    <input 
                                                        onChange={moisHandler}
                                                        checked={mois === 3} 
                                                        type="radio" 
                                                        id="troismois" 
                                                        name="modalite" 
                                                        value="3"
                                                    />
                                                    <label htmlFor="troismois">
                                                        <b>Payable en 3 mensualités</b><br/>
                                                        Remise de 10%
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className={mois === 6 ? 'boite actif' : 'boite'}>
                                                    <input 
                                                        onChange={moisHandler}
                                                        checked={mois === 6} 
                                                        type="radio" 
                                                        id="sixmois" 
                                                        name="modalite" 
                                                        value="6"
                                                    />
                                                    <label htmlFor="sixmois">
                                                        <b>Payable en 6 mensualités</b><br/>
                                                        Remise de 5%
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className={mois === 12 ? 'boite actif' : 'boite'}>
                                                    <input 
                                                        onChange={moisHandler}
                                                        checked={mois === 12} 
                                                        type="radio" 
                                                        id="douzemois" 
                                                        name="modalite" 
                                                        value="12"
                                                    />
                                                    <label htmlFor="douzemois">
                                                        <b>Payable en 12 mensualités</b><br/>
                                                        Tarif normal
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </SlideUpDown>

                                <SlideUpDown open={offre || developpement}>
                                    <BoutonLarge 
                                        couleur='bleu' 
                                        action={() => session.ajouterDevis(siteInternet) }>
                                        Ajouter à mon projet
                                    </BoutonLarge>
                                </SlideUpDown>

                                <SlideUpDown open={offre && !developpement}>
                                    <BoutonLarge 
                                        couleur='jaune' 
                                        action={() => alert('Devis au format PDF')}>
                                        Voir mon devis
                                    </BoutonLarge>
                                </SlideUpDown>

                            </div>

                        </div>

                    </Fragment>

                )}

            </div>

        </DivCSS>
    )
}

const DivCSS = styled.div`
    padding: 100px 0 150px 0 !important;
`

export default SimulateurTarif
