import React from 'react'
import imgAnalitics from '../../../images/analytics.svg'

const WebAnalytics = () => {
    return (
        <div className="container pb-100">

            <div className="row pt-200" data-aos="fade-up">

                <div className="col-md-6 text-center pb-50 order-md-2 align-self-center img-left-mobile">
                    <img src={imgAnalitics} alt="" width="60%" data-aos="zoom-in-up" />
                </div>

                <div className="col-md-6 order-md-1 pr-100">
                    <h2 className="texte-jaune">Web analytics</h2>
                    <p className="chapeaux">Comprendre les comportements des utilisateurs et leurs habitudes de navigation</p>
                    <p>Nous analysons vos données, connectons nos outils d’audit comportementaux, avec comme objectif de mettre en lumière les points positifs et les axes d’amélioration. Analyser pour objectiver, oublier les croyances pour se concentrer sur l’utilisateur ; notre méthode innovante permet de comprendre parfaitement son audience pour en faire des leviers de business et d’innovation.</p>

                </div>

            </div>

        </div>
    )
}

export default WebAnalytics
