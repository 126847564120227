import React from 'react'
import imgAnalysePositionnement from '../../../images/analyse-positionnement.svg'

const AnalyseReferencement = () => {
    return (
        <section className="bg-white trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pb-50 align-self-center pt-5">
                        <img src={imgAnalysePositionnement} alt="" width="70%" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune">Analyse de votre référencement</h2>
                        <p className="chapeaux">Il est important de vérifier votre e-réputation régulièrement sur les moteurs de recherche</p>
                        <p>En faisant des recherches sur Google, Bing et autres les moteurs, on peut avoir des surprises : retrouver des avis de blogueurs, des conversations dans les forums, des images détournées voir de l’usurpation d’identité. Et si votre site n'est pas classé en top position, ces contenus peuvent monopoliser votre audience et décider de votre réputation.</p>
                        <p>Il est donc important de suivre le nom de votre marque, de vos produits, de vos dirigeants ou autres porte-paroles de l’entreprise. Nous passons au peigne fin l’ensemble des différents types de résultats : résultats classiques, images, vidéos, actualités ... afin d’avoir un tour d’horizon le plus large possible.</p>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default AnalyseReferencement
