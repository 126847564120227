import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Bouton from '../ui/Bouton'
import imgSiteInternet from '../../images/services-site-internet.svg'
import imgCommunityManagement from '../../images/services-community-management.svg'
import imgApplicationMobile from '../../images/services-application-mobile.svg'
import imgReferencement from '../../images/services-referencement.svg'
import imgEmailMarketing from '../../images/services-email-marketing.svg'
import imgAuditReputation from '../../images/services-audit-e-reputation.svg'

const AutresServicesMarketing = (props) => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        pauseOnHover: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
            breakpoint: 1400,
                settings: {
                    slidesToShow: 3
                }
            },
            {
            breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
            breakpoint: 700,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <SectionCSS className="bg-jaune-blanc-bleu pt-100 pb-100">

            <div className="container defilement">

                <div className="row pb-5" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                    <div className="col-12 text-center">
                        <FontAwesomeIcon icon={faPlusCircle} className="texte-jaune" style={{fontSize: '2.5em', marginBottom: '20px'}} />
                        <h2 className="texte-bleu">Autres services</h2>
                        <p className="sous-titres texte-bleu-fonce">Explorez le champs des possibles</p>
                    </div>
                </div>

                <div className="row" data-aos="fade-up">

                    <div className="col-12">

                        <div id="autres-services">

                            <Slider {...settings}> 

                                {props.exclure !== 'site-internet' ? (

                                    
                                    <div className="item">
                                        <div className="service">
                                            <img src={imgSiteInternet} alt="" />
                                            <p className="chapeaux">Site<br/>internet</p>
                                            <p>Votre présence sur le web est aujourd’hui primordiale pour faire face à la concurrence et atteindre vos cibles</p>
                                            <Link to="site-internet">
                                                <Bouton couleur='bleu'>Découvrir</Bouton>
                                            </Link>
                                        </div>
                                    </div>

                                ) : null}

                                {props.exclure !== 'community-management' ? (

                                    <div className="item">
                                        <div className="service">
                                            <img src={imgCommunityManagement} alt="" />
                                            <p className="chapeaux">Community<br/>management</p>
                                            <p>Assurez votre présence sur les réseaux sociaux et répondez à vos objectifs de relation client et de notoriété</p>
                                            <Link to="community-management">
                                                <Bouton couleur='bleu'>Découvrir</Bouton>
                                            </Link>
                                        </div>
                                    </div>

                                ) : null}

                                {props.exclure !== 'application-mobile' ? (

                                    <div className="item">
                                        <div className="service">
                                            <img src={imgApplicationMobile} alt="" />
                                            <p className="chapeaux">Application<br/>mobile</p>
                                            <p>Rendez vos contenus plus accessibles sur les smartphones et tablettes avec une meilleure expérience utilisateur</p>
                                            <Link to="application-mobile">
                                                <Bouton couleur='bleu'>Découvrir</Bouton>
                                            </Link>
                                        </div>
                                    </div>

                                ) : null}

                                {props.exclure !== 'referencement' ? (


                                    <div className="item">
                                        <div className="service">
                                            <img src={imgReferencement} alt="" />
                                            <p className="chapeaux">Référencement<br/> naturel & payant</p>
                                            <p>Augmentez votre visibilité sur les principaux moteurs de recherche pour générer plus de trafic utile</p>
                                            <Link to="referencement">
                                                <Bouton couleur='bleu'>Découvrir</Bouton>
                                            </Link>
                                        </div>
                                    </div>

                                ) : null}

                                {props.exclure !== 'email-marketing' ? (

                                    <div className="item">
                                        <div className="service">
                                            <img src={imgEmailMarketing} alt="" />
                                            <p className="chapeaux">Email<br/>marketing</p>
                                            <p>Nous vous accompagnons de la mise en place à l'envoi d'email afin de toucher directement votre cible</p>
                                            <Link to="email-marketing">
                                                <Bouton couleur='bleu'>Découvrir</Bouton>
                                            </Link>
                                        </div>
                                    </div>

                                ) : null}

                                {props.exclure !== 'audit-e-reputation' ? (

                                    <div className="item">
                                        <div className="service">
                                            <img src={imgAuditReputation} alt="" />
                                            <p className="chapeaux">Audit<br/>e-réputation</p>
                                            <p>Découvrez comment votre marque est perçue sur le web afin de définir des points d’amélioration à moyen terme</p>
                                            <Link to="audit-e-reputation">
                                                <Bouton couleur='bleu'>Découvrir</Bouton>
                                            </Link>
                                        </div>
                                    </div>

                                ) : null}

                            </Slider>

                        </div>

                    </div>

                </div>
                
            </div>

        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    img{width: 50%;}

    #autres-services .item{
        padding: 30px;
    }
    #autres-services .service{
        background: #fff; 
        padding: 50px 35px; 
        box-shadow: rgba(0,0,0,0.1) 0 10px 20px; 
        border-radius: 10px; 
        border-bottom: var(--fondpage) 5px solid; 
        text-align: center;
    }
    #autres-services .service img{
        height: 100px; 
        max-width: 75px; 
        padding-bottom: 25px; 
        margin: 0 auto;
    }
    #autres-services .service .chapeaux{
        color: var(--bleujolof) !important;
    }
    @media (min-width: 1700px) {
        #autres-services .service p:not(.chapeaux){
            min-height: 120px;
        }
    }

`

export default AutresServicesMarketing
