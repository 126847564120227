import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Bouton from '../../ui/Bouton'
import imgFondArrondiBlanc from '../../../images/fond-arrondi-blanc.webp'
import imgFondArrondiBlancGauche from '../../../images/fond-arrondi-blanc-gauche.webp'
import imgFondRectangleBlanc from '../../../images/fond-rectangle-blanc.webp'

const HebergementSeo = () => {
    return (
        <DivCSS>
            <div className="box-hebergement-row" data-aos="fade-up">
                <div className="container">

                    <div className="row">

                        <div className="col-lg-6 pt-100 pb-100 pr-100 box-hebergement">
                            <h2 className="texte-jaune">Hébergement web</h2>
                            <p className="chapeaux">Un hébergement est indispensable pour publier votre site sur Internet</p>
                            <p>Nous vous proposons une solution globale comprenant l'hébergement, le nom de domaine (ex : monentreprise.com) et la gestion technique de votre serveur ; vous pourrez ainsi vous libérer de cette tâche contraignante et vous concentrer sur votre activité.
                            </p>
                            <p>Les noms de domaine génériques sont offerts la 1ère année  (.com, .net, .org, etc.) et les noms de domaines de niveau national sont facturés séparément (.sn, .fr, etc) car étant gérés au niveau des gouvernements respectifs.</p>

                            <table className="tarif-hebergement">
                                <tbody>
                                    <tr className="titre">
                                        <td>Espace<br/>disque</td>
                                        <td>Adresses emails<br/>personnalisées</td>
                                        <td>Nom de<br/>domaine</td>
                                        <td className="d-none d-xl-table-cell">Base de<br/>données SQL</td>
                                    </tr>
                                    <tr className="contenu">
                                        <td nowrap="nowrap">100 Go</td>
                                        <td nowrap="nowrap">10 adresses</td>
                                        <td>Inclus</td>
                                        <td className="d-none d-xl-table-cell">1 Go</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div className="col-lg-6 pt-100 pr-100 box-referencement">
                            <h2 className="texte-jaune">Référencement naturel</h2>
                            <p className="chapeaux">La démarche qualitative dans laquelle s’inscrit les moteurs de recherche laisse penser que le contenu est devenu l’un des atouts majeurs en matière de référencement</p>
                            <p>Il semble évident que les moteurs de recherches souhaitent présenter du contenu de qualité à leurs utilisateurs. De ce fait, un site pauvre en contenu ou dont le contenu n'est pas compréhensible ou accessible par les algorithmes ne pourra pas être compétitif.</p>
                            <p>Nous considérons que le positionnement sur Google, Bing et tous les moteurs de recherche est autant un facteur qu’une opportunité de développement. Il se pense et se travaille autant en amont qu’en aval d’un projet, et déborde de la simple sphère technique. Au sein de notre agence à l’approche scientifique, convaincus du rôle marketing majeur que le SEO occupe aujourd’hui, nous veillons à en maîtriser tous ses aspects, de l’optimisation du contenu à l’analyse des visites.</p>

                            <Link to="referencement">
                                <Bouton couleur='jaune'>En savoir plus</Bouton>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </DivCSS>
    )
}

const DivCSS = styled.div`
    .box-hebergement-row {
        left: -30px;
        position: relative;
    }
    .box-hebergement h2,
    .box-hebergement p,
    .box-hebergement table,
    .box-hebergement button,
    .box-referencement {
        margin-left: 30px;
    }
    .tarif-hebergement {
        /*border-radius: 10px; overflow: hidden;*/
        margin: 35px 0 20px 0;
    }
    .tarif-hebergement td {
        background: #fff /*var(--fondpage)*/;
        padding: 10px 15px;
        border: rgba(0, 0, 0, 0.1) 1px solid;
        text-align: center;
        font-size: 16px;
        line-height: 16px;
    }
    .tarif-hebergement .contenu {
        font-weight: 700;
    }
    @media (min-width: 1200px) {
        .box-hebergement {
            background: url(${imgFondArrondiBlanc}) right no-repeat;
            background-size: cover;
            //width: calc(100% + 40px);
            padding-right: 50px;
        }
    }
    @media (max-width: 1199px) {
        .box-hebergement {
            background: url(${imgFondArrondiBlancGauche}) right no-repeat;
            background-size: cover;
            //width: calc(100% + 40px);
            padding-right: 50px;
        }
        .box-referencement {
            padding-right: 50px;
        }
    }
    @media (min-width: 576px) {
        .box-hebergement {
            padding-right: 100px;
        }
    }
    @media (min-width: 992px) {
        .box-referencement {
            margin-left: 0;
            padding-left: 40px;
        }
    }
    @media (min-width: 992px) {
        .box-hebergement-row {
            background: url(${imgFondRectangleBlanc}) left no-repeat;
            background-size: 20% 100%;
            margin-left: 0;
        }
    }
    @media (min-width: 1600px) {
        .box-hebergement-row {
            background-size: 33% 100%;
        }
    }
    @media (min-width: 576px), (max-width: 911px) {
        .box-hebergement-row{
            margin: 0 -40px !important;
        }
        .box-hebergement-row .box-hebergement {
            padding-left: 55px;
        }
        .box-hebergement-row .box-referencement {
            padding-left: 55px;
        }
    }

`

export default HebergementSeo
