import React, { Fragment, useContext } from 'react'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
//import { useAnalytics } from './utils/googleAnalytics'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import TitreProjet from '../images/mon-projet.svg'
import FondBleuTransparent from './ui/FondBleuTransparent'
import Bouton from './ui/Bouton'
import SessionContext from './utils/SessionContext'

const MonProjet = () => {

    //useAnalytics('ouvrir-projet');

    const session = useContext(SessionContext);

    return (

        <Fragment>

            <ProjetCSS id='projetBody'>

                <Scrollbars autoHide autoHideTimeout={100} className='boxScrollbar'>

                    <div id="fermerVolet" title="Fermer le volet projet" onClick={session.fermerProjetAnimation} >
                        <FontAwesomeIcon icon={faChevronCircleLeft} />
                    </div>

                    <div className="row espacement">

                        <div className="col-12 text-start mt-2">
                            <div className="mt-3 d-md-none d-block"></div>
                            <img src={TitreProjet} alt="Mon projet" className="titreProjet" />
                            <hr className="mt-4" />
                        </div>

                        <div className="col-12">
                            <h3 className="pb-2">Services sélectionnés</h3>
                        </div>

                        {session.devis.length === 0 ? (

                            <div className="col-12 boxProjets">
                                <p>Votre projet est vide pour le moment. Commencez par parcourir le site et ajoutez les services qui pourraient apporter de la valeur ajoutée à votre projet.</p>
                                <p>Ensuite vous n'aurez plus qu'à revenir ici pour nous donner vos coordonnées afin que nos équipes (technique et commerciale) puissent vous contactez pour un accompagnement dans la réalisation de votre projet.</p>
                            </div>

                        ) : (

                            <div className="col-12 boxProjets">
                                <Accordion allowZeroExpanded>
                                {session.devis.map((titre) => (
                                    <AccordionItem key={titre.article}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <div className="titre">
                                                    {titre.article}
                                                    <span onClick={() => session.supprimerDevis(titre.article)} title="Supprimer du projet">
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </span>
                                                </div>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <ul className="listes">
                                                {titre.contenu.map((contenu) => (
                                                    <Fragment key={Math.random()}>
                                                        {(contenu !== 0 && contenu) && <li>{contenu}</li>}
                                                    </Fragment>   
                                                ))}
                                            </ul>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))}
                                </Accordion>
                            </div>

                        )}


                        <div className="col-12 pt-5">
                            <hr />
                            <h3 className="pb-0 mb-0">Mes coordonnées</h3>
                        </div>

                        <div className="col-12 pb-50">

                            <form id="envoiProjet">

                                <div className="form-group">
                                    <label htmlFor="projetNom" className="formulaire_label">Prénom et nom</label>
                                    <input type="text" className="formulaire" id="projetNom" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="projetTelephone" className="formulaire_label">Numéro de téléphone</label>
                                    <input type="text" className="formulaire" id="projetTelephone" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="projetEmail" className="formulaire_label">Adresse email</label>
                                    <input type="email" className="formulaire" id="projetEmail" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="projetEntreprise" className="formulaire_label">Entreprise</label>
                                    <input type="text" className="formulaire" id="projetEntreprise" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="projetCommentaires" className="formulaire_label">Commentaires</label>
                                    <textarea id="projetCommentaires" rows="5" className="formulaire"></textarea>
                                </div>

                                <Bouton couleur='bleu'>Envoyer mon projet</Bouton>

                            </form>

                        </div>

                    </div>
                
                </Scrollbars>
                
            </ProjetCSS>

            { session.projet && <FondBleuTransparent fermer={session.fermerProjetAnimation} titre='Fermer le volet projet' /> }

        </Fragment>
    )
}

// Styles CSS
const ProjetCSS = styled.div`
    width: 95%;
    max-width: 1000px;
    height: 100%;
    top: 0;
    left: -50px;
    position: fixed;
    background: var(--fondpage);
    box-shadow: rgba(0, 0, 0, 0.1) 63px 0 99px;
    //overflow-y: hidden;
    //overflow-y: auto;
    overflow-y: hidden;
    z-index: 1000;
    transition: all 300ms ease-in-out;
    animation-name: positionnementProjet;
    animation-duration: 750ms;
    @keyframes positionnementProjet {
        0% {left: -1100px; opacity: 0;}
        50% {left: -25px; opacity: 1;}
        100% {left: -50px; opacity: 1;}
    }

    #fermerVolet {
        position: fixed;
        background: var(--fondpage) !important;
        color: var(--bleujolof) !important;
        left: calc(95% - 50px);
        padding: 25px 15px 25px 5px;
        border-radius: 0 25px 25px 0;
        bottom: 50%;
        cursor: pointer;
        animation-name: opacite;
        animation-duration: 1000ms;
        z-index: 1000;
    }
    @keyframes opacite {
        0% {opacity: 0;}
        50% {opacity: 0;}
        100% {opacity: 1;}
    }

    .thumb-horizontal{}

    .titreProjet{
        height: 45px;
        top: -10px;
        position: relative;
        font-size: 40px;
        color: var(--orangejolof) !important;
        font-weight: 700;
    }

    .espacement {
        padding: 30px 30px 30px 70px;
        margin: 0;
    }
    & > div > div:first-child {
        margin-bottom: -20px !important;
    }

    .boxProjets{
        font-size: 18px;
        line-height: 22px;
    }
    .boxProjets .titre {
        font-size: 16px;
        width: 100%;
        text-decoration: none;
        //color: var(--bleujolof) !important;
        font-weight: 400;
        text-align: left;
        padding: 15px 20px;
        margin-bottom: 15px;
        background: white;
        box-shadow: 0 10px 30px rgba(2, 45, 74, 0.1) !important;
        border-radius: 10px;
    }
    .boxProjets .titre::selection {
        background: #fff !important;
        color: var(--gristexte) !important;
    }
    .boxProjets .titre span {
        font-size: 15px;
        color: var(--jaunejolof) !important;
        text-align: right;
        padding: 2px 20px;
        position: absolute;
        right: 40px;
    }
    .boxProjets .listes {
        padding-left: 5px;
        font-size: 15px;
        line-height: 19px;
    }
    #envoiProjet .form-group {
        margin: 0;
    }

    @media (min-width: 768px) { // MD
        width: 550px;
        .boxProjets .titre {
            font-size: 20px;
            width: 100%;
            text-decoration: none;
            //color: var(--bleujolof) !important;
            font-weight: 300;
            text-align: left;
            padding: 15px 20px;
            margin-bottom: 15px;
            background: white;
            box-shadow: 0 10px 30px rgba(2, 45, 74, 0.1) !important;
            border-radius: 10px;
        }
        .boxProjets .titre span {
            font-size: 20px;
            color: var(--jaunejolof) !important;
            text-align: right;
            padding: 2px 20px;
            position: absolute;
            right: 60px;
        }
        .boxProjets .listes {
            padding-left: 15px;
            font-size: 18px;
            line-height: 22px;
        }
        .espacement {
            padding: 50px 50px 50px 100px;
        }
        #fermerVolet {
            left: 500px;
            padding: 25px 15px;
        }    
    }

    // Animations sortie
    .fonduSortie {
        animation-name: opaciteSortie;
        animation-duration: 500ms;
        opacity:0;
    }
    @keyframes opaciteSortie {
        0% {opacity: 1;}
        75% {opacity: 1;}
        100% {opacity: 0;}
    }

    &.slideSortie {
        animation-name: positionSortieProjet;
        animation-duration: 500ms;
        left: -1100px;
    }
    @keyframes positionSortieProjet {
        from {left: -50px;}
        to {left: -1100px;}
    }
    .fonduSortieRapide {
        animation-name: opaciteSortie;
        animation-duration: 100ms;
        opacity: 0;
    }
`

export default MonProjet
