import React from 'react'
import imgPersonas from '../../../images/reputation-personas.svg'

const AnalysePersonas = () => {
    return (
        <section className="bg-white pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pb-50 align-self-center text-center img-left-mobile">
                        <img src={imgPersonas} alt="" width="70%" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune">Analyse des personas types</h2>
                        <p className="chapeaux">Pour connaître vos communautés, nous devons dresser des portraits types d'utilisateurs en général, ce qu’on appelle des personas</p>
                        <p>En gros, il s’agit de subdiviser en catégories les internautes qui parlent de vous. Serial liker, influenceurs, leader d’opinion, troll, fan ou encore guide bienveillant, etc. L’idée est de définir l’identité numérique des utilisateurs en fonction de leurs objectifs et de leurs pratiques. Connaître vos fans permet de mieux communiquer avec eux et mieux adapter votre argumentaire en fonction du personas.</p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default AnalysePersonas
