import React from 'react'
import styled from 'styled-components'
import bgTitre from '../images/bg-titre-header.webp'
import bgTitreXxl from '../images/bg-titre-header-arrondi.webp'


const HeaderTitre = (props) => {

    return (
        <SectionCSS className="p-0 header-section">

            <div className={props.referencement ? 'header-titre referencement' : 'header-titre'} data-aos="fade-right" data-aos-duration="1000">
                <div className="container">
                    <h1 className="texte-bleu pb-3">{props.titre}</h1>
                    <p className={'chapeaux ' + props.largeur}>{props.texte}</p>
                </div>
            </div>

            <div className="text-end">
                {props.children}
            </div>

        </SectionCSS>
    )
}


// Styles CSS
const SectionCSS = styled.section`

    // XS
        .header-titre {
            background: url( ${bgTitre} ) right;
            background-size: cover;
            margin: 50px 0;
            padding: 35px 15% 25px 0;
        }
        .header-titre h1 {
            font-size: 35px;
            line-height: 35px;
            font-weight: 400;
        }
        .header-titre p {
            font-size: 22px;
            line-height: 25px;
            max-width: 250px;
        }
        .header-image {
            width: 100%;
            margin-bottom: 50px;
            z-index: -1;
        }
    @media (min-width: 576px) { // SM
        .header-titre {
            margin: 50px 50px 50px 0;
            padding: 75px 15% 65px 0;
        }
        .header-titre h1 {
            font-size: 50px;
            line-height: 50px;
        }
        .header-titre p {
            font-size: 25px;
            line-height: 30px;
            max-width: 350px;
        }
        .header-image {
            width: 100%;
            max-width: 600px;
            margin-bottom: 50px;
        }
        .container{
            padding-right: 0 !important;
        }
    }
    @media (min-width: 768px) { // MD
        .header-titre {
            margin: 60px 150px 60px 0;
            padding: 75px 15% 65px 0;
        }
        .header-titre h1 {
            font-size: 50px;
            line-height: 50px;
        }
        .header-titre p {
            font-size: 25px;
            line-height: 30px;
            max-width: 400px;
        }
        .header-image {
            width: 100%;
            max-width: 700px;
            margin-bottom: 50px;
        }
    }
    @media (min-width: 992px) { // LG
        .header-titre {
            margin: 60px 300px 60px 0;
            padding: 75px 15% 65px 0;
        }
        .header-titre h1 {
            font-size: 60px;
            line-height: 60px;
        }
        .header-titre p {
            font-size: 30px;
            line-height: 33px;
            max-width: 500px;
        }
        .header-image {
            width: 100%;
            max-width: 700px;
            margin-bottom: 50px;
        }
    }
    @media (min-width: 1200px) { // XL 
        margin-top: 65px;
        .header-titre {
            margin: 35px 45% 60px 0;
            padding: 75px 15% 65px 0;
        }
        .header-titre.referencement {
            padding: 75px 15% 30px 0;
        }
        .header-titre h1 {
            font-size: 60px;
            line-height: 60px;
        }
        .header-titre p {
            font-size: 27px;
            line-height: 33px;
            max-width: 500px;
            min-height: 99px;
        }
        .header-image {
            width: unset;
            max-width: none;
            height: 398px /*432px*/;
            margin-bottom: 50px;
            top: 175px;
            right: 0;
            position: absolute;
        }
    }
    @media (min-width: 1600px) {
        margin-top: 91px;
        .header-titre {
            margin: 35px 42% 60px 0;
            padding: 150px 15% 140px calc((100% - 1600px) / 2);
        }
        .header-titre.referencement {
            padding: 150px 15% 95px calc((100% - 1600px) / 2);
        }
        .header-titre h1 {
            font-size: 80px;
            line-height: 80px;
        }
        .header-titre .chapeaux {
            max-width: 500px;
        }
        .header-titre .chapeaux.courtTexte  {
            max-width: 375px !important;
        }
        .header-image {
            width: unset;
            max-width: none;
            height: 588px /*622px*/;
            margin-bottom: 50px;
            top: 200px;
            left: 47% /*45%*/;
            position: absolute;
            border-radius: 50px;
        }
    }
    @media (min-width: 1925px) {
        .header-titre {
            background: url( ${bgTitreXxl} ) right no-repeat;
            background-size: contain;
            margin: 35px 48% 60px 0;
            padding: 100px 9% 100px calc((100% - 1600px) / 2);
        }
        .header-titre.referencement {
            padding: 100px 9% 55px calc((100% - 1600px) / 2);
        }
        .header-titre h1 {
            font-size: 80px;
            line-height: 80px;
        }
        .header-image {
            width: unset;
            max-width: none;
            height: 498px /*532px*/;
            margin-bottom: 50px;
            top: 200px;
            left: 48% /*47%*/;
            position: absolute;
        }
    }

`


export default HeaderTitre
