import React from 'react'
import styled from 'styled-components'
//import { Link } from 'react-router-dom'
//import Bouton from './Bouton'


const Services = (props) => {
    return (

        <DivCSS className="col-lg-4 col-md-6 mb-4" data-aos="fade-up">
            {/*<Link to={props.lien}>*/}
                <div className="bg-white arrondi text-center recettes-marketing">
                    {props.children}
                    <div className="pt-5 pb-3 texte-bleu text-uppercase chapeaux">
                        {props.titre}
                    </div>
                    <div className="ps-5 pe-5 pb-5">
                        {props.texte}<br />
                        {/*<Bouton couleur='bleu'>Découvrir</Bouton>*/}
                    </div>
                </div>
            {/*</Link>*/}
        </DivCSS>

    )
}


// Styles CSS
const DivCSS = styled.div`

    // XS
    .recettes-marketing {
        box-shadow: none;
        margin: 15px 0;
        transition: all 300ms ease-in-out;
    }
    .recettes-marketing:hover {
        box-shadow: #9dbdcd 0 50px 50px;
    }
    .chapeaux{
        color: var(--bleujolof) !important;
    }
    img{
        width: 100%;
        border-radius: 10px 10px 0 0;
    }
    @media (min-width: 576px) { // SM
        /*.recettes-marketing {
            margin: 15px;
        }*/
    }
    @media (min-width: 768px) { // MD
        .recettes-marketing {
            min-height: 500px;
            margin: 5px;
        }
    }
    @media (min-width: 992px) { // LG
        .recettes-marketing {
            min-height: 420px;
        }
    }
    @media (min-width: 1200px) { // XL 
        .recettes-marketing {
            min-height: 440px;
        }
    }
    @media (min-width: 1600px) {
        .recettes-marketing {
            min-height: 500px;
        }
    }
    @media (min-width: 1925px) {
        
    }

`


export default Services
