import React, { Fragment, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import HeaderTitre from '../../composants/HeaderTitre'
import bgReferencement from '../../images/bg-referencement.webp'
import Intro from '../../composants/marketingDigital/referencement/Intro'
import AuditExistant from '../../composants/marketingDigital/referencement/AuditExistant'
import AnalysePositionnement from '../../composants/marketingDigital/referencement/AnalysePositionnement'
import OptimisationSEO from '../../composants/marketingDigital/referencement/OptimisationSEO'
import SEA from '../../composants/marketingDigital/referencement/SEA'
import Devis from '../../composants/marketingDigital/referencement/Devis'
import AutresServicesMarketing from '../../composants/marketingDigital/AutresServicesMarketing'
import SessionContext from '../../composants/utils/SessionContext'


const Referencement = () => {

    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('marketingDigital');
    })

    return (
        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>SEO & SEA | Référencement naturel & payant | Jolof Digital</title>
                <meta name="description" content="Augmentez votre visibilité sur les principaux moteurs de recherche"></meta>
                <link rel="canonical" href="https://jolof.digital/referencement" />
            </Helmet>

            <HeaderTitre 
                titre = {<>SEO & SEA <br/><span>Référencement <br/>naturel & payant</span></>}
                texte = "Augmentez votre visibilité sur les principaux moteurs de recherche"
                referencement >
                <img src={bgReferencement} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>

            <Intro />

            <AuditExistant />

            <AnalysePositionnement />

            <OptimisationSEO />

            <SEA />

            <Devis />

            <AutresServicesMarketing exclure='referencement' />

        </Fragment>
    )
}

export default Referencement
