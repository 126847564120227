import React from 'react'
import imgReputationSites from '../../../images/reputation-sites.svg'

const AnalyseSitesWeb = () => {
    return (
        <section className="bg-blanc-bleu trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-md-6 text-center pb-5 order-md-2 align-self-center img-left-mobile">
                        <img src={imgReputationSites} alt="" width="90%" data-aos="zoom-in-up" />
                    </div>

                    <div className="col-md-6 pr-100 pt-3 order-md-1">
                        <h2 className="texte-jaune">Analyse de votre réputation <br className="d-none d-xl-block" />sur les autres sites web</h2>
                        <p className="chapeaux">Bien cartographier les principaux portails d'entreprise, les sites d'avis, les blogs dans votre thématique, les influenceurs, les sites d'actualité qui vous citent, etc. afin d’avoir une écoute sur ce qu'il se dit à votre sujet</p>
                        <p>Votre marque peut être citée de façon positive ou négative sur les blogs, les forums et autres plateformes de conversations en ligne. Il est donc important de gérer le monitoring afin d'avoir un feed-back en temps réel et pouvoir corriger les problèmes à temps.</p>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default AnalyseSitesWeb
