import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faHome, faQuestionCircle, faDraftingCompass, faEnvelope, faBars, faBriefcase, faCode 
} from '@fortawesome/free-solid-svg-icons'*/
import iconHome from '../images/icon_home.svg'
import iconQuestionCircle from '../images/icon_question-circle.svg'
import iconCode from '../images/icon_awesome-code.svg'
import iconDaftingCompass from '../images/icon_drafting-compass.svg'
import iconEnvelope from '../images/icon_envelope.svg'
import iconBars from '../images/icon_bars.svg'
import logo from '../images/logo-jolof-digital.svg'
import Menu from './Menu'
import MonProjet from './MonProjet'
import SessionContext from './utils/SessionContext'

const HeaderMenu = () => {

    const session = useContext(SessionContext);

    return (
        <CorrectionHeight>
        <Entete id='headerMenu'>

            { session.menu && <Menu /> }

            { session.projet && <MonProjet /> }
            
            <div className='container'>
                <div className="row">
                    <div className="col-3">
                        <Branding onClick={session.defilerHaut}>
                            {/*<Link to='/'>*/}
                                <img src={logo} alt='Jolof Digital' className='logo' /> 
                            {/*</Link>*/}
                        </Branding>
                    </div>
                    <div className="col-9">
                        <MenuLarge>
                            <ul>
                                <li className="d-none d-xl-inline-block">
                                    {session.menuActif === 'accueil' ? (
                                        <span 
                                            data-tip data-for="accueil" 
                                            onClick={session.defilerHaut}>
                                            <img src={iconHome} alt='Accueil' />
                                            {/*<FontAwesomeIcon icon={faHome} />*/}
                                            
                                            <ReactTooltip 
                                                id="accueil" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                Accueil
                                            </ReactTooltip>
                                        </span>
                                    ) : (
                                        <Link to="./" 
                                            data-tip data-for="accueil">
                                            <img src={iconHome} alt='Accueil' />
                                            {/*<FontAwesomeIcon icon={faHome} />*/}
                                            
                                            <ReactTooltip 
                                                id="accueil" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                Accueil
                                            </ReactTooltip>
                                        </Link>
                                    )}
                                </li>

                                <li className="d-none d-xl-inline-block">
                                    {session.menuActif === 'accueil' ? (
                                        <span 
                                            data-tip data-for="notre_presentation" 
                                            onClick={() => session.defiler('presentation')}>
                                            <img src={iconQuestionCircle} alt='A propos' />
                                            {/*<FontAwesomeIcon icon={faQuestionCircle} />*/}

                                            <ReactTooltip 
                                                id="notre_presentation" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                A propos
                                            </ReactTooltip>
                                        </span>
                                    ) : (
                                        <Link to="./#presentation"
                                            data-tip data-for="notre_presentation">
                                            <img src={iconQuestionCircle} alt='A propos' />
                                            {/*<FontAwesomeIcon icon={faQuestionCircle} />*/}

                                            <ReactTooltip 
                                                id="notre_presentation" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                A propos
                                            </ReactTooltip>
                                        </Link>
                                    )}
                                    
                                </li>

                                <li className="d-none d-xl-inline-block">
                                    {session.menuActif === 'accueil' ? (
                                        <span 
                                            data-tip data-for="nos_services" 
                                            onClick={() => session.defiler('services')}>
                                            <img src={iconCode} alt='Nos services' />
                                            {/*<FontAwesomeIcon icon={faCode} />*/}

                                            <ReactTooltip 
                                                id="nos_services" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                Nos services
                                            </ReactTooltip>
                                        </span>
                                    ) : (
                                        <Link to="./#services"
                                            data-tip data-for="nos_services">
                                            <img src={iconCode} alt='Nos services' />
                                            {/*<FontAwesomeIcon icon={faCode} />*/}

                                            <ReactTooltip 
                                                id="nos_services" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                Nos services
                                            </ReactTooltip>
                                        </Link>
                                    )}
                                </li>

                                <li className="d-none d-xl-inline-block">
                                    {session.menuActif === 'accueil' ? (
                                        <span 
                                            data-tip data-for="nos_realisations" 
                                            onClick={() => session.defiler('realisations')}>
                                            <img src={iconDaftingCompass} alt='Nos réalisations' />
                                            {/*<FontAwesomeIcon icon={faDraftingCompass} />*/}

                                            <ReactTooltip 
                                                id="nos_realisations" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                Nos réalisations
                                            </ReactTooltip>
                                        </span>
                                    ) : (
                                        <Link to="./#realisations"
                                            data-tip data-for="nos_realisations">
                                            <img src={iconDaftingCompass} alt='Nos réalisations' />
                                            {/*<FontAwesomeIcon icon={faDraftingCompass} />*/}

                                            <ReactTooltip 
                                                id="nos_realisations" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                Nos réalisations
                                            </ReactTooltip>
                                        </Link>
                                    )}
                                </li>

                                <li className="d-none d-xl-inline-block">
                                    {session.menuActif === 'accueil' ? (
                                        <span 
                                            data-tip data-for="nous_contacter" 
                                            onClick={() => session.defiler('contact')}>
                                            <img src={iconEnvelope} alt='Nous contacter' />
                                            {/*<FontAwesomeIcon icon={faEnvelope} />*/}

                                            <ReactTooltip 
                                                id="nous_contacter" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                Nous contacter
                                            </ReactTooltip>
                                        </span>
                                    ) : (
                                        <Link to="./#contact"
                                            data-tip data-for="nous_contacter">
                                            <img src={iconEnvelope} alt='Nous contacter' />
                                            {/*<FontAwesomeIcon icon={faEnvelope} />*/}

                                            <ReactTooltip 
                                                id="nous_contacter" 
                                                className='tooltipMenu' 
                                                place="bottom" 
                                                type="dark" 
                                                effect="solid">
                                                Nous contacter
                                            </ReactTooltip>
                                        </Link>
                                    )}
                                </li>


                                {/*

                                <li className="d-none d-xl-inline-block" id={session.menuActif === 'marketingDigital' ? 'actif' : null}>
                                    <Link to='/marketing-digital' data-tip data-for="marketing">
                                        <FontAwesomeIcon icon={faCrosshairs} />
                                    </Link>
                                    <ReactTooltip id="marketing" className='tooltipMenu' place="bottom" type="dark" effect="solid">
                                        Marketing digital
                                    </ReactTooltip>
                                </li>

                                <li className="d-none d-xl-inline-block" id={session.menuActif === 'systemesInformation' ? 'actif' : null}>
                                    <Link to='/systemes-information' data-tip data-for="systemes">
                                        <FontAwesomeIcon icon={faPoll} />
                                    </Link>
                                    <ReactTooltip id="systemes" className='tooltipMenu' place="bottom" type="dark" effect="solid">
                                        Systèmes d'information
                                    </ReactTooltip>
                                </li>

                                <li className="d-none d-xl-inline-block" id={session.menuActif === 'realisations' ? 'actif' : null}>
                                    <Link to='/realisations' data-tip data-for="realisation">
                                        <FontAwesomeIcon icon={faDraftingCompass} />
                                    </Link>
                                    <ReactTooltip id="realisation" className='tooltipMenu' place="bottom" type="dark" effect="solid">
                                        Nos réalisations
                                    </ReactTooltip>
                                </li>

                                <li className="d-none d-xl-inline-block" id={session.menuActif === 'contact' ? 'actif' : null}>
                                    <Link to='/contact' data-tip data-for="contact">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </Link>
                                    <ReactTooltip id="contact" className='tooltipMenu' place="bottom" type="dark" effect="solid">
                                        Nous contacter
                                    </ReactTooltip>
                                </li>

                                */}
                                    
                                <li className="ouvrirMenu d-xl-none" onClick={session.toggleMenu}>
                                    <span data-tip data-for="ouvrirMenu">
                                        <img src={iconBars} alt='Menu' />
                                        {/*<FontAwesomeIcon icon={faBars} />*/}
                                    </span>
                                    <ReactTooltip id="ouvrirMenu" className='tooltipMenu' place="bottom" type="dark" effect="solid">
                                        Ouvrir le menu
                                    </ReactTooltip>
                                </li>

                                {/*
                                <li className="d-none d-xl-inline-block ouvrirProjet" onClick={session.toggleProjet}>
                                    <span data-tip data-for="ouvrirProjet">
                                        <FontAwesomeIcon icon={faBriefcase} />
                                        <span className="nombre">{session.devis.length}</span>
                                    </span>
                                    <ReactTooltip id="ouvrirProjet" className='tooltipMenu' place="bottom" type="dark" effect="solid">
                                        Mon projet
                                    </ReactTooltip>
                                </li>
                                */}

                            </ul>
                        </MenuLarge>
                    </div>
                </div>
                
            </div>
        </Entete>
        </CorrectionHeight>
    )
}

// Styles CSS
const Entete = styled.header`
    //border-top: var(--bleujolof) 1px solid;
    background: linear-gradient(to bottom, var(--fondpage), rgba(216, 237, 247, 0));
    padding: 25px 0;
    z-index: 900;
    @media (max-width: 575px){
        padding-top: 20px;
    }
    &.fixe{
        background: linear-gradient(to bottom, #fff, rgba(255,255,255,0.95));
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0 5px 25px;
        padding-top: 0;
        padding-bottom: 0;
        .logo{width: 160px; padding-top: 15px;}
        //nav svg{font-size: 30px;}
        nav img{height: 30px; margin: 0 20px 15px 20px;}
        .ouvrirMenu span{padding: 8px 12px; top: -8px; width: 40px; height: 40px; display: inline-block;}
        .ouvrirProjet span{padding: 0 12px; top: -6px;}
        .ouvrirProjet span.nombre{left: 15px; top: -10px;  padding: 2px 0 0 0; font-size: 14px; text-align: center;}
        //.ouvrirMenu svg{color: white !important; font-size: 20px;}
        .ouvrirMenu img{height: 20px; margin: 5px 20px 15px 2px}
        .ouvrirProjet svg{color: var(--bleujolof) !important; font-size: 30px;}
        nav{margin-top: 13px;}
        animation-name: positionnementHeader;
        animation-duration: 300ms;
        @keyframes positionnementHeader {
            from {top: -100px; opacity: 0;}
            to {top: 0; opacity: 1;}
        }
        @media (max-width: 1199px) {
            nav{margin-top: 15px;}
        }
        @media (max-width: 575px) {
            padding-bottom: 14px;
            .ouvrirMenu span {
                padding: 8px 12px 10px 10px;
                top: -3px;
                width: 45px;
                height: 45px;
            }
        }
        @media (min-width: 992px) and (max-width: 1700px) {
            .ouvrirMenu span {padding: 10px 12px; top: -8px;}
        }
    }
    &.slideSortieHeader {
        animation-name: sortieHeader;
        animation-duration: 300ms;
    }
    @keyframes sortieHeader {
        from {top: 0; opacity: 1;}
        to {top: -100px; opacity: 0;}
    }
`
const CorrectionHeight = styled.div`
    height: 86px;
    @media (min-width: 576px) {
        height: 110px;
    }
`
const Branding = styled.div`
    @media (max-width: 575px) {
        .logo{
            width: 230px !important;
            padding-top: 15px;
        }
    }
    @media (min-width: 576px) {
        .logo {
            width: 330px;
            padding-top: 20px;
        }
    }
`
const MenuLarge = styled.nav`
    text-align: right;
    margin-top:30px;

    ul{
        margin-bottom : 0;
    }
    li {
        display: inline-block;
    }
    img {
        height: 40px;
        //width: 40px;
        margin: 0 20px 25px 20px;
    }
    img:hover {
        cursor: pointer;
    }
    /*svg {
        //color: var(--bleujolof) !important;
        color: var(--gristexte) !important;
        font-size: 40px;
        margin: 0 20px 5px 20px;
        //transition: ease-in-out 500ms;
    }
    svg:hover {
        color: var(--bleufonce) !important;
        cursor: pointer;
    }*/
    #actif svg {
       color: var(--gristexte) !important;
    }
    /*.ouvrirMenu svg{
        color: var(--fondpage) !important;
        color: white !important;
        margin: 0;
        font-size: 30px;
    }*/
    .ouvrirMenu img{
        margin: 2px 0 0 0;
        height: 25px;
    }
    .ouvrirMenu span{
        //border: var(--jaunejolof) 2px solid !important;
        //background: linear-gradient(to bottom, var(--jaunejolof), var(--orangejolof));
        background: var(--orangejolof);
        padding: 18px 19px 17px 19px;
        margin-left: 20px;
        border-radius: 100px;
        position: relative;
        top: -10px;
        width: 65px;
        height: 65px; 
        display: inline-block;
        cursor: pointer;
        //border: none !important;
    }

    .ouvrirProjet svg{
        color: var(--bleujolof) !important;
        margin: 0;
        font-size: 40px;
    }
    .ouvrirProjet span{
        //border: var(--bleujolof) 2px solid !important;
        //background: var(--fondpage);
        padding: 0 17px 0 17px;
        margin: 0 0 0 20px;
        border-radius: 100px;
        position: relative;
        top: -7px;
        cursor: pointer;
    }
    .ouvrirProjet span.nombre{
        position: absolute;
        top: -20px;
        left: 30px;
        background: var(--gristexte) !important;
        font-size: 15px;
        line-height: 15px;
        width: 22px;
        height: 20px;
        text-align: center;
        font-weight: 700;
        //color: var(--fondpage) !important;
        color: white !important;
        padding: 2px 0 0 0;
    }
    @media (max-width: 576px) {
        .ouvrirMenu span{
            padding: 14px 15px 13px 15px;
            right: -20px;
            top: -22px;
            position: relative;
            width: 55px;
            height: 55px; 
        }
        .ouvrirMenu img {
            margin: 0;
            height : 20px;
        }
        /*.ouvrirMenu svg {
            font-size: 30px !important;
        }*/
        /*@media (min-width: 577px) {
            .ouvrirMenu img {
                height : 300px !important;
            }
        }*/
    }
    .tooltipMenu{
        font-size: 14px;
        font-weight: 400;
        padding: 2px 10px;
        background: var(--bleujolof);
        color: white !important;
    }
    .tooltipMenu:after{
        border-bottom-color: #17b8de !important;
    }
    @media (max-width: 992px){
        .tooltipMenu{display: none !important;}
    }
`

export default HeaderMenu