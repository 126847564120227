import React, { Fragment } from 'react'
import styled from 'styled-components'
import introMarketing from '../../images/intro-marketing-digital.svg'

const Intro = () => {
    return (
        <Fragment>
            <DivDegrade />
            <SectionCSS className="bg-bleu-blanc">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-up">
                            <hr />
                            <h3>
                                Ces dernières décennies, la technologie a profondément amélioré la relation client dans tous les secteurs d'activité
                            </h3>
                            <p>
                                Les consommateurs utilisent de plus en plus internet dans leur processus d’achat, de formation ou même de recherche d'informations, incitant les entreprises à s'adapter à cette évolution comportementale en intégrant le digital dans leurs stratégies marketing. 
                            </p>
                            <p>
                                Même si leur cœur d'activité ne repose pas sur internet, la majorité d'entre elles n'ont pas d'autres issues que celle de prendre en compte ce média ne serait-ce que pour maîtriser leur réputation en ligne et engager une véritable conversation avec leurs clients. 
                            </p>
                            <p>
                                Aujourd’hui plus que jamais, les entreprises ont besoin de soigner leur e-réputation et d’établir une interaction permanente avec leurs clients si elles veulent avoir une place dans cette nouvelle ère numérique. Bientôt, la quasi-totalité des consommateurs n’utiliseront que des canaux digitaux pour faire leurs achats. Dans ce contexte, sans recourir au marketing digital, une entreprise abandonne une bonne partie de ses clients potentiels.
                            </p>
                        </div>
                        <div className="col-lg-6 text-center" data-aos="zoom-in-up">
                            <img src={introMarketing} alt="" />
                        </div>
                    </div>
                </div>
            </SectionCSS>
        </Fragment>
    )
}

const SectionCSS = styled.section`
    padding: 0 0 50px 0;
    @media (max-width: 991px) {
        padding: 0;
    }
    img{
        width : 100%;
        max-width: 490px; 
        padding: 60px 25px 25px 25px;
        margin: 0 auto;
    }
`
const DivDegrade = styled.div`
    background: linear-gradient(to top, var(--fondpage), rgba(216,237,247,0)); 
    width: 100%; 
    height: 150px;
`

export default Intro