import React, { Fragment } from 'react'
import styled from 'styled-components'
import methodologie from '../../../images/methodologie.svg'

const Intro = () => {
    return (
        <Fragment>
            <DivDegrade />
            <SectionCSS className="bg-bleu-blanc">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up">
                        <hr />
                        <h3>
                            Il est temps de prendre en main votre réputation en ligne !
                        </h3>
                        <p>
                            C’est un fait, aujourd’hui tout le monde se renseigne sur le web à propos d’un produit ou service avant de passer à l’acte d’achat. En particulier pour les produits et services dépassant un certain niveau tarifaire. Avis sur les blogs, avis clients, conversations sur les forums et les réseaux sociaux, échanges avec ses contacts, avis d’influenceurs, etc.
                        </p>
                        <p>
                            Sur internet, la parole se libère, vous pouvez bénéficier de commentaires positifs ou souffrir d’interventions négatives de la part d’utilisateurs. Il est primordial de pouvoir les identifier et savoir ce qui est dit. Pour cela, il vous faudra mettre en place un audit e-réputation en suivant un ensemble d'indicateurs. Si celui-ci est bien réalisé, il va vous permettre de savoir qui parle de vous et ce qu'ils disent, de comprendre quel est le comportement des communautés autour de votre produit ou votre marque et surtout, vous pourrez définir les points d’amélioration.
                        </p>
                    </div>
                    <div className="col-lg-6" data-aos="zoom-in-up">
                        <div className="methodologie">

                            <div className="row">
                                <div className="col-xl-5 order-xl-2">
                                    <img src={methodologie} alt="" />
                                </div>
                                <div className="col-xl-7 order-xl-1">
                                    <h3 className="texte-bleu">Notre <br />méthodologie</h3>
                                    <ol>
                                        <li>Briefing avec le client</li>
                                        <li>Analyse de votre référencement</li>
                                        <li>Votre réputation sur les sites web</li>
                                        <li>Votre réputation sur les réseaux sociaux</li>
                                        <li>La réputation de vos concurrents</li>
                                        <li>Recherche des personas types</li>
                                        <li>Recommandations</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </SectionCSS>
        </Fragment>
    )
}

const SectionCSS = styled.section`
    padding-bottom: 100px;
    .methodologie {
        background: #fff;
        box-shadow: rgba(2, 45, 75, 0.1) 0 10px 20px;
        border-radius: 10px;
        padding: 30px;
        margin: 50px 0 0 0;
    }
    .methodologie ol {
        list-style: none;
        counter-reset: li;
        padding-left: 0;
    }
    .methodologie li::before {
        content: counter(li) ". ";
        color: var(--bleujolof);
        font-size: 18px;
        margin-left: -19px;
    }
    .methodologie li {
        counter-increment: li;
        margin-left: 19px;
    }
    .methodologie img {
        width: 100%;
        margin-bottom: 50px;
        max-width: 150px;
    }
    @media (min-width: 576px) {
        .methodologie {
            padding: 50px;
        }
    }
    @media (min-width: 768px) {
        .methodologie img {
            width: 100%;
            margin-top: 50px;
            max-width: 200px;
        }
    }
    @media (min-width: 992px) {
        .methodologie {
            margin: 50px 0 0 50px;
        }
        .methodologie img {
            width: 100%;
            margin-top: 0;
            margin-bottom: 50px;
            max-width: 150px;
        }
    }
    @media (min-width: 1200px) {
        .methodologie img {
            margin-top: 50px;
            margin-bottom: 0;
            max-width: 200px;
        }
    }
`
const DivDegrade = styled.div`
    background: linear-gradient(to top, var(--fondpage), rgba(216,237,247,0)); 
    width: 100%; 
    height: 150px;
`

export default Intro
