import React, { Fragment, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import SessionContext from '../composants/utils/SessionContext'
import Presentation from '../composants/accueil/Presentation'
import Demarche from '../composants/accueil/Demarche'
import NosServices from '../composants/accueil/NosServices'
import NosRealisations from '../composants/accueil/NosRealisations'
import HeaderVideo from '../composants/accueil/HeaderVideo'
import NousContacter from '../composants/accueil/NousContacter'
import { useHashFragment } from '../composants/utils/scrollToHashElement'

const MarketingDigital = () => {

    const session = useContext(SessionContext);
    useHashFragment();
    
    useEffect(() => {
        session.setMenuActif('accueil');
    })

    const titre = 'Jolof Digital | Agence spécialisée dans le marketing digital';
    const description = 'Votre partenaire pour la mise en œuvre de solutions numériques destinées aux Petites et Moyennes Entreprises (PME)';
    const urlPage = 'https://jolof.digital/';
    const imgPage = 'https://jolof.digital/og/accueil-jolof-digital.jpg';
    
    return (
        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{titre}</title>
                <meta name="description" content={description}></meta>
                <link rel="canonical" href={urlPage} />
                <meta property="og:title" content={titre} />
                <meta property="og:description" content={description} />
                <meta property="og:type" content="website" /> 
                <meta property="og:url" content={urlPage} />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:image" content={imgPage} />
                <meta name="twitter:card" content="photo"/>
                <meta name="twitter:site" content="@jolofdigital"/>
                <meta property="twitter:title" content={titre}/>
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image:src" content={imgPage} />
                {/*
                <meta name="title" content="Production-Ready Animation Library for React | Framer Motion"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, viewport-fit=cover"/>
                <meta property="fb:app_id" content="216342412279020"/>
                <meta property="og:type" content="product"/>
                <meta property="og:url" content="https://www.framer.com/motion/"/>
                <meta property="og:title" content="Production-Ready Animation Library for React | Framer Motion"/>
                <meta property="og:description" content="Framer Motion is a production-ready React animation and gesture library."/>
                <meta property="og:image" content="https://www.framer.com/images/social/motion.png"/>
                <meta name="twitter:card" content="photo"/>
                <meta name="twitter:site" content="@framer"/><meta name="twitter:creator" content="@framer"/>
                <meta name="twitter:title" content="Production-Ready Animation Library for React | Framer Motion"/>
                <meta name="twitter:description" content="Framer Motion is a production-ready React animation and gesture library."/>
                <meta name="twitter:image:src" content="https://www.framer.com/images/social/motion.png"/>
                <meta itemProp="name" content="Production-Ready Animation Library for React | Framer Motion"/>
                <meta itemProp="description" content="Framer Motion is a production-ready React animation and gesture library."/>
                <meta itemProp="image" content="https://www.framer.com/images/social/motion.png"/>
                <meta name="mobile-web-app-capable" content="yes"/> 
                */}
            </Helmet>

            <HeaderVideo />
            
            {/* Garantissez votre part de marché grâce au numérique */}
            {/* Nous sommes spécialisés dans la conception de projets digitaux créatifs, engageants et axés sur les résultats */}

            <Presentation />

            <Demarche />
            
            <NosServices />

            <NosRealisations />

            <NousContacter />

        </Fragment>
    )
}

export default MarketingDigital