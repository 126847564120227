import React from 'react'
import styled from 'styled-components'
import imgEcommerce from '../../../images/e-commerce.svg'

const Ecommerce = () => {
    return (
        <DivCSS className="container">

            <div className="row pb-100" data-aos="fade-up">
                <div className="col-md-6 pb-50 align-self-center img-left-mobile">
                    <img src={imgEcommerce} alt="" width="80%" data-aos="zoom-in-up" />
                </div>
                <div className="col-md-6 pt-50 pr-100">
                    <h2 className="texte-jaune">E-commerce</h2>
                    <p className="chapeaux pr-100">Vos clients en ligne méritent une expérience d'achat optimale</p>
                    <p>Vous voulez bâtir une stratégie de vente en ligne réussie, intégrant les multiples problématiques de merchandising en ligne, de tunnel d'achat et de service client ? Grâce à notre offre e-commerce, gérez votre boutique en ligne et évoluez vers une plate-forme connectée avec vos différents outils marketing pour garantir votre rentabilité.</p>

                </div>
            </div>

        </DivCSS>
    )
}

const DivCSS = styled.div`
    padding-top: 150px !important;
`

export default Ecommerce
