import React, { Fragment, useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare, faLinkedin, faInstagramSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import TitreMenu from '../images/menu.svg'
import iconHome from '../images/icon_home_bleu.svg'
import iconQuestionCircle from '../images/icon_question-circle_bleu.svg'
import iconCode from '../images/icon_awesome-code_bleu.svg'
import iconDaftingCompass from '../images/icon_drafting-compass_bleu.svg'
import iconEnvelope from '../images/icon_envelope_bleu.svg'
import FondBleuTransparent from './ui/FondBleuTransparent'
import SessionContext from './utils/SessionContext'
import ReactGA from "react-ga4"


const Menu = (props) => {

    ReactGA.event({
        category: "conversion",
        action: "menu_mobile"
    });

    const ajouterConversion = () => {
        ReactGA.event({
            category: "conversion",
            action: "whatsapp_menu"
        });
    }

    const session = useContext(SessionContext);

    return (

        <Fragment>

            <MenuCSS id='menuBody'>

                <Scrollbars autoHide autoHideTimeout={100}>

                    <div id="fermerVolet" title="Fermer le menu" onClick={session.fermerMenuAnimation} >
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                    </div>
                
                    <div className="espacement">

                        <div className="row mt-4 mb-4">
                            <div className="col-md-6">
                                <img src={TitreMenu} className="titreMenu" alt="Menu" />
                            </div>
                            {/*<div className="col-md-6">
                                <div id="box-projet-menu" className="ouvrirProjet" onClick={session.toggleProjet} >
                                    <FontAwesomeIcon icon={faBriefcase} /> <span>Mon projet</span>
                                    <span className="nombre" style={{}}>{session.devis.length}</span>
                                </div>
                            </div>*/}
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                {session.menuActif === 'accueil' ? (
                                    <div onClick={session.defilerHautMobile}>
                                        <div className="box">
                                            <img src={iconHome} alt='Accueil' />
                                            {/*<FontAwesomeIcon icon={faHome} />*/}
                                            <span>Accueil</span>
                                        </div>
                                    </div>
                                ) : (
                                    <Link to="./" onClick={session.toggleMenu} >
                                        <div className="box">
                                            <img src={iconHome} alt='Accueil' />
                                            {/*<FontAwesomeIcon icon={faHome} />*/}
                                            <span>Accueil</span>
                                        </div>
                                    </Link>
                                )}
                            </div>

                            <div className="col-md-12">
                                {session.menuActif === 'accueil' ? (
                                    <div onClick={() => session.defilerMobile('presentation')}>
                                        <div className="box">
                                            <img src={iconQuestionCircle} alt='A propos' />
                                            {/*<FontAwesomeIcon icon={faQuestionCircle} />*/}
                                            <span>A propos</span>
                                        </div>
                                    </div>
                                ) : (
                                    <Link to="./#presentation" onClick={session.toggleMenu} >
                                        <div className="box">
                                            <img src={iconQuestionCircle} alt='A propos' />
                                            {/*<FontAwesomeIcon icon={faQuestionCircle} />*/}
                                            <span>A propos</span>
                                        </div>
                                    </Link>
                                )}
                            </div>

                            <div className="col-md-12">
                                {session.menuActif === 'accueil' ? (
                                    <div onClick={() => session.defilerMobile('services')}>
                                        <div className="box">
                                            <img src={iconCode} alt='Nos services' />
                                            {/*<FontAwesomeIcon icon={faCode} />*/}
                                            <span>Nos services</span>
                                        </div>
                                    </div>
                                ) : (
                                    <Link to="./#services" onClick={session.toggleMenu} >
                                        <div className="box">
                                            <img src={iconCode} alt='Nos services' />
                                            {/*<FontAwesomeIcon icon={faCode} />*/}
                                            <span>Nos services</span>
                                        </div>
                                    </Link>
                                )}
                            </div>

                            {session.menuActif !== 'accueil' && (
                                <div className="col-md-12 mb-2">
                                    <Link to="site-internet" onClick={session.toggleMenu} >
                                        <div className="box small">
                                            <span>Création de site internet</span>
                                        </div>
                                    </Link>
                                    <Link to="community-management" onClick={session.toggleMenu} >
                                        <div className="box small">
                                            <span>Community management</span>
                                        </div>
                                    </Link>
                                    <Link to="application-mobile" onClick={session.toggleMenu} >
                                        <div className="box small">
                                            <span>Application mobile</span>
                                        </div>
                                    </Link>
                                    <Link to="referencement" onClick={session.toggleMenu} >
                                        <div className="box small">
                                            <span>Référencement</span>
                                        </div>
                                    </Link>
                                    <Link to="email-marketing" onClick={session.toggleMenu} >
                                        <div className="box small">
                                            <span>Email marketing</span>
                                        </div>
                                    </Link>
                                    <Link to="audit-e-reputation" onClick={session.toggleMenu} >
                                        <div className="box small">
                                            <span>Audit e-réputation</span>
                                        </div>
                                    </Link>
                                </div>
                            )}

                            <div className="col-md-12">
                                {session.menuActif === 'accueil' ? (
                                    <div onClick={() => session.defilerMobile('realisations')}>
                                        <div className="box">
                                            <img src={iconDaftingCompass} alt='Nos réalisations' />
                                            {/*<FontAwesomeIcon icon={faDraftingCompass} />*/}
                                            <span>Nos réalisations</span>
                                        </div>
                                    </div>
                                ) : (
                                    <Link to="./#realisations" onClick={session.toggleMenu} >
                                        <div className="box">
                                            <img src={iconDaftingCompass} alt='Nos réalisations' />
                                            {/*<FontAwesomeIcon icon={faDraftingCompass} />*/} 
                                            <span>Nos réalisations</span>
                                        </div>
                                    </Link>
                                )}
                            </div>

                            <div className="col-md-12">
                                {session.menuActif === 'accueil' ? (
                                    <div onClick={() => session.defilerMobile('contact')}>
                                        <div className="box">
                                            <img src={iconEnvelope} alt='Nous contacter' />
                                            {/*<FontAwesomeIcon icon={faEnvelope} />*/} 
                                            <span>Nous contacter</span>
                                        </div>
                                    </div>
                                ) : (
                                    <Link to="./#contact" onClick={session.toggleMenu} >
                                        <div className="box">
                                            <img src={iconEnvelope} alt='Nous contacter' />
                                            {/*<FontAwesomeIcon icon={faEnvelope} />*/} 
                                            <span>Nous contacter</span>
                                        </div>
                                    </Link>
                                )}
                            </div>
                            
                        </div>

                        <div className="row mt-2">
                            {/*<div className="col-md-8 mt-5 lecture">
                                <Link to="" onClick={session.toggleMenu} >
                                    <FontAwesomeIcon icon={faBookReader} />
                                    <i className="fas fa-book-reader"></i>
                                    <span>En savoir plus sur le digital</span><br/>
                                    <p>Nous avons sélectionné de bons articles pour vous</p>
                                </Link>
                            </div>
                            <hr/>*/}
                            <div className="col-md-12 social">
                                <span>Suivez-nous sur les réseaux sociaux</span>
                                <a href="https://facebook.com/jolofdigital" title="Facebook" target="_blank" rel="noopener noreferrer" className='click_facebook' aria-label="Voir notre page Facebook">
                                    <FontAwesomeIcon icon={faFacebookSquare} />
                                </a>
                                {/*<a href="https://instagram.com/jolofdigital" title="Instagram" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>*/}
                                <a href="https://instagram.com/jolofdigital" title="Instagram" target="_blank" rel="noopener noreferrer" className='click_instagram' aria-label="Voir notre page Instagram">
                                    <FontAwesomeIcon icon={faInstagramSquare} />
                                </a>
                                <a href="https://twitter.com/jolofdigital" title="Twitter" target="_blank" rel="noopener noreferrer" className='click_twitter' aria-label="Voir notre page Twitter">
                                    <FontAwesomeIcon icon={faTwitterSquare} />
                                </a>
                                <a href="https://linkedin.com/company/jolofdigital" title="LinkedIn" target="_blank" rel="noopener noreferrer" className='click_linkedin' aria-label="Voir notre page LinkedIn">
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 social">
                                <span>WhatsApp</span>
                                <a href="https://wa.me/221777444040" target="_blank" rel='noopener noreferrer' className='click_whatsapp' aria-label="Nous contacter par WhatsApp" onClick={ajouterConversion}>
                                    <p className="whatsapp">
                                        <FontAwesomeIcon icon={faWhatsapp} />
                                        77 744 40 40
                                    </p>
                                </a>
                            </div>
                        </div>

                    </div>

                </Scrollbars>

            </MenuCSS>

            { session.menu && <FondBleuTransparent fermer={session.fermerMenuAnimation} titre='Fermer le menu' /> }
            
        </Fragment>
    )
}

// Styles CSS
const MenuCSS = styled.div`
    width: 95%;
    max-width: 1000px;
    height: 100%;
    top: 0;
    right: -50px;
    position: fixed;
    background: var(--fondpage);
    box-shadow: rgba(0, 0, 0, 0.1) -63px 0 99px;
    //overflow-y: auto;
    z-index: 1000;
    transition: all 300ms ease-in-out;
    animation-name: positionnementMenu;
    animation-duration: 750ms;
    @keyframes positionnementMenu {
        0% {right: -1100px; opacity: 0;}
        50% {right: -25px; opacity: 1;}
        100% {right: -50px; opacity: 1;}
    }
    
    #fermerVolet {
        position: fixed;
        background: var(--fondpage) !important;
        color: var(--bleujolof) !important;
        right: calc(95% - 50px); 
        padding: 25px 5px 25px 15px;
        border-radius: 25px 0 0 25px;
        bottom: 50%;
        cursor: pointer;
        animation-name: opacite;
        animation-duration: 1000ms;
        z-index: 1000;
    }
    @keyframes opacite {
        0% {opacity: 0;}
        50% {opacity: 0;}
        100% {opacity: 1;}
    }

    .titreMenu {
        height: 35px;
        top: -10px;
        position: relative;
        font-size: 40px;
        color: var(--orangejolof) !important;
        font-weight: 700;
    }
    .espacement {
        padding: 30px 70px 30px 30px;
    }
    & > div > div:first-child {
        margin-bottom: -20px !important;
    }
    .box {
        background: white;
        box-shadow: 0 10px 30px rgba(2, 45, 74, 0.1) !important;
        padding: 20px;
        margin-bottom: 15px;
        border-radius: 10px;
        transition: all 200ms ease-in-out;
    }
    .box.small {
        box-shadow: none !important;
        padding: 8px 10px 5px 10px;
        margin-bottom: 7px;
        margin-left: 15px;
        border-left: var(--bleujolof) 5px solid;
    }
    .box.small span {
        font-size: 18px !important;
    }
    .box img {
        width: 30px;
        margin-right: 10px;
    }
    /*.box svg {
        font-size: 30px;
        color: var(--bleujolof) !important;
        margin-right: 10px;
    }
    .box svg.mb-3 {
        font-size: 50px;
        color: var(--bleujolof);
        margin-right: 10px;
    }*/
    .box span {
        font-size: 22px;
        font-weight: 400;
        color: var(--bleujolof) !important;
        top: 3px;
        position: relative;
        transition: all 200ms ease-in-out;
    }
    /*.box span:hover, .box span:hover svg, .box:hover svg:not(.mb-3){
        color: var(--bleujolofhover) !important;
    }*/
    .box span:hover{
        color: var(--jaunejolof) !important;
    }
    /*.box span:hover svg, .box:hover svg:not(.mb-3){
        color: var(--bleujolofhover) !important;
    }*/
    .box .listes {
        font-size: 18px;
        line-height: 25px;
    }
    .box .listes a{
        transition: all 200ms ease-in-out;
    }
    .box .listes a:hover {
        color: var(--jaunejolof) !important;
    }
    #box-projet-menu {
        background: var(--bleujolof);
        padding: 20px;
        border-radius: 10px;
        top: 33px;
        position: relative;
        cursor: pointer;
        transition: 300ms all ease-in-out;
    }
    #box-projet-menu:hover {
        background: var(--bleujolofhover);
    }
    #box-projet-menu svg {
        font-size: 30px;
        color: white !important;
        margin-right: 10px;
    }
    #box-projet-menu span {
        font-size: 20px;
        font-weight: 400;
        color: white !important;
        top: -2px;
        position: relative;
    }
    .social {
        text-align: left;
        margin-bottom: 25px;
        padding-left: 15px;
    }
    .social svg {
        font-size: 55px;
        margin: 0 5px;
        color: var(--bleujolof) !important;
        transition: 300ms all ease-in-out;
    }
    .social svg:hover {
        color: var(--bleujolofhover) !important;
    }
    .social span {
        opacity: 0.75;
        padding: 15px 5px;
        font-size: 25px;
        display: block;
    }
    .lecture {
        padding: 0 20px;
    }
    .lecture svg {
        color: var(--bleujolof) !important;
        border: var(--bleujolof) 2px solid;
        padding: 10px;
        border-radius: 5px;
        font-size: 55px;
        margin: 0 auto;
    }
    .lecture span {
        font-size: 20px;
        line-height: 23px;
        font-weight: 400;
        color: var(--bleujolof) !important;
        display: block;
        margin: 15px 0 -15px 0;
    }
    .lecture p {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        margin-bottom: 50px;
    }
    hr {
        margin-left: 23px !important;
    }
    .nombre{
        background: rgba(0,0,0,0.2);
        border-radius: 50px;
        padding: 2px 8px 3px 8px;
        float: right;
        margin-top: 4px;
    }

    .whatsapp {
        font-size: 25px !important;
        font-weight: 400;
        margin: 0;
        padding: 10px 20px 10px 10px;
        width: fit-content;
        transition: color 200ms ease-in-out;
        //border: white 1px solid;
        background: var(--bleujolof);
        color: var(--fondpage) !important;
        border-radius: 10px;
        display: flex;
        align-items: center;
    }
    .whatsapp svg {
        font-size: 30px;
        padding-right: 5px;
        color: var(--fondpage) !important;
    }

    @media (min-width: 768px) { // MD
        .titreMenu {
            height: 50px;
            top: -5px;
        }
        .espacement {
            padding: 50px 100px 50px 50px;
        }
        .box {
            padding: 20px 30px;
            margin-bottom: 30px;
        }
        .box span {
            font-size: 25px;
            font-weight: 400;
            color: var(--bleujolof);
            top: -2px;
            position: relative;
        }
        .box .listes {
            font-size: 18px;
        }
        #box-projet-menu {
            top: -15px;
            position: relative;
            cursor: pointer;
        }
        #box-projet-menu svg {
            font-size: 30px;
            margin-right: 10px;
        }
        #box-projet-menu span {
            font-size: 20px;
            top: -2px;
            position: relative;
        }
        .social {
            text-align: left;
            padding-top: 55px;
            margin-bottom: 0;
        }
        .social svg {
            font-size: 55px;
            margin: 0 0 0 10px;
        }
        .social span {
            //display: none;
        }
        .lecture svg {
            padding: 10px;
            border-radius: 5px;
            font-size: 55px;
        }
        .lecture span {
            font-size: 25px;
            font-weight: 400;
            color: var(--bleujolof) !important;
            top: -21px !important;
            left: 13px !important;
            position: relative !important;
            display: unset !important;
        }
        .lecture p {
            font-size: 16px;
            font-weight: 400;
            top: -22px;
            left: 69px;
            position: relative;
        }
        hr {
            display: none;
        }
    }

    @media (min-width: 992px) { // LG
            width: 950px;
        #fermerVolet {
            right: 900px;
            padding: 25px 15px;
        }
        .social svg {
            font-size: 55px;
            margin: 0 5px;
        }
    }

    // Animations sortie
    .fonduSortie {
        animation-name: opaciteSortie;
        animation-duration: 500ms;
        opacity:0;
    }
    @keyframes opaciteSortie {
        0% {opacity: 1;}
        75% {opacity: 1;}
        100% {opacity: 0;}
    }

    &.slideSortie {
        animation-name: positionSortieMenu;
        animation-duration: 500ms;
        right: -1100px;
    }
    @keyframes positionSortieMenu {
        from {right: -50px;}
        to {right: -1100px;}
    }
    .fonduSortieRapide {
        animation-name: opaciteSortie;
        animation-duration: 100ms;
        opacity: 0;
    }

`


export default Menu
