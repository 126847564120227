import React from 'react'
import imgRecommandations from '../../../images/reputation-recommandation.svg'

const Recommandatons = () => {
    return (
        <section className="bg-jaune-blanc trait-gris-bottom pt-150 pb-150">

            <div className="container">
                <div className="row" data-aos="fade-up">
                    <div className="col-3 text-end align-self-center" data-aos="zoom-in-up">
                    </div>
                    <div className="col-6 text-center">
                        <img src={imgRecommandations} alt="" width="150" className="maxwidth100" />
                    </div>
                    <div className="col-3 text-start align-self-center" data-aos="zoom-in-up">
                    </div>
                </div>
                <div className="row" data-aos="fade-up">
                    <div className="col-md-8 offset-md-2 text-center">
                        <h2 className="texte-jaune pt-5">Recommandations</h2>
                        <p className="chapeaux">Tirer les conclusions de l’audit et définir comment <br className="d-none d-xl-block"/>adapter votre stratégie de communication web à vos objectifs</p>
                        <p>Le but de l’audit est de faire un vrai bilan de votre stratégie de communication sur Internet afin d’en mesurer les impacts, savoir si elle est bien reçue par les internautes et identifier vos points forts, vos points faibles et surtout maîtriser vos sources majeures de communication. Les sources peuvent être <b>déclaratives</b> (tout ce que vous dites de vous), <b>agissantes</b> (ce que vous faites, vos prises de parole, l’aspect opérationnel, vos actions marketing) ou <b>externes</b> (ce que les autres écrivent à votre sujet)</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Recommandatons
