import React, { useContext, useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faTwitterSquare, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faSortDown, faListOl, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'
import imgDevis from '../../../images/icone-tarif-bleu.svg'
import imgValide from '../../../images/valide.svg'
import SlideUpDown from '../../ui/SlideUpDown'
import BoutonLarge from '../../ui/BoutonLarge'
import Bouton from '../../ui/Bouton'
import InputSpinner from 'react-bootstrap-input-spinner'  
import SessionContext from '../../utils/SessionContext'
import Loader from '../../ui/Loader'

const Devis = () => {
    
    const session = useContext(SessionContext);

    const [facebook, setFacebook ] = useState(false);
    const [instagram, setInstagram ] = useState(false);
    const [linkedIn, setLinkedIn ] = useState(false);
    const [twitter, setTwitter ] = useState(false);
    const [youtube, setYoutube ] = useState(false);
    const [publications, setPublications ] = useState(false);
    const [nombrePublications, setNombrePublications ] = useState(8);
    const [nombreVideos, setNombreVideos ] = useState(1);
    const [budget, setBudget ] = useState(0);
    const [offre, setOffre ] = useState(false);

    const facebookHandler = () => {setFacebook(!facebook)}
    const instagramHandler = () => {setInstagram(!instagram)}
    const linkedInHandler = () => {setLinkedIn(!linkedIn)}
    const twitterHandler = () => {setTwitter(!twitter)}
    const youtubeHandler = () => {setYoutube(!youtube)}
    /*const nombrePublicationsHandler = (event) => {
        setNombrePublications(event.target.value)
    }
    const nombreVideosHandler = (event) => {
        setNombreVideos(event.target.value)
    } 
    const budgetHandler = (event) => {
        setBudget(event.target.value)
    }*/

    useEffect(() => {
        setPublications(
            facebook || instagram || linkedIn
        );
    }, [facebook, instagram, linkedIn]);

    useEffect(() => {
        setOffre(
            facebook || instagram || linkedIn || twitter || youtube
        );
    }, [facebook, instagram, linkedIn, twitter, youtube]);

    const communityManagement = {
        'article' : 'Community management',
        'contenu' : [
            facebook && ('Facebook : ' + nombrePublications + ' publications/mois'),
            instagram && ('Instagram : ' + nombrePublications + ' publications/mois'),
            linkedIn && ('LinkedIn : ' + nombrePublications + ' publications/mois'),
            twitter && ('Twitter : publications illimitées'),
            youtube && ('Youtube : ' + nombreVideos + ' vidéo' + (Number(nombreVideos) > 1 ? 's' : '') + '/mois'),
            budget && ('Budget mensuel : ' + Number(budget).toLocaleString() + ' FCFA')
        ]
    }

    return (
        <SectionCSS className="bg-bleu-blanc pt-150 pb-150" id="devisBox">
            <div className="container">

                { session.recupService('Community management') ? (

                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <div className="col-12 text-center">
                            <img src={imgValide} alt="" width="100" className="mb-3" data-aos="fade-in-up" />
                            <h2 className="texte-bleu">Félicitations</h2>
                            <p className="sous-titres mb-2">Vous avez ajouté Community Management à votre projet !</p>
                            <p className="sous-titres mb-3">Vous pouvez nous envoyer votre projet maintenant ou poursuivre votre visite pour y ajouter d'autres services qui pourraient vous intéresser.</p>

                            <Bouton couleur='bleu' action={session.toggleProjet}>Voir ou envoyer mon projet</Bouton>
                        
                            <p className="sous-titres mt-4 supprimer_projet" onClick={() => session.supprimerDevis('Community management')}>Supprimer du projet</p>
                        </div>
                    </div>


                ) : (
                    
                    <Fragment>
                        <div className="row pb-100" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                            <div className="col-12 text-center">
                                <img src={imgDevis} alt="" width="50" className="mb-3" data-aos="zoom-in-up" />
                                <h2 className="texte-bleu">Demandez <br className="d-sm-none" />un devis</h2>
                                <p className="sous-titres">Sélectionnez les réseaux sociaux et les options <br className="d-none d-sm-block" />qui correspondent à vos besoins</p>
                            </div>
                        </div>

                        <div className="row" data-aos="fade-up">

                            <div className="col-md-6 col-xl-6 offset-xl-1">

                                <SlideUpDown open={!offre}>
                                    <div id="attente-mobile" className="box-options text-center">
                                        <Loader />
                                        <p className="chapeaux">Choisissez un réseau social pour démarrer</p>
                                        <FontAwesomeIcon icon={faSortDown} className='texte-bleu-clair' />
                                    </div>
                                </SlideUpDown>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-xl-1 align-self-center text-center icone">
                                            <FontAwesomeIcon icon={faFacebookSquare} />
                                        </div>
                                        <div className="col-xl-8">
                                            <b className="denomination">Facebook</b><br/>
                                            <span className="description">Avec plus de 2 milliards d’utilisateurs, Facebook est LE réseau social le plus populaire au monde. Il est devenu un véritable outil de communication en proposant une multitude d’opportunités en termes de visibilité pour votre business</span>
                                        </div>
                                        <div className="col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={facebookHandler}
                                                    checked={facebook} 
                                                    className="switch-input" 
                                                    type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-xl-1 align-self-center text-center icone">
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </div>
                                        <div className="col-xl-8">
                                            <b className="denomination">Instagram</b><br/>
                                            <span className="description">Instagram vous donne l’occasion de mettre en scène votre activité, de dévoiler les coulisses d’un événement ou la fabrication d’un produit. L’objectif est de montrer le côté lifestyle de la marque en créant une vraie ambiance visuelle</span><br/>
                                        </div>
                                        <div className="col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={instagramHandler} 
                                                    checked={instagram} 
                                                    className="switch-input" 
                                                    type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-xl-1 align-self-center text-center icone">
                                            <FontAwesomeIcon icon={faLinkedin} />
                                        </div>
                                        <div className="col-xl-8">
                                            <b className="denomination">LinkedIn</b><br/>
                                            <span className="description">En plus de travailler votre marque employeur, notamment la visibilité qu’apportent vos collaborateurs, Linkedin permet de diffuser du contenu de qualité auprès de professionnels et de prospects de vos secteurs d’activité</span><br/>
                                        </div>
                                        <div className="col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={linkedInHandler} 
                                                    checked={linkedIn} 
                                                    className="switch-input" 
                                                    type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-xl-1 align-self-center text-center icone">
                                            <FontAwesomeIcon icon={faTwitterSquare} />
                                        </div>
                                        <div className="col-xl-8">
                                            <b className="denomination">Twitter</b><br/>
                                            <span className="description">La plupart des journalistes, étudiants, hommes politiques, entrepreneurs, sportifs, ... ont un compte Twitter. Il est devenu une des principales sources d’information en temps réel et tous les événements y sont visibles et commentés à la seconde</span><br/>
                                        </div>
                                        <div className="col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={twitterHandler} 
                                                    checked={twitter} 
                                                    className="switch-input" 
                                                    type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-options">
                                    <div className="row">
                                        <div className="col-xl-1 align-self-center text-center icone">
                                            <FontAwesomeIcon icon={faYoutube} />
                                        </div>
                                        <div className="col-xl-8">
                                            <b className="denomination">YouTube</b><br/>
                                            <span className="description">YouTube est une plateforme à mi-chemin entre un site d’hébergement de contenus, un réseau social et un moteur de recherche. Le format vidéo permet de communiquer sur votre entreprise de façon moins formelle et de rendre vos produits et services plus tangibles aux yeux des internautes</span><br/>
                                        </div>
                                        <div className="col-xl-3 align-self-center text-end">
                                            <label className="switch">
                                                <input 
                                                    onChange={youtubeHandler} 
                                                    checked={youtube} 
                                                    className="switch-input" 
                                                    type="checkbox" />
                                                <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <SlideUpDown open={publications}>
                                    <div className="box-options">
                                        <div className="row">
                                            <div className="col-xl-1 align-self-center text-center icone">
                                                <FontAwesomeIcon icon={faListOl} />
                                            </div>
                                            <div className="col-xl-7">
                                                <b className="denomination">Publications par mois</b><br/>
                                                <span className="description">Sur Facebook, Instagram, LinkedIn</span><br/>
                                            </div>
                                            <div className="col-xl-4 align-self-center text-center">
                                                <div className="boxSpinner">
                                                    <InputSpinner
                                                        type={'real'}
                                                        precision={0}
                                                        max={60}
                                                        min={0}
                                                        step={1}
                                                        value={nombrePublications}
                                                        onChange={nbr => setNombrePublications(nbr)}
                                                        size="sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={youtube}>
                                    <div className="box-options">
                                        <div className="row">
                                            <div className="col-xl-1 align-self-center text-center icone">
                                                <FontAwesomeIcon icon={faListOl} />
                                            </div>
                                            <div className="col-xl-7">
                                                <b className="denomination">Publications par mois</b><br/>
                                                <span className="description">Sur Youtube</span><br/>
                                            </div>
                                            <div className="col-xl-4 align-self-center text-center">
                                                <div className="boxSpinner">
                                                    <InputSpinner
                                                        type={'real'}
                                                        precision={0}
                                                        max={10}
                                                        min={0}
                                                        step={1}
                                                        value={nombreVideos}
                                                        onChange={nbr => setNombreVideos(nbr)}
                                                        size="sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre}>
                                    <div className="box-options">
                                        <div className="row">
                                            <div className="col-lg-2 col-xl-1 align-self-center text-center icone">
                                                <FontAwesomeIcon icon={faMoneyBillWave} style={{fontSize: '1.5em'}} />
                                            </div>
                                            <div className="col-lg-7 col-xl-7">
                                                <b className="denomination">Budget achat d'espace mensuel <span style={{fontSize: '66%', fontWeight: 300}}>(En FCFA)</span></b><br/>
                                                <span className="description">Publicité sur les réseaux sociaux </span><br/>
                                            </div>
                                            <div className="col-lg-3 col-xl-4 align-self-center text-center">
                                                <div className="boxSpinner">
                                                    <InputSpinner
                                                        type={'real'}
                                                        precision={0}
                                                        max={25000000}
                                                        min={0}
                                                        step={5000}
                                                        value={budget}
                                                        onChange={nbr => setBudget(nbr)}
                                                        size="sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SlideUpDown>

                            </div>

                            <div className="col-md-6 col-xl-4">

                                <SlideUpDown open={!offre}>
                                    <div id="attente" className="box-options text-center">
                                        <Loader />
                                        <p className="chapeaux">Choisissez un réseau social pour démarrer</p>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre}>

                                    <div id="offre" className="box-options pt-0 pb-0">
                                        <div className="row border-0" id="optionschoisies">
                                            <div className="col-7 col-md-8 text-start texte-bleu text-uppercase align-self-center">Options choisies</div>
                                            <div className="col-5 col-md-4 text-center description align-self-center">Publications<br/> par mois</div>
                                        </div>

                                        <SlideUpDown open={facebook}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">Facebook</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center">
                                                    {nombrePublications}
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={instagram}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">Instagram</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center">
                                                    {nombrePublications}
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={linkedIn}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">LinkedIn</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center">
                                                    {nombrePublications}
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={twitter}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">Twitter</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-cente">
                                                    Illimité
                                                </div>
                                            </div>
                                        </SlideUpDown>

                                        <SlideUpDown open={youtube}>
                                            <div className="row">
                                                <div className="col-7 col-md-8 text-start">YouTube</div>
                                                <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center nombrepubyoutubeHTML">
                                                    {nombreVideos}
                                                </div>
                                            </div>
                                        </SlideUpDown>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre && budget > 0}>
                                    <div className="box-options bg-bleu-fonce">
                                        <div className="row">
                                            <div className="col-7 col-md-8 text-start text-white">Achat d'espace <span className="d-none d-xl-inline-block">mensuel</span></div>
                                            <div className="col-5 col-md-4 text-center texte-bleu fw-bold text-nowrap align-self-center">
                                                {Number(budget).toLocaleString()}
                                            </div>
                                        </div>
                                    </div>
                                </SlideUpDown>

                                <SlideUpDown open={offre}>
                                    <BoutonLarge 
                                        couleur='bleu' 
                                        action={() => session.ajouterDevis(communityManagement) }>
                                        Ajouter à mon projet
                                    </BoutonLarge>
                                </SlideUpDown>

                            </div>

                        </div>
                    </Fragment>

                )}

            </div>
            
        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    //img{width: 50%;}
    
`

export default Devis
