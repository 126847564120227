import React from 'react'
import { css } from "@emotion/react";
import HashLoader from 'react-spinners/HashLoader'

const Loader = () => {
    const override = css`
        display: block;
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 25px;
    `;
    return (
        <HashLoader color='#2ac1e3' loading={true} css={override} size={100} />
    )
}

export default Loader
