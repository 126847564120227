import React, { useState, Fragment, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import Bouton from '../ui/Bouton'
import emailjs from '@emailjs/browser'
import LoaderEmail from '../ui/LoaderEmail'
import ReactGA from "react-ga4"


const NousContacter = () => {
    const [messageAttente, setMessageAttente] = useState(false);
    const [messageEnvoye, setMessageEnvoye] = useState(false);

    const form = useRef();

    const envoyerEmail = (e) => {
        e.preventDefault();
        setMessageAttente(true);
    
        emailjs.sendForm('service_aa2v7m5', 'template_7uzardf', form.current, 'ckx0RtjJa7hbn2H-R')
          .then((result) => {
              //console.log(result.text);
              setMessageEnvoye(true);
              setMessageAttente(false);
                const element = document.getElementById('contact');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
          }, (error) => {
              console.log(error.text);
              setMessageAttente(false);
          });
      };

    useEffect(() => {
        if(messageEnvoye) {
            ReactGA.event({
                category: "conversion",
                action: "contact"
              });
        }
    }, [messageEnvoye])
    

    return (
        <Fragment>
            <SectionTitre  className="bg-bleu-clair" id='contact'>
                <div className="container">

                    {messageEnvoye ? (

                        <>
                        <div className="text-center pb-50 pt-50">
                            <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                                <FontAwesomeIcon icon={faCheckSquare} />
                            </div>
                        </div>

                        <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                            <div className="col-12 text-center">
                                <h2 className="texte-bleu">Message envoyé</h2>
                                <p className="sous-titres mb-3">Votre message a été transmis avec succès. Nous ferons de notre mieux pour y répondre dans les plus brefs délais.</p>
                            </div>
                        </div>
                        </>

                    ) : (

                        <>
                            <div className="text-center pb-50">
                                <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                            </div>

                            <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                                <div className="col-12 text-center">
                                    <h2 className="texte-bleu">Nous contacter</h2>
                                    <p className="sous-titres">N'hésitez pas à nous contacter pour toute demande d'information ou de devis. Notre équipe est à votre disposition pour répondre à vos besoins et vous accompagner dans votre projet.</p>
                                </div>
                            </div>
                        </>

                    )}

                </div>
            </SectionTitre>

            {messageEnvoye ? (

                <div className='bg-bleu-clair' style={{paddingBottom: '150px'}}>

                </div>

            ) : (

            <SectionServices className="pt-0 mt-0 bg-bleu-clair">
                <div className="container">

                    <div className="row">

                        <div className="col-md-6 offset-md-3 pb-50">

                            <form  ref={form} onSubmit={envoyerEmail}>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label htmlFor="nom" className="formulaire_label">Prénom et nom</label>
                                            <input type="text" className="formulaire" id="nom" name='nom' required />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label htmlFor="telephone" className="formulaire_label">Numéro de téléphone</label>
                                            <input type="text" className="formulaire" id="telephone" name='telephone' required />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label htmlFor="email" className="formulaire_label">Adresse email</label>
                                            <input type="email" className="formulaire" id="email" name='email' required />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label htmlFor="entreprise" className="formulaire_label">Entreprise</label>
                                            <input type="text" className="formulaire" id="entreprise" name='entreprise' />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="message" className="formulaire_label">Message</label>
                                    <textarea id="message" name='message' rows="7" className="formulaire" />
                                </div>

                                {messageAttente ? (

                                    <div className='centrerBouton pt-5'>
                                        <LoaderEmail />
                                    </div>

                                ) : (

                                    <div className='centrerBouton'>
                                        <Bouton couleur='bleu' type="submit">Envoyer le message</Bouton>
                                    </div>

                                )}


                            </form>

                        </div>

                    </div>

                </div>
            </SectionServices>
            )}

        </Fragment>
    )
}

export default NousContacter


const SectionTitre = styled.section`
    padding: 150px 0 50px 0;
    svg{
        font-size: 50px; 
    }
    h2{
        font-weight: 700;
        text-transform: uppercase;
    }
`

const SectionServices = styled.section`
    padding: 100px 0;

    .centrerBouton{
        text-align: center;
        margin-top: 15px;
    }
`