import React, { Fragment } from 'react'
import styled from 'styled-components'
import methodologie from '../../../images/methodologie.svg'

const Intro = () => {
    return (
        <Fragment>
            <DivDegrade />
            <SectionCSS className="bg-bleu-blanc">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up">
                        <hr />
                        <h3>
                            En téléchargeant votre application, l’utilisateur <br className="d-none d-xl-block" />transporte votre marque à ses côtés
                        </h3>
                        <p>
                            Aujourd'hui, presque tout le monde possède un smartphone dont il ne se sépare que très rarement et la plupart le préfère à l'ordinateur pour naviguer sur le web. Tous les sites et applications web que nous développons sont « responsives », donc adaptés à n’importe quel appareil (ordinateur, smartphone, tablette) ; mais dans certains cas, vous aurez besoin d’une application mobile qui peut mieux atteindre vos clients et leur offrir la meilleure expérience mobile possible en utilisant toutes les possibilités de leur téléphone (notification push, GPS, appareil photo, etc.).
                        </p>
                        <p>
                            En plus, une application mobile offre plus de visibilité pour votre entreprise ; en général, les consommateurs aiment les icônes et pas les Url, car celles-ci sont plus simples à trouver et à retenir. L’application mobile a l’avantage d’être rapidement accessible car il suffit de cliquer sur une icône et non de taper une url. Ceci présente un gain de temps considérable pour l’utilisateur, ce qui est très apprécié dans son processus d’achat. Une fois que le consommateur aura téléchargé votre application mobile sur Google Play ou l’Apple store, vous serez présent dans son quotidien. Étant donné qu’il consulte son téléphone plusieurs fois par jour, il verra votre marque tous les jours.
                        </p>
                    </div>
                    <div className="col-lg-6" data-aos="zoom-in-up">
                        <div className="methodologie">

                            <div className="row">
                                <div className="col-xl-5 order-xl-2">
                                    <img src={methodologie} alt="" />
                                </div>
                                <div className="col-xl-7 order-xl-1">
                                    <h3 className="texte-bleu">Notre <br />méthodologie</h3>
                                    <ol>
                                        <li>Briefing avec le client</li>
                                        <li>Phase de conception</li>
                                        <li>UX & UI Design</li>
                                        <li>Développement mobile</li>
                                        <li>Console d'administration</li>
                                        <li>Tests et corrections</li>
                                        <li>Validation avec le client</li>
                                        <li>Publication sur les stores</li>
                                        <li>Maintenance</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </SectionCSS>
        </Fragment>
    )
}

const SectionCSS = styled.section`
    padding-bottom: 100px;
    .methodologie {
        background: #fff;
        box-shadow: rgba(2, 45, 75, 0.1) 0 10px 20px;
        border-radius: 10px;
        padding: 30px;
        margin: 50px 0 0 0;
    }
    .methodologie ol {
        list-style: none;
        counter-reset: li;
        padding-left: 0;
    }
    .methodologie li::before {
        content: counter(li) ". ";
        color: var(--bleujolof);
        font-size: 18px;
        margin-left: -19px;
    }
    .methodologie li {
        counter-increment: li;
        margin-left: 19px;
    }
    .methodologie img {
        width: 100%;
        margin-bottom: 50px;
        max-width: 150px;
    }
    @media (min-width: 576px) {
        .methodologie {
            padding: 50px;
        }
    }
    @media (min-width: 768px) {
        .methodologie img {
            width: 100%;
            margin-top: 50px;
            max-width: 200px;
        }
    }
    @media (min-width: 992px) {
        .methodologie {
            margin: 50px 0 0 50px;
        }
        .methodologie img {
            width: 100%;
            margin-top: 0;
            margin-bottom: 50px;
            max-width: 150px;
        }
    }
    @media (min-width: 1200px) {
        .methodologie img {
            margin-top: 50px;
            margin-bottom: 0;
            max-width: 200px;
        }
    }
`
const DivDegrade = styled.div`
    background: linear-gradient(to top, var(--fondpage), rgba(216,237,247,0)); 
    width: 100%; 
    height: 150px;
`

export default Intro
