import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import logo from '../images/logo-jolof-digital.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faChevronCircleRight, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare, faLinkedin, faInstagramSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import SessionContext from './utils/SessionContext'
import ReactGA from "react-ga4"

const Footer = () => {

    const session = useContext(SessionContext)
    const date = new Date();

    const ajouterConversion = () => {
        ReactGA.event({
            category: "conversion",
            action: "whatsapp_footer"
        });
    }

    return (
        <Pied className="bg-white">

            <div className="container">
                <div className="row">

                    <div className="col-xl-3 col-md-6 mb-5">
                        <p>
                            <Link to="/">
                                <img src={logo} alt="Jolof Digital" width="250" />
                            </Link>
                        </p>
                        <p className="mt-3 mb-4 presentation">Agence spécialisée dans le marketing digital {/*et les systèmes d’information avec une connaissance globale*/} 360°</p>
                        <a href="https://wa.me/221777444040" target='_blank' rel='noopener noreferrer' className='click_whatsapp' aria-label="Nous contacter par WhatsApp" onClick={ajouterConversion}>
                            <p className="whatsapp">
                                <FontAwesomeIcon icon={faWhatsapp} />
                                <span>(+221)</span> 77 744 40 40
                            </p>
                        </a>
                        <ul className="listes">
                            {/*<li>Plan du site</li>
                            <li>Politique de confidentialité</li>*/}
                            {/* <li>Conditions Générales de Vente</li> */}
                        </ul>
                        <p>© {date.getFullYear()} Jolof Digital. Tous droits réservés</p>
                    </div>

                    <div className="col-xl-3 offset-xl-1 col-md-6 mt-md-5 mb-md-5">
                        <p className="texte-gris mb-3 titreFooter">Suivez-nous</p>
                        <a href="https://facebook.com/jolofdigital" target="_blank" rel="noopener noreferrer" className='click_facebook' aria-label="Voir notre page Facebook">
                            <FontAwesomeIcon icon={faFacebookSquare} />
                        </a>
                        {/*<a href="https://instagram.com/jolofdigital" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>*/}
                        <a href="https://instagram.com/jolofdigital" target="_blank" rel="noopener noreferrer" className='click_instagram' aria-label="Voir notre page Instagram">
                            <FontAwesomeIcon icon={faInstagramSquare} />
                        </a>
                        <a href="https://twitter.com/jolofdigital" target="_blank" rel="noopener noreferrer"  className='click_twitter' aria-label="Voir notre page Twitter">
                            <FontAwesomeIcon icon={faTwitterSquare} />
                        </a>
                        <a href="https://linkedin.com/company/jolofdigital" target="_blank" rel="noopener noreferrer" className='click_linkedin' aria-label="Voir notre page LinkedIn">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>

                        {/* Begin Mailchimp Signup Form for mobile */}
                        <p className="texte-gris mb-4 mt-5 titreFooter d-block d-xl-none">Abonnez-vous à la Newsletter</p>
                        <div id="mc_embed_signup">
                            <form action="https://gmail.us4.list-manage.com/subscribe/post?u=2e530af5614986b55ac4cece9&amp;id=524b36aa1d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                <div id="mc_embed_signup_scroll">

                                    <div className="input-group mb-5 d-flex d-xl-none newsletter">
                                        <FontAwesomeIcon icon={faEnvelope} className="iconemail" />
                                        <input type="email" name="EMAIL" id="mce-EMAIL" className="form-control" placeholder="Adresse email" aria-label="Adresse email" aria-describedby="basic-addon2" autoComplete="off" required />

                                        {/* <div id="mce-responses" className="clear">
                                            <div className="response" id="mce-error-response" style="display:none"></div>
                                            <div className="response" id="mce-success-response" style="display:none"></div>
                                        </div> */}
                                        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                                        <div className="testRobot" aria-hidden="true">
                                            <input type="text" name="b_2e530af5614986b55ac4cece9_524b36aa1d" tabIndex="-1" />
                                        </div>

                                        <div className="input-group-append">
                                            <button type="submit" className="input-group-text btn-bleu" id="basic-addon2">
                                            Je m'abonne à la Newsletter 
                                            <FontAwesomeIcon icon={faChevronCircleRight} />
                                            </button>
                                        </div>

                                    </div>

                                </div>
                            </form>
                        </div>
                        {/* End mc_embed_signup */}

                        {/*<p className="texte-bleu mt-5 titreFooter">Menu général</p>
                        <ul className="listes">
                            <li><Link to="/">Accueil</Link></li>
                            <li><Link to="agence">L'agence</Link></li>
                            <li><Link to="marketing-digital">Marketing digital</Link></li>
                            <li><Link to="systemes-information">Systèmes d'information</Link></li>
                            <li><Link to="realisations">Réalisations</Link></li>
                            <li><Link to="contact">Contact</Link></li>
                            <li className="ouvrirProjet" onClick={session.toggleProjet}>Mon projet</li>
                        </ul>*/}
                    </div>

                    <div className="col-xl-5 col-md-12 mt-5 d-none d-xl-block">

                        {/* Begin Mailchimp Signup Form for desktop */}
                        <p className="texte-gris mb-3 titreFooter d-none d-xl-block">Abonnez-vous à la Newsletter</p>
                        <div id="mc_embed_signup">
                            <form action="https://gmail.us4.list-manage.com/subscribe/post?u=2e530af5614986b55ac4cece9&amp;id=524b36aa1d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                <div id="mc_embed_signup_scroll">

                                    <div className="input-group mb-5 d-none d-xl-flex newsletter">
                                        <FontAwesomeIcon icon={faEnvelope} className="iconemail" />
                                        <input type="email" name="EMAIL" id="mce-EMAIL-desktop" className="form-control" placeholder="Adresse email" aria-label="Adresse email" aria-describedby="basic-addon2-desktop" autoComplete="off" required />

                                        {/* <div id="mce-responses" className="clear">
                                            <div className="response" id="mce-error-response" style="display:none"></div>
                                            <div className="response" id="mce-success-response" style="display:none"></div>
                                        </div> */}
                                        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                                        <div className="testRobot" aria-hidden="true">
                                            <input type="text" name="b_2e530af5614986b55ac4cece9_524b36aa1d" tabIndex="-1" />
                                        </div>

                                        <div className="input-group-append">
                                            <button type="submit" className="input-group-text btn-bleu" id="basic-addon2-desktop">
                                                Je m'abonne à la Newsletter 
                                                <FontAwesomeIcon icon={faChevronCircleRight} />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                        {/* End mc_embed_signup */}

                        <div className="row">

                            {/*<div className="col-md-6 mb-5">
                                <Link to="marketing-digital">
                                    <p className="texte-bleu titreFooter lien">Marketing digital</p>
                                </Link>
                                <ul className="listes">
                                    <li><Link to="site-internet">Création de site internet</Link></li>
                                    <li><Link to="community-management">Community management</Link></li>
                                    <li><Link to="application-mobile">Application mobile</Link></li>
                                    <li><Link to="referencement">Référencement naturel & payant</Link></li>
                                    <li><Link to="email-marketing">Email marketing</Link></li>
                                    <li><Link to="audit-e-reputation">Audit e-réputation</Link></li>
                                    <li><Link to="partenariat-technique">Partenariat technique</Link></li>
                                </ul>
                            </div>

                            <div className="col-md-6 mb-5">
                                <p className="texte-bleu titreFooter lien">Systèmes d'information</p>
                                <ul className="listes">
                                    <li><Link to="">Progiciel de gestion intégré (ERP)</Link></li>
                                    <li><Link to="">Gestion de la relation client (CRM)</Link></li>
                                    <li><Link to="">Application métier sur mesure</Link></li>
                                    <li><Link to="">UX / UI Design</Link></li>
                                    <li><Link to="">Consultance fonctionnelle</Link></li>
                                    <li><Link to="">Audit de système d'information</Link></li>
                                    <li><Link to="">Partenariat technique</Link></li>
                                </ul>
                            </div>*/}

                        </div>

                    </div>

                </div>
            </div>

            <div className="haut" title="Retourner en haut de page" onClick={session.defilerHaut}>
                <FontAwesomeIcon icon={faChevronUp} />
            </div>

        </Pied>
    )
}

// Styles CSS
const Pied = styled.footer`
    //div{border: rgba(0,0,0,0.1) thin solid}
    padding-top: 100px;
    margin-top: 100px;
    font-size: 18px;
    .presentation{
        font-size: 22px;
        line-height: 1.2em;
    }
    p.titreFooter {
        margin-bottom: 10px;
        line-height: 25px;
        font-size: 22px;
        font-weight: 300;
    }
    p.titreFooter.lien:hover {
        color: var(--jaunejolof) !important;
        transition: color 500ms ease-in-out;
    }
    @media (min-width: 992px) and (max-width: 1700px) {
        font-size: 16px;
        line-height: 18px;
        p.titreFooter{
            font-size: 24px;
            margin-bottom: 5px;
        }
        padding-top: 75px;
        margin-top: 50px;
        .haut {
            margin-top: 0 !important;
        }
    }
    svg {
        font-size: 50px;
        margin-right: 10px;
        color: var(--bleujolof) !important;
    }
    .whatsapp {
        font-size: 25px !important;
        font-weight: 700;
        margin: 15px 0;
        transition: color 200ms ease-in-out;
    }
    .whatsapp:hover{
        color: var(--orangejolof) !important;
    }
    .whatsapp span {
        font-size: 20px;
        font-weight: 300;
        letter-spacing: -1px;
        top: -2px;
        position: relative;
    }
    .whatsapp svg {
        font-size: 30px;
        top: 3px;
        position: relative;
    }
    .haut {
        background: var(--fondpage);
        cursor: pointer;
        text-align: center;
        padding: 30px;
        margin-top: 50px;
        display: block;
    }
    .haut svg {
        color: #b7cad7 !important;
        transition: color 500ms ease-in-out;
    }
    .haut:hover svg{
        color: var(--bleujolof) !important;
    }
    a{
        transition: color 300ms ease-in-out;
    }
    a:hover, li:hover {
        color: var(--jaunejolof) !important;
    }
    li{
        cursor: pointer;
        transition: color 300ms ease-in-out;
    }
    .newsletter .iconemail {
        position: absolute;
        top: 11px;
        left: 15px;
        z-index: 10;
        font-size: 30px;
    }
    .newsletter input {
        background: #f3f6fb !important;
        padding: 15px 25px 15px 60px;
        border: none !important;
        border-radius: 10px 0 0 10px !important;
        max-width: 350px;
        max-height: 50px;
    }
    .newsletter .btn-bleu {
        margin-top: 0;
        cursor: pointer;
        white-space: nowrap;
        background: var(--bleujolof);
        color: white !important;
        padding: 12px 20px 10px 20px;
        border-radius: 0 10px 10px 0 !important;
        border: none;
        border-bottom: var(--bleujolof) solid 2px;
        box-shadow: rgba(23, 184, 222, 0.2) 0 10px 10px;
        transition: all 300ms ease-in-out;
        font-weight: 300;
    }
    .newsletter .btn-bleu svg{
        color: white !important;
        margin-left: 10px;
        margin-right: 0;
    }
    .newsletter .btn-bleu svg {
        font-size: 20px;
    }
    .newsletter .btn-bleu:hover {
        box-shadow: rgba(23, 184, 222, 0.5) 0 20px 40px;
    }
    .newsletter .form-control:focus{
        box-shadow: rgba(23, 184, 222, 0.5) 0 0 40px;
        background: white !important;
        transition: all ease-in-out 300ms;
        border:none !important;
        outline: none !important;
        outline-offset: 0 !important;
    }
    .input-group-text.btn-bleu{
        border-radius: 0 10px 10px 0;
    }
    .testRobot{
        position: absolute; 
        left: -5000px;
    }
    @media (max-width: 768px) {
        /*.newsletter{
            display: block;
            width: 100%;
        }
        .newsletter .input-group{
            display: block;
        }*/
        .newsletter input {
            padding: 25px 25px 25px 60px;
            margin-bottom: 15px;
            border-radius: 10px !important;
            max-width: 100%;
            width: 100%;
        }
        .newsletter .btn-bleu {
            border-radius: 10px !important;
            width: 100%;
            max-width: 100%;
            display: block;
        }
        .newsletter .input-group-append{
            width: 100%;
        }
    }
`

export default Footer