import React from 'react'
import styled from 'styled-components'
import bgPage from '../images/404.webp'
import { createGlobalStyle } from 'styled-components'


const Erreur404 = () => {

    return (
        <>
            <DivCSS>
                <div className='marge'>
                    <h1>Erreur</h1>
                    <p>Désolé, la page que vous recherchez est introuvable. Il se peut que la page ait été supprimée, que son nom ait été modifié ou que l'adresse n'existe plus. Veuillez vérifier l'URL que vous avez saisie ou retourner à la page d'accueil pour poursuivre votre navigation sur notre site.</p>
                </div>
            </DivCSS>
            <GlobalStyle />
        </>
    )
}

// Styles CSS
const GlobalStyle = createGlobalStyle`
    body{
        background: white !important;
    }
    header:not(.fixe) {
        background: white !important;
    }
    footer {
        margin-top: 0 !important;
    }
`
const DivCSS = styled.div`
    background: url(${bgPage});
    background-position: center;
    background-size: cover;
    height: 750px;
    margin-top: 20px;
    padding: 350px 50px 200px 50px;

    .marge{
        max-width: 550px;
        margin: 0 auto;
    }
    h1{
        font-weight: 700;
        color: white !important;
        text-shadow: rgba(0,0,0,0.2) 0 2px 2px;
        text-align: center;
    }
    p{
        text-align: center;
        color: white !important;
        text-shadow: rgba(0,0,0,0.2) 0 2px 2px;
    }

    @media (min-width: 576px) { // SM
        margin-top: 40px;
    }
    @media (min-width: 768px) { // MD
        margin-top: 40px;
    }
    @media (min-width: 992px) { // LG
        margin-top: 40px;
    }
`

export default Erreur404