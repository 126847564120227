import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function RemonterPage() {
  const { pathname } = useLocation();

  useEffect(() => {
    //window.scrollTo(0, 0);
    window.scrollTo({top: 0, behavior: 'instant'});
    //document.getElementByID('your-element').scrollIntoView({behavior: "instant"});
  }, [pathname]);

  return null;
}