import React from 'react'
import imgDeveloppement from '../../../images/developpemet.svg'

const Developpement = () => {
    return (
        <div className="pt-100 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pb-50 pt-5 align-self-center text-center img-left-mobile">
                        <img src={imgDeveloppement} alt="" width="80%" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune pt-5">Développement</h2>
                        <p className="chapeaux">Les technologies utilisées sur iOS et Android sont complètement différentes et chacune d’entre elles nécessite des compétences de programmation distinctes</p>
                        <p>Contrairement aux applications web qui peuvent être installées sur n’importe quel serveur dans le monde entier et accessibles depuis n’importe quel navigateur sur n’importe quel appareil, les applications mobiles sont destinées à être installées sur un système d’exploitation spécifique d’un smartphone. Aujourd’hui, au niveau mondial, le marché est partagé entre Android (86%), iOS (13%) et les autres (1%) ; ces parts de marché diffèrent selon les zones géographiques.</p>

                        <p>Pour atteindre 99% des utilisateurs potentiels, 2 applications différentes doivent être développées, soit une pour Android et une pour iOS.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Developpement
