import React from 'react'
import imgReputationRs from '../../../images/reputation-reseaux-sociaux.svg'

const AnalyseReseauxSociaux = () => {
    return (
        <section className="bg-bleu-blanc trait-gris-bottom pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">
                    <div className="col-md-6 pt-3 pb-50 align-self-center">
                        <img src={imgReputationRs} alt="" width="80%" data-aos="zoom-in-up" />
                    </div>
                    <div className="col-md-6 pr-100">
                        <h2 className="texte-jaune">Analyse de votre réputation <br className="d-none d-xl-block"/>sur les réseaux sociaux</h2>
                        <p className="chapeaux">Au-delà de vos propres pages, les personnes peuvent également laisser des avis, des messages ou autres sur leurs comptes personnels, dans des groupes ou via des commentaires sur des publications</p>
                        <p>Comme pour les sites, il faut assurer un monitoring pour détecter les mentions qui font référence à votre entreprise et ses services et prendre la parole dans ces espaces pour donner la bonne information, apporter un support si nécessaire ou remonter l'information tout simplement.</p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default AnalyseReseauxSociaux
