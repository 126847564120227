import React from 'react'
import styled from 'styled-components'
import charte from '../../../images/charte-editoriale.svg'


const CharteEditoriale = () => {
    return (
        <SectionCSS className="bg-blanc-bleu pt-150 pb-150">
            <div className="container">

                <div className="row" data-aos="fade-up">

                    <div className="col-md-6 text-center pb-5 order-md-2 align-self-center img-left-mobile">
                        <img src={charte} alt="" data-aos="zoom-in-up" />
                    </div>

                    <div className="col-md-6 pr-100 order-md-1">
                        <h2 className="texte-jaune pt-5">Charte éditoriale</h2>
                        <p className="chapeaux">Assurez une homogénéité rédactionnelle tout en respectant le fil conducteur</p>
                        <p>Nous établissons un cadre à votre prise de parole, réfléchissons au registre de langues ainsi qu’au vocabulaire le plus adapté pour interagir avec votre communauté et nous déterminons une liste d’expressions clés à employer sur les publications.</p>
                    </div>

                </div>

            </div>
        </SectionCSS>
    )
}

const SectionCSS = styled.section`
    img{width: 70%;}
`

export default CharteEditoriale
