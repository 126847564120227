import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

const Bouton = (props) => {
    
    return (
        <BoutonCSS className={props.couleur} onClick={props.action}>
            {props.children} <FontAwesomeIcon icon={faChevronCircleRight} />
        </BoutonCSS>
    )
}


const BoutonCSS = styled.button`
  white-space: nowrap;
  color: white !important;
  padding: 12px 20px 10px 20px;
  border-radius: 10px;
  border: none;
  margin-top: 25px;
  transition: all ease-in-out 300ms;
  font-weight: 300;
svg {
  color: white !important;
  margin-left: 5px;
  transition: all ease-in-out 300ms;
}
&:hover{
    padding: 12px 25px 10px 25px;
}
&.bleu{
    background: var(--bleujolof);
    border-bottom: var(--bleujolof) solid 2px;
    box-shadow: rgba(23, 184, 222, 0.2) 0 10px 10px;
}
&.bleu:hover{
    box-shadow: rgba(23, 184, 222, 0.5) 0 20px 40px;
}
&.jaune{
    background: var(--jaunejolof);
    border-bottom: var(--jaunejolof) solid 2px;
    box-shadow: rgba(254, 157, 43, 0.2) 0 10px 10px;
}
&.jaune:hover{
    box-shadow: rgba(254, 157, 43, 0.5) 0 20px 40px;
}
`

export default Bouton