import React, { Fragment } from 'react'
import styled from 'styled-components'
import iconCode from '../../images/icon_awesome-code.svg'
import Services from '../ui/Services'
import imgSiteInternet from '../../images/service--site-internet.webp'
import imgCommunityManagement from '../../images/service--community-management.webp'
import imgApplicationMobile from '../../images/service--application-mobile.webp'
import imgEmailMarketing from '../../images/service--email-marketing.webp'
import imgReferencement from '../../images/service--referencement.webp'
import imgAuditReputation from '../../images/service--audit-e-reputation.webp'

const NosServices = () => {
    return (
        <Fragment>
            <SectionTitre  className="bg-blanc-bleu" id='services'>
                <div className="container">
                    {/*<div className="text-center mb-5" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </div>*/}

                    <div className="text-center pb-50">
                        <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                            <img src={iconCode} alt='Nos services' />
                            {/*<FontAwesomeIcon icon={faCode} />*/}
                        </div>
                    </div>

                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <div className="col-12 text-center">
                            <h2 className="texte-bleu">Nos services</h2>
                            <p className="sous-titres">Nous possédons une expertise dans toutes les disciplines du marketing digital et travaillons en étroite collaboration avec vous pour comprendre vos objectifs et définir la meilleure stratégie pour atteindre vos résultats</p>
                            {/*<p className="sous-titres">Afin d'optimiser vos chances d’atteindre vos objectifs, l’idéal est d’adopter une stratégie qui fait appel à plusieurs canaux numériques</p>*/}
                        </div>
                    </div>
                </div>
            </SectionTitre>

            <SectionServices className="pt-0 mt-0 bg-bleu-clair">
                <div className="container">

                    <div className="row">

                        <Services 
                            titre = {<>Site<br/>internet</>}
                            texte = 'Votre présence sur le web est aujourd’hui primordiale pour faire face à la concurrence et atteindre vos cibles'
                            lien = '/site-internet'>
                            <img src={imgSiteInternet} alt='' />
                        </Services>

                        <Services 
                            titre = {<>Community<br/>management</>}
                            texte = 'Assurez votre présence sur les réseaux sociaux et répondez à vos objectifs de relation client et de notoriété'
                            lien = '/community-management'>
                            <img src={imgCommunityManagement} alt='' />
                        </Services>

                        <Services 
                            titre = {<>Application<br/>mobile</>}
                            texte = 'Rendez vos contenus plus accessibles sur les smartphones et tablettes avec une meilleure expérience utilisateur'
                            lien = '/application-mobile'>
                            <img src={imgApplicationMobile} alt='' />
                        </Services>

                        <Services 
                            titre = {<>Email<br/>marketing</>}
                            texte = "Nous vous accompagnons de la mise en place à l'envoi d'email afin de toucher directement votre cible"
                            lien = '/email-marketing'>
                            <img src={imgEmailMarketing} alt='' />
                        </Services>

                        <Services 
                            titre = {<>Référencement<br/>naturel & payant</>}
                            texte = 'Augmentez votre visibilité sur les principaux moteurs de recherche pour générer plus de trafic utile'
                            lien = '/referencement'>
                            <img src={imgReferencement} alt='' />
                        </Services>

                        <Services 
                            titre = {<>Audit<br/>e-réputation</>}
                            texte = 'Découvrez comment votre marque est perçue sur le web afin de définir des points d’amélioration'
                            lien = '/audit-e-reputation'>
                            <img src={imgAuditReputation} alt='' />
                        </Services>

                    </div>
                </div>
            </SectionServices>
        </Fragment>
    )
}

export default NosServices


const SectionTitre = styled.section`
    padding: 150px 0 50px 0;
    img{
        height: 50px; 
    }
    h2{
        font-weight: 700;
        text-transform: uppercase;
    }
`

const SectionServices = styled.section`
    padding: 100px 0 0 0;
`