import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Bouton from '../ui/Bouton'
import imgPartenariatTechnique from '../../images/partenaire-technique.svg'

const Partenariat = () => {
    return (
        <SectionCSS>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <img src={imgPartenariatTechnique} alt="" />
                    </div>
                    <div className="col-12 text-center pt-5" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                        <h2 className="texte-bleu">
                            Partenariat technique
                        </h2>
                        <p className="sous-titres">
                            Vous êtes une agence de communication et recherchez un partenaire pour vous épauler dans votre pôle digital ?</p>
                        <p className="sous-titres fw-bold pt-2">
                            Jolof Digital est là pour vous !
                        </p>
                        <Link to='/partenariat-technique'>
                            <Bouton couleur='bleu'>En savoir plus</Bouton>
                        </Link>
                    </div>
                </div>
            </div>
        </SectionCSS>
    )
}

export default Partenariat


const SectionCSS = styled.section`
    padding: 100px 0;
    img{
        width: 100%; 
        max-width: 550px;
    }
`