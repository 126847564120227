import React, { Fragment, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import HeaderTitre from '../composants/HeaderTitre'
import bgPartenariat from '../images/bg-partenariat-technique.webp'
import SessionContext from '../composants/utils/SessionContext'


const PartenariatTechnique = () => {

    const session = useContext(SessionContext);
    useEffect(() => {
        session.setMenuActif('partenariat');
    })

    return (
        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Partenariat technique | Jolof Digital</title>
                <meta name="description" content="Pour les agences et intégrateurs de solutions qui veulent délocaliser une partie de leur pôle digital"></meta>
                <link rel="canonical" href="https://jolof.digital/partenariat-technique" />
            </Helmet>

            <HeaderTitre 
                titre = {<>Partenariat <br/>technique</>}
                /*texte = "Vous êtes une agence ou un intégrateur de solutions et recherchez un partenaire pour vous épauler dans votre pôle digital ?">*/
                texte = "Pour les agences et intégrateurs de solutions qui veulent délocaliser une partie de leur pôle digital">
                <img src={bgPartenariat} alt="" className="header-image" data-aos="fade-left" data-aos-duration="1000" />
            </HeaderTitre>
            
        </Fragment>
    )
}

export default PartenariatTechnique
