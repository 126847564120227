import { createGlobalStyle } from 'styled-components';

const StylesJolofGlobal = createGlobalStyle`

    /* couleurs */
    .texte-bleu {
        color: var(--bleujolof) !important;
    }
    .texte-jaune {
        color: var(--jaunejolof) !important;
    }
    .texte-rouge {
        color: var(--rougejolof) !important;
    }
    .texte-orange {
        color: var(--orangejolof) !important;
    }
    .texte-bleu-clair {
        color: var(--fondpage) !important;
    }
    .texte-bleu-fonce {
        color: var(--bleufonce) !important;
    }
    .bg-bleu {
        background: var(--bleujolof) !important;
    }
    .bg-jaune {
        background: var(--jaunejolof) !important;
    }
    .bg-rouge {
        background: var(--rougejolof) !important;
    }
    .bg-orange {
        background: var(--orangejolof) !important;
    }
    .bg-bleu-clair {
        background: var(--fondpage) !important;
    }
    .bg-jaune-clair {
        background: rgba(254, 157, 43, 0.1) !important;
    }
    .bg-bleu-fonce {
        background: var(--bleufonce) !important;
    }
    .bg-bleu-blanc {
        background: linear-gradient(to bottom, var(--fondpage), white) !important;
    }
    .bg-bleu-blanc-bleu {
        background: linear-gradient(to bottom, var(--fondpage), white, var(--fondpage)) !important;
    }
    .bg-blanc-bleu {
        background: linear-gradient(to top, var(--fondpage), white) !important;
    }
    .bg-blanc-jaune {
        background: linear-gradient(to top, rgba(254, 157, 43, 0.1), white), #fff !important;
    }
    .bg-jaune-blanc {
        background: linear-gradient(to bottom, rgba(254, 157, 43, 0.1), white), #fff !important;
    }
    .bg-jaune-blanc-bleu {
        background: linear-gradient(to bottom, rgba(254, 157, 43, 0.1), white, var(--fondpage)), #fff !important;
    }
    .bg-jaune-blanc-jaune {
        background: linear-gradient(to bottom, rgba(254, 157, 43, 0.1), white, rgba(254, 157, 43, 0.1)), #fff !important;
    }

    /* textes */
    h1 {
        font-size: 80px;
        line-height: 80px;
        font-weight: 400;
    }
    h2 {
        font-size: 40px;
        line-height: 40px;
        font-weight: 300;
        padding-bottom: 10px;
    }
    h3 {
        font-size: 27px;
        line-height: 30px;
        font-weight: 300;
        margin-bottom: 16px;
    }
    h1 span {
        font-weight: 300;
        font-size: 70%;
        line-height: 1em;
        display: block;
        margin-top: 10px;
    }
    p{
        color: var(--gristexte) !important;
    }
    header h1,
    footer h6,
    .imgProjet {
        font-size: 35px;
        color: var(--bleujolof) !important;
        white-space: nowrap;
        font-weight: 700;
    }
    .sous-titres {
        max-width: 600px;
        margin: 0 auto;
    }
    .chapeaux {
        font-size: 27px;
        line-height: 30px;
    }
    .etapes {
        font-size: 30px;
        font-weight: 700;
        background: white;
        padding: 20px 0;
        width: 65px;
        height: 65px;
        margin: 0 auto;
        border-radius: 75px;
        box-shadow: rgba(0, 0, 0, 0.16) 0 0 10px;
    }
    .listes {
        padding-left: 1px;
    }
    .listes li {
        list-style: none;
        margin-left: 15px;
    }
    .listes li::before {
        content: "»";
        color: var(--bleujolof);
        font-size: 24px;
        padding-right: 5px;
        top: 1px;
        position: relative;
        margin-left: -15px;
    }
    sub,
    sup {
        font-size: 66%;
        font-weight: 300;
    }

    /* Formulaires */
    .formulaire {
        background: white !important;
        color: var(--bleujolof) !important;
        font-size: 18px;
        font-weight: 700;
        border: none;
        width: 100%;
        box-shadow: 0 10px 30px rgba(2, 45, 74, 0.1) !important;
        padding: 27px 20px 10px 20px;
        border-radius: 10px;
        transition: all ease-in-out 300ms;
    }
    .formulaire:hover,
    .formulaire:focus {
        box-shadow: 0 20px 30px rgba(2, 45, 74, 0.2) !important;
    }
    .formulaire_label {
        font-size: 16px;
        margin-left: 20px;
        bottom: -30px;
        position: relative;
        height: 0;
    }
    .boxSpinner {
        max-width: 200px;
        margin: 0 auto;
    }
    .boxSpinner .form-control{
        text-align: center;
        font-size: 18px !important;
        height: 30px;
    }
    .boxSpinner .form-control:active,
    .boxSpinner .form-control:focus {
        box-shadow: none;
        border-color: #ced4da;
    }
    .boxSpinner button{
        background: var(--bleujolof);
        border-color:  var(--bleujolof);
        color: #fff !important;
        font-size: 25px;
        padding: 0 15px;
        line-height: 20px;
        height: 30px;
    }
    .boxSpinner button:disabled{
        background: #69c8f1;
        border-color:  #69c8f1;
        color: #fff !important;
        font-size: 25px;
        padding: 0 15px;
        line-height: 20px;
        height: 30px;
    }
    .boxSpinner button:hover,
    .boxSpinner button:active,
    .boxSpinner button:focus{
        background: var(--bleujolofhover);
        border-color:  var(--bleujolofhover);
        box-shadow: none;
    }
    input[type="range"].range-slider.range-slider--info::-moz-range-thumb {
        background: var(--bleujolof);
    }
    input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

    /* Divers */
    @media (max-width: 576px) {
        .img-left-mobile {
            text-align: left !important;
        }
    }
    .maxwidth100 {
        max-width: 100% !important;
    }
    .trait-gris-bottom {
        border-bottom: #f3f6fb 1px solid !important;
    }
    .trait-gris-top {
        border-top: #f3f6fb 1px solid !important;
    }
    .trait-blanc-bottom{
        border-bottom: white 5px solid;
    }
    .margin-top-75 {
        margin-top: 75px;
    }
    .arrondi {
        border-radius: 10px;
    }
    .arrondi-haut {
        border-radius: 10px 10px 0 0;
    }
    .pt-50 {
        padding-top: 50px !important;
    }
    .pt-100 {
        padding-top: 100px !important;
    }
    .pt-150 {
        padding-top: 150px !important;
    }
    .pt-200 {
        padding-top: 200px !important;
    }
    .pb-50 {
        padding-bottom: 50px !important;
    }
    .pb-100 {
        padding-bottom: 100px !important;
    }
    .pb-150 {
        padding-bottom: 150px !important;
    }
    .pb-200 {
        padding-bottom: 200px !important;
    }
    @media (min-width: 576px) {
        .pr-100 {
            padding-right: 50px;
        }
    }
    @media (min-width: 992px) {
        .pr-100 {
            padding-right: 50px;
        }
    }
    @media (min-width: 1200px) {
        .pr-100 {
            padding-right: 100px;
        }
    }
    @media (max-width: 1700px) {
        .pt-100 {
            padding-top: 50px !important;
        }
        .pt-150 {
            padding-top: 100px !important;
        }
        .pb-100 {
            padding-bottom: 50px !important;
        }
        .pb-150 {
            padding-bottom: 100px !important;
        }
    }

    /* tarification */
    .box-options {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 15px;
        box-shadow: rgba(2, 45, 75, 0.1) 0 0 20px;
    }
    .box-options {
        line-height: 16px;
        text-align: center;
    }
    .box-options div.row, 
    .box-options div[class^="col-"] {
        padding-top: 7px;
        padding-bottom: 7px;
    }
    .box-options .react-slidedown{
        overflow-x: hidden;
    }
    .box-options .icone svg{
        color: var(--bleujolof) !important;
        font-size: 2em;
    }
    .box-options .denomination {
        color: var(--bleujolof) !important;
        margin-bottom: -12px;
        display: block; /*line-height: 22px;*/
    }
    .box-options .description {
        font-size: 15px;
    }
    .box-options .prix {
        font-size: 16px;
    }
    .box-options .prix b {
        font-weight: 500;
    }
    /*#attente img,
    #attente-mobile img {
        width: 200px;
        max-width: 100%;
    }*/
    #attente .chapeaux,
    #attente-mobile .chapeaux,
    #developpement .chapeaux {
        max-width: 200px;
        line-height: 30px;
        margin: 0 auto;
    }
    #attente svg,
    #attente-mobile svg{
        font-size: 4em;
    }
    #attente .chapeaux{
        font-size: 24px;
        line-height: 30px;
    }
    #attente-mobile .chapeaux{
        font-size: 20px;
        line-height: 25px;
        padding: 0 10px;
    }
    #developpement {
        padding-top: 50px;
        padding-bottom: 25px;
    }
    #developpement img {
        width: 100px;
        max-width: 50%;
    }
    #developpement .description {
        max-width: 300px;
        font-size: 16px;
        line-height: 20px;
        margin: 0 auto;
        padding-top: 10px;
    }
    #developpementDetail {
        padding: 20px;
    }
    #developpementDetail textarea {
        width: 100%;
        height: 200px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        color: var(--bleujolof) !important;
        border: none;
        text-align: center;
    }
    @media (max-width: 767px) {
        #offre {
            background: var(--jaunejolof) /*var(--bleujolof)*/;
            color: black !important;
        }
        #offre .texte-bleu {
            color: #fff !important;
        }
        #attente {
            display: none !important;
        }
    }
    @media (min-width: 768px) {
        #attente {
            padding-top: 50px;
            padding-bottom: 75px;
        }
        #attente-mobile {
            display: none !important;
        }
    }

    #offre .row {
        border-top: rgba(0, 0, 0, 0.2) thin dotted;
    }
    #optionschoisies {
        line-height: 20px;
    }
    #total,
    #offre {
        line-height: 24px;
    }
    #modalites .row{
        margin: -4px;
    }
    #modalites .col-xl-6{
        padding: 5px 3px;
    }
    #modalites .boite {
        border: var(--fondpage) thin solid;
        border-radius: 5px;
        padding: 0;
    }
    #modalites .boite:hover {
        background: var(--fondpage) !important;
        transition: all 200ms ease-in-out;
    }
    #modalites .row {
        padding: 0;
    }
    #modalites input {
        display: none;
    }
    #modalites label b {
        color: var(--bleujolof) !important;
        text-transform: uppercase;
        font-weight: bold;
    }
    #modalites label {
        padding: 15px;
        width: 100%;
        font-size: 16px;
        line-height: 16px;
        cursor: pointer;
        margin: 0;
    }
    #modalites .boite.actif {
        background: var(--bleujolof) !important;
        border-color: var(--bleujolof);
    }
    #modalites .boite.actif b,
    #modalites .boite.actif:hover b {
        color: white !important;
    }
    #modalites .boite.actif label {
        color: var(--fondpage);
    }
    .alertedevis {
        font-size: 14px;
        line-height: 16px;
        border-radius: 10px;
        padding: 7px 15px;
    }
    select.nombre,
    input.nombre {
        width: 95px;
        border-radius: 25px;
        box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 25px !important;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.4) !important;
        text-align: center;
    }
    @media (min-width: 1200px) {
        .box-options {
            text-align: left;
            padding: 2px 20px;
        }
    }

    .supprimer_projet {
        opacity: 0.5;
        cursor: pointer;
        transition: 300ms all ease-in-out;
    }
    .supprimer_projet:hover {
        opacity: 0.85;
        color: var(--orangejolof) !important;
    }


    /* Bouton Switch */
    .switch {
        position: relative;
        display: block;
        vertical-align: top;
        width: 100px;
        height: 30px;
        padding: 3px;
        margin: 0 10px 10px 0;
        background: linear-gradient(to bottom, #eeeeee, #ffffff 25px);
        background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff 25px);
        border-radius: 18px;
        box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        margin: auto;
    }
    .switch-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
    .switch-label {
        position: relative;
        display: block;
        height: inherit;
        font-size: 15px;
        text-transform: uppercase;
        background: #eceeef;
        border-radius: inherit;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), 
                    inset 0 0 2px rgba(0, 0, 0, 0.15);
    }
    .switch-label:before,
    .switch-label:after {
        position: absolute;
        top: 50%;
        margin-top: -0.5em;
        line-height: 1;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        -o-transition: inherit;
        transition: inherit;
    }
    .switch-label:before {
        content: attr(data-off);
        right: 11px;
        color: #aaaaaa;
        text-shadow: 0 1px rgba(255, 255, 255, 0.5);
    }
    .switch-label:after {
        content: attr(data-on);
        left: 11px;
        color: #ffffff;
        text-shadow: 0 1px rgba(0, 0, 0, 0.2);
        opacity: 0;
    }
    .switch-input:checked ~ .switch-label {
        /*background: #E1B42B;*/
        background: var(--bleujolof);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
                    inset 0 0 3px rgba(0, 0, 0, 0.2);
    }
    .switch-input:checked ~ .switch-label:before {
        opacity: 0;
    }
    .switch-input:checked ~ .switch-label:after {
        opacity: 1;
    }
    .switch-handle {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 28px;
        height: 28px;
        background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
        background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
        border-radius: 100%;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    }
    .switch-handle:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
        width: 12px;
        height: 12px;
        background: var(--fondpage);
        border-radius: 6px;
        box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
    }
    .switch-input:checked ~ .switch-handle {
        left: 74px;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
    }
    .switch-label,
    .switch-handle {
        transition: All 0.3s ease;
        -webkit-transition: All 0.3s ease;
        -moz-transition: All 0.3s ease;
        -o-transition: All 0.3s ease;
    }

    /* and remove [alt] to apply to all images*/
    img[alt]:after {  
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px !important;
        height: 1px !important;
        background-color: #fff;
        font-family: 'Helvetica';
        font-weight: 300;
        line-height: 2;  
        text-align: center;
        content: "";
    }
    /*img[alt]:after {  
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        font-family: 'Helvetica';
        font-weight: 300;
        line-height: 2;  
        text-align: center;
        content: attr(alt);
    }*/

`;
 
export default StylesJolofGlobal;